import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { _http, default_url, _api } from '@app/_models/url';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage'

@Injectable({
	providedIn: 'root'
})
export class WebService {
	public url: string = default_url;

	constructor(
		private http: HttpClient,
		private alertCtrl: AlertController,
		private storage: Storage,
	) { }


	public async delete(uri: string = '', options: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.http.delete(_http + this.url + _api + uri, options).toPromise().then((response: any) => {
				resolve(response);
			}).catch(err => {
				reject(err);
			});
		});
	}

	public async get(uri: string = ''): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.http.get(_http + this.url + _api + uri).toPromise().then((response: any) => {
				resolve(response);
			}).catch(err => {
				reject(err);
			});
		});
	}

	public async post(uri: string = '', body): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.http.post(_http + this.url + _api + uri, body).toPromise().then((response: any) => {
				resolve(response);
			}).catch(err => {
				reject(err);
			});
		});
	}


	public async put(uri: string = '', body): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.http.put(_http + this.url + _api + uri, body).toPromise().then((response: any) => {
				resolve(response);
			}).catch(err => {
				reject(err);
			});
		});
	}

	public async setUrl() {
		const prompt = await this.alertCtrl.create({
			header: 'Enterprise PureLocate',
			message: 'Enter the Full URL for the PureLocate API endpoint.<br><br> This is for custom enterprise deployments only.<br><br> Contact PureLocate for details.',
			inputs: [
				{
					name: 'URL',
					placeholder: this.url,
					value: this.url
				},
			],
			buttons: [
				{
					text: 'Default',
					handler: () => {
						this.storage.remove("CUSTOM_URL");
						this.url = default_url;
					}
				},
				{
					text: 'Save',
					handler: data => {
						this.saveCustom(data);
					}
				}
			]
		});
		await prompt.present();
	}

	public async saveCustom(data: any): Promise<void> {

		return new Promise<void>((resolve, reject) => {
			this.url = data.URL;
			this.storage.set("CUSTOM_URL", this.url).then(() => {
				resolve();
			}).catch((err) => {
				reject(err)
			});
		});
	}


}
