import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '@app/_models/user';
import { _http, _api } from '@app/_models/url';
import { UserMessage } from '@protos';
import { NavigationService, Routes } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private user: User = new User(new UserMessage());
  public userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(this.user);

  constructor(
    private navService: NavigationService
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.navService.navigateAsRoot(Routes.login);
  }
}