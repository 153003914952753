
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GrpcService {

  public apiUrl = 'https://app.purelocate.com';

  constructor(

  ) { }


  // /////////////
  // // TEMPORARY CALLS

  // private hasRetrieveDevices: Boolean = false;
  // async retrieveAllOrgAssets(): Promise<string[]> {
  //   this.hasRetrieveDevices = true;
  //   return new Promise<string[]>((resolve, reject) => {

  //     this.apiService.getAllDevicesFromOrg().then(assets => {
  //       resolve(assets);
  //     });
  //   });
  // }

  // /////////////



  // /////////////
  // // GRPC Calls

  // async retrievePageDataList(row: RowDefintions, requestIndex: number, requestSize: number, byteParams: number): Promise<string[]> {

  //   let pageStart = requestIndex * requestSize;
  //   let pageEnd = requestIndex * requestSize + requestSize;

  //   //assuming none of the asset IDs are in the graph
  //   switch (row) {
  //     case RowDefintions.LOCATIONS:
  //       return new Promise<string[]>((resolve, reject) => {
  //         this.getLocationPageData(requestIndex, requestSize, byteParams).then((resp) => {
  //           let locationList = resp.getLocationsList();
  //           let ids: string[] = [];

  //           locationList.forEach(location => {
  //             this.dataService.rows[RowDefintions.LOCATIONS][location.getId()] = new LocationDerivedModel(location);
  //             ids.push(location.getId());
  //           });
  //           resolve(ids);
  //         });
  //       });
  //     case RowDefintions.FLOORS:
  //       return new Promise<string[]>((resolve, reject) => {
  //         this.getFloorPageData(requestIndex, requestSize, byteParams).then((resp) => {
  //           let floorList = resp.getFloorsList();
  //           let ids: string[] = [];

  //           floorList.forEach(floor => {
  //             this.dataService.rows[RowDefintions.FLOORS][floor.getId()] = new FloorDerivedModel(floor);
  //             ids.push(floor.getId());
  //           });

  //           resolve(ids);
  //         });
  //       });
  //     case RowDefintions.STATIONS:
  //       return new Promise<string[]>((resolve, reject) => {


  //         if (!this.hasRetrieveDevices) {
  //           this.retrieveAllOrgAssets().then((resp) => {
  //             let keys = Object.keys(this.dataService.rows[4]);
  //             this.dataService.setStationTotalCount(keys.length);
  //             resolve(keys.slice(pageStart, pageEnd));
  //           });
  //         } else {
  //           let keys = Object.keys(this.dataService.rows[4]);
  //           resolve(keys.slice(pageStart, pageEnd));
  //         }


  //         // this.getStationPageData(requestIndex, requestSize, byteParams).then((resp) => {
  //         //   let stationList = resp.getStationsList();
  //         //   let ids: string[] = [];

  //         //   stationList.forEach(station => {
  //         //     this.dataService.rows[RowDefintions.STATIONS][station.getId()] = new StationDerivedModel(station);
  //         //     ids.push(station.getId());
  //         //   });

  //         //   resolve(ids);
  //         // });
  //       });
  //     case RowDefintions.ASSETS:
  //       return new Promise<string[]>((resolve, reject) => {

  //         if (!this.hasRetrieveDevices) {
  //           this.retrieveAllOrgAssets().then((resp) => {
  //             let keys = Object.keys(this.dataService.rows[5]);
  //             this.dataService.setAssetTotalCount(keys.length);
  //             resolve(keys.slice(pageStart, pageEnd));
  //           });
  //         } else {
  //           let keys = Object.keys(this.dataService.rows[5]);
  //           const assets = keys.slice(pageStart, pageEnd);

  //           resolve(assets);
  //         }

  //         // this.getAssetPageData(requestIndex, requestSize, byteParams).then((resp) => {
  //         //   let assetList = resp.getAssetsList();
  //         //   let ids: string[] = [];

  //         //   assetList.forEach(asset => {
  //         //     this.dataService.rows[RowDefintions.ASSETS][asset.getId()] = new AssetDerivedModel(asset);
  //         //     ids.push(asset.getId());
  //         //   });

  //         //   resolve(ids);
  //         // });
  //       });
  //     default:
  //       break;
  //   }
  // }

  // async retrieveEntityData(IDs: string[], byteParams: number): Promise<string[]> {

  //   const storedIDs: string[] = [];
  //   const nonstored_IDs: string[] = [];
  //   IDs.forEach(id => {
  //     if (!this.dataService.checkEntity(id)) {
  //       nonstored_IDs.push(id);
  //     } else {
  //       storedIDs.push(id);
  //     }
  //   });

  //   //All entity IDs are loaded
  //   if (nonstored_IDs.length == 0) return new Promise<string[]>((resolve, reject) => {
  //     resolve(storedIDs);
  //   });

  //   //All or some IDs are not loaded
  //   return new Promise<string[]>((resolve, reject) => {
  //     this.getEntityDataList(nonstored_IDs, byteParams).then((resp) => {
  //       if (!resp) {
  //         resolve([]);
  //         return;
  //       }

  //       let locations: LocationMessage[] = resp.getLocationsList()
  //       locations.forEach(location => {
  //         this.dataService.rows[RowDefintions.LOCATIONS][location.getId()] = new LocationDerivedModel(location);
  //         storedIDs.push(location.getId());
  //       })

  //       let floors: FloorMessage[] = resp.getFloorsList()
  //       floors.forEach(floor => {
  //         this.dataService.rows[RowDefintions.FLOORS][floor.getId()] = new FloorDerivedModel(floor);
  //         storedIDs.push(floor.getId());
  //       })

  //       let stations: StationMessage[] = resp.getStationsList()
  //       stations.forEach(station => {
  //         this.dataService.rows[RowDefintions.STATIONS][station.getId()] = new StationDerivedModel(station);
  //         storedIDs.push(station.getId());
  //       })

  //       let assets: AssetMessage[] = resp.getAssetsList()
  //       assets.forEach(asset => {
  //         this.dataService.rows[RowDefintions.ASSETS][asset.getId()] = new AssetDerivedModel(asset);
  //         storedIDs.push(asset.getId());
  //       })

  //       resolve(storedIDs);
  //     });

  //   });
  // }


  // async retrieveServerDataCount(type?: RowDefintions): Promise<boolean> {
  //   return new Promise<true>((resolve, reject) => {

  //     switch (type) {
  //       case (RowDefintions.LOCATIONS):
  //         this.getServerLocationCount().then((resp) => {
  //           //this.dataService.setAssetTotalCount(resp);
  //           resolve(true);
  //         });
  //         break;
  //       case (RowDefintions.FLOORS):
  //         this.getServerFloorCount().then((resp) => {
  //           //this.dataService.setAssetTotalCount(resp);
  //           resolve(true);
  //         });
  //         break;
  //       case (RowDefintions.STATIONS):
  //         this.getServerStationCount().then((resp) => {
  //           //this.dataService.setAssetTotalCount(resp);
  //           resolve(true);
  //         });
  //         break;
  //       case (RowDefintions.ASSETS):
  //         this.getServerAssetCount().then((resp) => {
  //           //this.dataService.setAssetTotalCount(resp);
  //           resolve(true);
  //         });
  //         break;
  //       default: //retrieve all
  //         this.getServerLocationCount().then((resp) => {
  //           //this.dataService.setAssetTotalCount(resp);

  //           this.getServerFloorCount().then((resp) => {
  //             //this.dataService.setAssetTotalCount(resp);

  //             this.getServerStationCount().then((resp) => {
  //               //this.dataService.setAssetTotalCount(resp);

  //               this.getServerAssetCount().then((resp) => {
  //                 //this.dataService.setAssetTotalCount(resp);
  //                 resolve(true);
  //               });
  //             });
  //           });
  //         });
  //         break;
  //     }
  //   });
  // }

  // /////////////

  // /////////////
  // //Dev functions

  // //Takes all data currently in graph in a file
  // public storeAllTestData(entities: EntityListsMessage) {
  //   this.dummyServerService.setAllData(entities);
  //   // this.protobufService.deserializeAssetMessageBinary();

  // }

  // //Load all data into the graph from file
  // public loadTestData() {

  // }
  // /////////////

  // public getAllData() {
    
  // }

  // async getLocationPageData(pageIndex: number, pageSize: number, byteParams: number): Promise<LocationsListMessage> {

  //   return new Promise<LocationsListMessage>((resolve, reject) => {

  //     this.apiService.getLocations().then(locations => {

  //       const promises = [];

  //       locations.getLocationsList().forEach(location => {
  //         let locationsList: Location_FloorsMessage[] = [];
  //         location.getProtoChildrenList().forEach(floorId => {

  //           promises.push(this.apiService.getFloor(floorId, location).then(floor => {
  //             let location_FloorMessage: Location_FloorsMessage = new Location_FloorsMessage();
  //             location_FloorMessage.setAssetCount(1);
  //             location_FloorMessage.setName(floor.getName());
  //             location_FloorMessage.setStationCount(1);
  //             locationsList.push(location_FloorMessage);
  //             location.setFloorsList(locationsList);
  //           }));
  //         });
  //       });

  //       Promise.all(promises).then(res => {
  //         resolve(locations);
  //       });

  //     });


  //     // this.dummyServerService.rpcGetLocationPageData(pageIndex, pageSize, byteParams).then((resp) => {
  //     //   resolve(resp);
  //     // },
  //     //   error => {
  //     //     console.log(error);
  //     //   }
  //     // );
  //   });

  // }

  // async getFloorPageData(pageIndex: number, pageSize: number, byteParams: number): Promise<FloorListMessage> {

  //   return new Promise<FloorListMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcGetFloorPageData(pageIndex, pageSize, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   });
  // }

  // async getStationPageData(pageIndex: number, pageSize: number, byteParams: number): Promise<StationsListMessage> {

  //   return new Promise<StationsListMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcGetStationPageData(pageIndex, pageSize, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   });
  // }


  // async getAssetPageData(pageIndex: number, pageSize: number, byteParams: number): Promise<AssetsListMessage> {

  //   return new Promise<AssetsListMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcGetAssetPageData(pageIndex, pageSize, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   });
  // }

  // async getLocationData(id: string, byteParams?: number): Promise<LocationMessage> {

  //   return new Promise<LocationMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcGetLocationData(id, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   });
  // }

  // async getFloorData(id: string, byteParams?: number): Promise<FloorMessage> {

  //   return new Promise<FloorMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcGetFloorData(id, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   });
  // }

  // async getStationData(id: string, byteParams?: number): Promise<StationMessage> {

  //   return new Promise<StationMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcGetStationData(id, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   });
  // }

  // async getAssetData(id: string, byteParams?: number): Promise<AssetMessage> {

  //   return new Promise<AssetMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcGetAssetData(id, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   });
  // }

  // async getEntityDataList(id: string[], byteParams?: number): Promise<EntityListsMessage> {

  //   return;

  //   // return new Promise<EntityListsMessage>((resolve, reject) => {


  //   //   this.apiService.httpGetFloors(id).then(floors => {

  //   //     resolve(floors);
  //   //   });
  //   //   resolve();
  //   // });
  //   // return new Promise<EntityListsMessage>((resolve, reject) => {
  //   //   this.dummyServerService.rpcEntityDataList(id, byteParams).then((resp) => {
  //   //     resolve(resp);
  //   //   },
  //   //     error => {
  //   //       console.log(error);
  //   //     }
  //   //   );
  //   // });
  // }

  // async getServerLocationCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getLocationCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async getServerFloorCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getFloorCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async getServerStationCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getStationCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async getServerAssetCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getAssetCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async getServerOnlineStationCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getOnlineStationCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async getServerOfflineStationCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getOfflineStationCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }


  // async getServerOnlineAssetCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getOnlineAssetCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async getServerOfflineAssetCount(): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     this.dummyServerService.getOfflineAssetCount().then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // //Filter functions
  // async filterAssets(searchTerm: string, byteParams: number): Promise<string[]> {
  //   return new Promise<string[]>((resolve, reject) => {
  //     let loadedAssetKeys: string[] = Object.keys(this.dataService.rows[RowDefintions.ASSETS]);

  //     resolve(loadedAssetKeys.filter(item => {
  //       return this.dataService.rows[RowDefintions.ASSETS][item].getName().toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
  //     }));

  //   });
  // }

  // async filterServerLocations(searchTerm: string, byteParams: number): Promise<string[]> {
  //   return new Promise<string[]>((resolve, reject) => {
  //     this.filterLocationData(searchTerm, byteParams).then((resp) => {
  //       let locationList = resp.getLocationsList();
  //       let ids: string[] = [];

  //       locationList.forEach(location => {
  //         this.dataService.rows[RowDefintions.LOCATIONS][location.getId()] = new LocationDerivedModel(location);
  //         ids.push(location.getId());
  //       });

  //       resolve(ids);
  //     });
  //   });
  // }

  // async filterServerStations(searchTerm: string, byteParams: number): Promise<string[]> {
  //   return new Promise<string[]>((resolve, reject) => {
  //     this.filterStationData(searchTerm, byteParams).then((resp) => {
  //       let stationList = resp.getStationsList();
  //       let ids: string[] = [];

  //       stationList.forEach(station => {
  //         this.dataService.rows[RowDefintions.STATIONS][station.getId()] = new StationDerivedModel(station);
  //         ids.push(station.getId());
  //       });

  //       resolve(ids);
  //     });
  //   });
  // }

  // async filterServerAssets(searchTerm: string, byteParams: number): Promise<string[]> {
  //   return new Promise<string[]>((resolve, reject) => {
  //     this.filterAssetData(searchTerm, byteParams).then((resp) => {
  //       let assetList = resp.getAssetsList();
  //       let ids: string[] = [];

  //       assetList.forEach(asset => {
  //         this.dataService.rows[RowDefintions.ASSETS][asset.getId()] = new AssetDerivedModel(asset);
  //         ids.push(asset.getId());
  //       });

  //       resolve(ids);
  //     });
  //   });
  // }
  // /////////////


  // async filterLocationData(filterText: string, byteParams: number): Promise<LocationsListMessage> {

  //   return new Promise<LocationsListMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcFilterLocationData(filterText, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async filterStationData(filterText: string, byteParams: number): Promise<StationsListMessage> {

  //   return new Promise<StationsListMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcFilterStationData(filterText, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async filterAssetData(filterText: string, byteParams: number): Promise<AssetsListMessage> {

  //   return new Promise<AssetsListMessage>((resolve, reject) => {
  //     this.dummyServerService.rpcFilterAssetData(filterText, byteParams).then((resp) => {
  //       resolve(resp);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  //   });
  // }

  // async addLocation(location: LocationDerivedModel): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.dummyServerService.rpcAddLocation(location).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });
  // }

  // async addFloor(floor: FloorDerivedModel): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.dummyServerService.rpcAddFloor(floor).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });
  // }

  // async addStation(station: StationDerivedModel): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.dummyServerService.rpcAddStation(station).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });
  // }

  // async addAsset(asset: AssetDerivedModel): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.dummyServerService.rpcAddAsset(asset).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });
  // }

  // async updateLocation(location: LocationDerivedModel, body: any): Promise<Boolean> {

  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.apiService.updateLocation(location.getId(), body).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });

  //   // return new Promise<Boolean>((resolve, reject) => {
  //   //   this.dummyServerService.rpcUpdateLocationData(location).then(res => {
  //   //     if (res) {
  //   //       resolve(true)
  //   //     } else {
  //   //       resolve(false);
  //   //     }
  //   //   });
  //   // });
  // }

  // async updateFloor(floor: FloorDerivedModel, body: any): Promise<Boolean> {

  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.apiService.updateFloor(floor.getId(), body).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });


  //   // return new Promise<Boolean>((resolve, reject) => {
  //   //   this.dummyServerService.rpcUpdateFloorData(floor).then(res => {
  //   //     if (res) {
  //   //       resolve(true)
  //   //     } else {
  //   //       resolve(false);
  //   //     }
  //   //   });
  //   // });
  // }

  // async updateStation(station: StationDerivedModel, body: any): Promise<Boolean> {

  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.apiService.updateDevice(station.getId(), body).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });


  //   // return new Promise<Boolean>((resolve, reject) => {
  //   //   this.dummyServerService.rpcUpdateStationData(station).then(res => {
  //   //     if (res) {
  //   //       resolve(true)
  //   //     } else {
  //   //       resolve(false);
  //   //     }
  //   //   });
  //   // });
  // }

  // async updateAsset(asset: AssetDerivedModel, body: any): Promise<Boolean> {

  //   return new Promise<Boolean>((resolve, reject) => {
  //     this.apiService.updateDevice(asset.getId(), body).then(res => {
  //       if (res) {
  //         resolve(true)
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });


  //   // return new Promise<Boolean>((resolve, reject) => {
  //   //   this.dummyServerService.rpcUpdateAssetData(asset).then(res => {
  //   //     if (res) {
  //   //       resolve(true)
  //   //     } else {
  //   //       resolve(false);
  //   //     }
  //   //   });
  //   // });
  // }

  // async removeLocation(location: LocationDerivedModel, byteParams: number): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {

  //     this.apiService.deleteLocation(location.getId()).then(resp => {
  //       if (resp) {
  //         delete this.dataService.getRow(RowDefintions.LOCATIONS)[location.getId()];
  //         resolve(true);
  //       } else {
  //         resolve(false);
  //       }
  //     }), error => {
  //       console.log(error);
  //       resolve(false);
  //     }

  //     // this.dummyServerService.rpcDeleteLocationData(location.getId(), byteParams).then((resp) => {
  //     //   if (resp) {
  //     //     delete this.dataService.getRow(RowDefintions.LOCATIONS)[location.getId()];

  //     //     resolve(true);
  //     //   } else {
  //     //     resolve(false);
  //     //   }
  //     // },
  //     //   error => {
  //     //     resolve(false);
  //     //   });
  //   });
  // }

  // async removeFloor(floor: FloorDerivedModel, byteParams: number): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {

  //     this.apiService.deleteFloor(floor.getId()).then(resp => {
  //       if (resp) {
  //         delete this.dataService.getRow(RowDefintions.FLOORS)[floor.getId()];
  //         resolve(true);
  //       } else {
  //         resolve(false);
  //       }
  //     }), error => {
  //       console.log(error);
  //       resolve(false);
  //     }

  //     // this.dummyServerService.rpcDeleteFloorData(floor.getId(), byteParams).then((resp) => {
  //     //   if (resp) {
  //     //     this.retrieveEntityData([floor.getProtoParent()], 0xff).then(res => {

  //     //       delete this.dataService.getRow(RowDefintions.FLOORS)[floor.getId()];
  //     //       // this.events.publish('map:reset'); //Events are deprecated and removed
  //     //     });

  //     //     resolve(true);
  //     //   }
  //     // },
  //     //   error => {
  //     //     resolve(false);
  //     //     console.log(error);
  //     //   });
  //   });
  // }


  // async removeStation(id: string, byteParams: number): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {

  //     this.apiService.deleteDevice(id).then(resp => {
  //       if (resp) {
  //         delete this.dataService.getRow(RowDefintions.STATIONS)[id];
  //         resolve(true);
  //       } else {
  //         resolve(false);
  //       }
  //     }), error => {
  //       console.log(error);
  //       resolve(false);
  //     }

  //     // this.dummyServerService.rpcDeleteFloorData(id, byteParams).then((resp) => {
  //     //   resolve(true);
  //     // },
  //     //   error => {
  //     //     resolve(false);
  //     //     console.log(error);
  //     //   });
  //   });
  // }


  // async removeAsset(id: string, byteParams: number): Promise<Boolean> {
  //   return new Promise<Boolean>((resolve, reject) => {

  //     this.apiService.deleteDevice(id).then(resp => {
  //       if (resp) {
  //         delete this.dataService.getRow(RowDefintions.ASSETS)[id];
  //         resolve(true);
  //       } else {
  //         resolve(false);
  //       }
  //     }), error => {
  //       console.log(error);
  //       resolve(false);
  //     }

  //     // this.dummyServerService.rpcDeleteFloorData(id, byteParams).then((resp) => {
  //     //   resolve(true);
  //     // },
  //     //   error => {
  //     //     resolve(false);
  //     //     console.log(error);
  //     //   });
  //   });
  // }



  // public async createLocation(): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {

  //     let location_id: string = this.dataService.generateID();

  //     let location = new LocationDerivedModel();
  //     // location[location_id].setInitialData(location[i]);
  //     location.setName("location " + location_id);
  //     location.setId(location_id);
  //     location.setProtoParent("PureEng");

  //     let location_details = new Location_Details();
  //     location_details.setCreated('1');
  //     let point = new Point();
  //     point.setX(33 + Math.random() * 3);
  //     point.setY(-120 + Math.random() * 3);
  //     location_details.setLatLong(point);
  //     location_details.setLevel(1);
  //     location_details.setModified('1')
  //     location.setDetails(location_details);

  //     //TODO
  //     // organization["PureEng"].addProtoChildren(location_id);

  //     this.addLocation(location).then((resp) => {
  //       if (resp) {
  //         resolve(location_id);
  //       } else {
  //         resolve(undefined);
  //       }
  //     }).catch(err => {
  //       reject();
  //     });
  //   });
  // }

  // public async createFloor(location_parent: LocationDerivedModel): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     let floor_id: string = this.dataService.generateID();

  //     let floor = new FloorDerivedModel();

  //     floor.setName("floor " + floor_id);
  //     floor.setId(floor_id);
  //     floor.setProtoParent(location_parent.getId());

  //     let floorPLan = new FloorplanMessage();

  //     let pointOne = new Point();
  //     pointOne.setX(location_parent.getDetails().getLatLong().getX() - .0001);
  //     pointOne.setY(location_parent.getDetails().getLatLong().getY() - .0001);
  //     let pointTwo = new Point();
  //     pointTwo.setX(location_parent.getDetails().getLatLong().getX() + .0001);
  //     pointTwo.setY(location_parent.getDetails().getLatLong().getY() + .0001);

  //     floorPLan.setCornerOne(pointOne);
  //     floorPLan.setCornerTwo(pointTwo);
  //     floorPLan.setImage(undefined);

  //     floor.setFloorplan(floorPLan);
  //     location_parent.addProtoChildren(floor.getId())


  //     let body: any = {
  //       'name': floor.getName(),
  //       'lat_long': {
  //         "type": "Point",
  //         "coordinates": [
  //           pointOne.getX(),
  //           pointOne.getY()
  //         ]
  //       }
  //     }

  //     this.updateLocation(location_parent, body).then(res => {
  //       if (res) {
  //         this.addFloor(floor).then((resp) => {
  //           if (resp) {
  //             resolve(floor_id);
  //           } else {
  //             resolve(undefined);
  //           }
  //         }).catch(err => {
  //           reject(err);
  //         });
  //       } else {
  //         resolve(undefined)
  //       }
  //     });
  //   });
  // }


}

