import { Component, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import * as leaflet from 'leaflet';
import { FloorDerivedModel, DataService } from '@app/services';
import { MapBuilder } from '@app/_models/map/map-builder';
import { FeatureGroup } from '@app/_models/map/feature-group';
import { DrawService } from '@app/services/map/draw.service';
import { Platform, AlertController } from '@ionic/angular';
import { ZoomLevel } from '@app/_models/map/map-constants';

class FloorGeoJsonFeatureGroups {
  floor: FloorDerivedModel = undefined;
  featureGroups?: FeatureGroup[] = [];
}

const ActiveLayerOptions: leaflet.PathOptions = {
  fillColor: "#ffffff",
  weight: 2,
  opacity: 1,
  color: "#dd941f",
  fillOpacity: 0.7,
}

const InactiveLayerOptions: leaflet.PathOptions = {
  fillColor: "#ffffff",
  weight: 2,
  opacity: 1,
  color: "#000000",
  fillOpacity: 0.7,
}

@Component({
  selector: 'map-draw',
  templateUrl: './draw.component.html',
  styleUrls: ['../map.component.scss'],
})
export class MapDrawComponent extends MapBuilder {
  @ViewChild('map', { static: false }) mapContainer: ElementRef;

  public isEditing = new BehaviorSubject(false);

  public _layerGroup: leaflet.LayerGroup = new leaflet.LayerGroup();
  public _activeFeatureGroup: FeatureGroup = undefined;
  get activeFeatureGroup() {
    return this._activeFeatureGroup
  }
  set activeFeatureGroup(featureGroup: FeatureGroup) {
    this._activeFeatureGroup = featureGroup;
  }

  public _floorFeatureGroups: FloorGeoJsonFeatureGroups = new FloorGeoJsonFeatureGroups();
  get floorFeatureGroups() {
    return this._floorFeatureGroups
  }
  set floorFeatureGroups(floorFeatureGroups: FloorGeoJsonFeatureGroups) {
    this._floorFeatureGroups = floorFeatureGroups;
  }
  public drawControl: leaflet.Control.Draw = undefined;

  constructor(
    public dataService: DataService,
    public drawService: DrawService,
    public platform: Platform,
    public alertController: AlertController,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.initializeMap(this.mapContainer.nativeElement);
    this.menu.openSideMenu();
  }

  public initializeMap(mapId: string) {
    this.generateMap(mapId);
    this.mapObs = of(this.map);

    if (this.initial_zoom) {
      this.setViewFromPoint(this.initial_zoom, ZoomLevel.stationsZoomLevel);
    }

    this.initializeFloorFeatureGroups(this.floorModel);

    this._layerGroup.addTo(this.map);

    this.isEditing.subscribe((isEditing) => {
      if (this.drawControl) {
        this.map.removeControl(this.drawControl);
        this.drawControl = undefined;
      }
      this.drawControl = this.drawService.createDrawControl(this.activeFeatureGroup);
      if (isEditing) {
        this.map.addControl(this.drawControl);
      }
    });

    this.map.on(leaflet.Draw.Event.CREATED, (e) => {
      this.addLayer(e.layer);
    });

    if(this.locationModel) this.setViewFromPoint(this.locationModel.getLatLong());
  }

  draw() {
    this.clear();

    this._floorFeatureGroups.featureGroups.forEach(featureGroup => {
      if (featureGroup.layerOptions.hidden === false) {
        this._layerGroup.addLayer(featureGroup);
      }
    });
  }

  clear() {
    this._layerGroup.clearLayers();
  }

  private initializeFloorFeatureGroups(floor: FloorDerivedModel) {
    if(!floor) return;

    this.floorFeatureGroups.floor = floor;

    let geoJson: string = floor.getGeojson().getJson();
    let layerGroups = this.drawService.createLayersFromGeoJSON(geoJson, InactiveLayerOptions);

    layerGroups.forEach(layerGroup => {
      let featureGroup = new FeatureGroup('test', layerGroup);
      this.setClickEvent(featureGroup);
      this._floorFeatureGroups.featureGroups.push(featureGroup);
    });

    this.draw();
  }

  private addLayer(layer: leaflet.GeoJSON) {
    if (!layer) return;
    if (!this.activeFeatureGroup) return;

    layer.setStyle(ActiveLayerOptions);
    this.activeFeatureGroup.addLayer(layer);
    this.draw();
  }

  showAll() {
    this.floorFeatureGroups.featureGroups.forEach(featureGroup => {
      featureGroup.layerOptions.hidden = false;
    });
    this.draw();
  }

  hideAll() {
    this.floorFeatureGroups.featureGroups.forEach(featureGroup => {
      featureGroup.layerOptions.hidden = true;
    });
    this.draw();
  }

  toggleFeatureGroup(featureGroup: FeatureGroup) {
    if (!featureGroup) return;

    if (featureGroup.layerOptions.hidden === false) {
      featureGroup.layerOptions.hidden = true;
    } else {
      featureGroup.layerOptions.hidden = false;
    }

    this.draw();
  }

  addFeatureGroup() {
    let layerGroup = new leaflet.LayerGroup();

    let featureGroup = new FeatureGroup('New Layer', layerGroup);
    this.setClickEvent(featureGroup);
    this._floorFeatureGroups.featureGroups.push(featureGroup);

    this.draw();
  }

  removeFeatureGroup(featureGroup: FeatureGroup) {
    if (!featureGroup) return;

    let index = this.floorFeatureGroups.featureGroups.indexOf(featureGroup);
    this.floorFeatureGroups.featureGroups.splice(index, 1);

    this.draw();
  }

  setActiveFeatureGroup(featureGroup: FeatureGroup) {
    if (!featureGroup) return;

    this.setInactivateFeatureGroups();

    featureGroup.layerOptions.active = true;
    featureGroup.getLayers().forEach((layer: leaflet.GeoJSON) => {
      layer.setStyle(ActiveLayerOptions);
    });
    this.activeFeatureGroup = featureGroup;

    this.isEditing.next(true);
  }

  setAllFeatureGroupsInactive() {
    this._floorFeatureGroups.featureGroups.forEach(featureGroup => {
      featureGroup.layerOptions.active = false;

    });

    this.setInactivateFeatureGroups();
    this.activeFeatureGroup = undefined;

    this.isEditing.next(false);
  }

  private setClickEvent(featureGroup: FeatureGroup) {
    featureGroup.on('click', (e) => {
      this.setActiveFeatureGroup(e.target);
    });
  }

  private setInactivateFeatureGroups() {
    this.floorFeatureGroups.featureGroups.forEach(featureGroup => {
      featureGroup.layerOptions.active = false;
      featureGroup.getLayers().forEach((layer: leaflet.GeoJSON) => {
        layer.setStyle(InactiveLayerOptions);
      });
    });

    this.isEditing.next(false);
  }

}
