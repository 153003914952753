import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoaderComponent } from '@app/components';

@Injectable()
export class LoaderService {

  public modal: HTMLIonModalElement;

  public forceHiddenLoad:boolean = false; // will skip the next loader from showing on screen.

  constructor(
    private modalController: ModalController,
  ) {
    this.createLoader().then((modal)=>{
      this.modal = modal;
      this.modal.present();
      this.modal.hidden = false;
    })
  }

  public show(){
    if(this.forceHiddenLoad){
      this.forceHiddenLoad = false;
      return;
    }
    if(this.modal) this.modal.hidden = false;
  }

  public hide(){
    if(this.modal) this.modal.hidden = true;
  }

  private async createLoader(): Promise<HTMLIonModalElement> {

    return new Promise<HTMLIonModalElement>((resolve, reject) => {
      this.modalController.create({
        component: LoaderComponent,
        backdropDismiss: true,
        keyboardClose: false,
        showBackdrop: true,
        cssClass: "modal-fullscreen"
      }).then((response: HTMLIonModalElement) => {
        resolve(response);
      }).catch(err => {
        reject(err);
      });
    });

  }


}
