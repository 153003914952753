import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from "@services";
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user: User = this.authenticationService.userValue;

        if (user) {
            // check if route is restricted by role
            if (route.data.roles) {
                //console.log(route.data.roles[0], this.authenticationService.userValue.role, route.data.roles[0] <= this.authenticationService.userValue.role);

                if (route.data.roles[0] <= this.authenticationService.userValue.role) {
                    // role not authorised so redirect to home page
                    return true;
                }
            }
            // this.navService.navigateAsRoot(Routes.dashboard);
            return false;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}