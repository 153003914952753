import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { colors } from '@app/_models';

export enum Position {
  bottom = "bottom",
  middle = "middle",
  top = "top",
}

export enum Side {
  start = "start",
  end = "end"
}

export enum roleResults {
  accept = "accept",
  cancel = "cancel"
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toast: ToastController
  ) {

  }

  public async presentToast(message: string, color: colors = colors.primary, position: Position = Position.middle) {
    const toast = await this.toast.create({
      message: message,
      color: color,
      position: position,
      duration: 3000
    });
    toast.present();
  }

  //Can be used for all dialogs
  async presentToastUserEnsure(
    header:string,
    message: string,
    color: colors = colors.primary,
    position: Position = Position.middle,
    ): Promise<Boolean> {

    const toast = await this.toast.create({
      header: header,
      message: message,
      position: position,
      color: color,
      buttons: [
        {
          icon: 'checkmark-outline',
          text: 'Accept',
          role: roleResults.accept,
        }, {
          text: 'Cancel',
          role: roleResults.cancel,
        }
      ]
    });
    toast.present();

    return new Promise<Boolean>((resolve, reject) => {
      toast.onDidDismiss().then(result => {
        if (result.role == roleResults.accept) {
          resolve(true);
        } else if (result.role == roleResults.cancel) {
          reject();
        }
        reject();
      });
    });

  }

}
