import * as leaflet from 'leaflet';
import { LocationDerivedModel, FloorDerivedModel } from '@app/services/models';
import { Locations, Floors } from '@app/services/graph.service';

interface MenuLocationItem {
    name: string;
    latLng: leaflet.LatLng
    location: LocationDerivedModel;
}

interface MenuFloorItem {
    name: string;
    latLng: leaflet.LatLng;
    floor: FloorDerivedModel;
}


export class MapMenu {
    menuLocationItems: MenuLocationItem[] = []
    menuFloorItems: MenuFloorItem[] = [];
    menuStateClass: string = '';

    public clearMenu() {
        this.menuLocationItems = [];
        this.menuFloorItems = [];
    }

    public openSideMenu() {
        this.menuStateClass = 'open';
    }

    public closeSideMenu() {
        this.menuStateClass = '';
    }

    public toggleSideMenu(){
        if(this.menuStateClass == 'open'){
            this.menuStateClass = '';
        }else{
            this.menuStateClass = 'open';
        }
    }

    public setLocationsMenu(locations: Locations) {
        if (!locations) return;

        Object.keys(locations).forEach(key => {
            let location = locations[key];

            let item: MenuLocationItem = <MenuLocationItem>{};
            item.name = location.getName();
            item.latLng = new leaflet.LatLng(location.getLatLong().getX(), location.getLatLong().getY());
            item.location = location;
            this.menuLocationItems.push(item);
        });
    }

    public setFloorsMenu(floors: Floors) {
        if (!floors) return;
        this.menuFloorItems = [];

        Object.keys(floors).forEach(key => {
            let floor = floors[key];

            if (floor) {
                let item: MenuFloorItem = <MenuFloorItem>{};
                item.name = floor.getName();
                item.floor = floor;
                this.menuFloorItems.push(item);
            }
        });
    }

}