import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { ApiService } from '@app/services/api.service';

@Component({
	selector: 'app-tag-input-page',
	templateUrl: './tag-input-page.page.html',
	styleUrls: ['./tag-input-page.page.scss'],
})
export class TagInputPagePage implements OnInit {

	public myModel = ''
	public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

	public macAddress:string = "";
	public tagName:string = "";
	public details:string = "";


	constructor(
		public toastCtrl: ToastController,
		public apiService: ApiService,
		public modalController:ModalController,
		) {
	}
	
	ngOnInit() {
	}

	ionViewDidLoad() {
		console.log('ionViewDidLoad TagInputPage');
	}

	save(){
		this.updateAsset();
	}

	updateAsset() {
		this.macAddress = this.macAddress.toLowerCase();
		this.macAddress = this.macAddress.replace(/:/g,'');
		this.macAddress = this.macAddress.substring(0,12);
		let body: any = {
			macAddress: this.macAddress,
			name: this.tagName,
			service: "TAG",
			details: this.details,
		}

		console.log(" :: THIS IS WHATS BEING SENT TO SERVER :: ", body);
		this.apiService.createDevice(body).then(async (response) => {
			console.log(" :: THIS IS WHATS BEING  Received from Server : ", response.toObject());

			let toast = await this.toastCtrl.create({
				message: 'Asset updating...',
				duration: 1000,
				position: 'middle',
				cssClass: 'info'
			});

			// let update : PureLocateDevice = this.asset;
			// this.store.dispatch(new DevicesActions.UpdateDevice(update));
			this.modalController.dismiss();

			toast.present();

			
		}).catch( async (err) => {
			console.log(":: Asset Update Error : ", err);
			
			let toast = await this.toastCtrl.create({
				message: 'Asset updating Error',
				duration: 1000,
				position: 'middle',
				cssClass: 'info'
			});
			toast.present();
		})
	}

	close(){
		this.modalController.dismiss();

	}

}
