import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DataService, FilterOptions, INTIAL_FILTEROPTIONS, Sort_State } from 'src/app/services/data.service';
import { AssetDerivedModel } from 'src/app/services/models/asset-derived-model.service';
import { NavigationService } from '@app/services/navigation.service';
import { GraphService } from '@app/services';

@Component({
	selector: 'asset-paginated-table',
	templateUrl: './asset-paginated-table.component.html',
	styleUrls: ['./asset-paginated-table.component.scss'],
})
export class AssetPaginatedTableComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@Input() enabledColumns = ['online', 'name', 'battery', 'aux', 'location', 'floor', 'base_station', 'last_report'];
	@Input() isPaginated = true;

	displayedColumns = ['online', 'name', 'battery', 'aux', 'location', 'floor', 'base_station', 'last_report'];

	public dataSource: MatTableDataSource<AssetDerivedModel> = new MatTableDataSource<AssetDerivedModel>([]);
	public pageSize = 25;
	public currentPage = 0;
	public options: FilterOptions = INTIAL_FILTEROPTIONS;
	public length = 0;
	public isLoadingResults = true;

	constructor(
		public navService: NavigationService,
		public dataService: DataService,
		public graphService: GraphService,
	) {
	}

	public ngOnInit() {
		this.setFilteredItems('');

		this.options.claimed = true;
		this.options.unclaimed = true;

		this.dataService.serverDataCounts.asset_total_count.subscribe(() => {
			this.getArray();
		});
	}

	ionViewWillLeave() {
		this.pageSize = 10;
		this.currentPage = 0;
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.pageSize = e.pageSize;
		this.getArray();
	}

	private async getArray(): Promise<void> {
		this.isLoadingResults = true;

		return new Promise<void>((resolve) => {
			this.dataService.filterAssets(this.options).then((response) => {
				if (response.length != 0) {
					this.isLoadingResults = false;
					this.length = response.length;
					response = response.slice(this.currentPage * this.pageSize, this.currentPage * this.pageSize + this.pageSize);
					this.dataSource = new MatTableDataSource<AssetDerivedModel>(response);
				}
				resolve();
			});
		});
	}

	setFilterState(state: number) {
		this.options.status = state;
		this.currentPage = 0;
		console.log("Search term changed: ", this.options);

		this.getArray();
	}

	setFilteredItems(searchTerm) {
		this.options.term = searchTerm;
		console.log("Search term changed: ", searchTerm);

		this.dataService.setAssetSearchSimilarity(this.options.term, this.options.claimed, this.options.unclaimed);

		if (this.options.term.length == 0) this.dataService.sortAssets(Sort_State.Alphabetical);
		else this.dataService.sortAssets(Sort_State.Similarity);

		this.currentPage = 0;

		this.getArray();
	}
}
