import * as L from "leaflet";
import { GraphLayerGroups } from "..";
import { ZoomLevel } from "./map-constants";

export class MapControls {
    public addToMap(map: L.Map, layerGroups: GraphLayerGroups) {
        let baseMaps = this.generateBaseMaps();
        let overlayMaps = {
            // "Current Location": this.leafletCurrentLayerGroup,
            "Stations": layerGroups.stationLayerGroup,
            "Assets": layerGroups.assetLayerGroup,
            "FloorPlans": layerGroups.floorLayerGroup,
            "Drawings": layerGroups.floorJsonLayerGroup,
        };
        L.control.layers(baseMaps, overlayMaps).addTo(map);
        map.addLayer(baseMaps['Positron']);
    }

    private generateBaseMaps(): any {
        var hydda = L.tileLayer('https://{s}.tile.openstreetmap.se/hydda/base/{z}/{x}/{y}.png', {
            attribution: '',
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var CartoDB_PositronNoLabels = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
            attribution: '',
            subdomains: 'abcd',
            maxZoom: 24,
        });

        var CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
            attribution: '',
            subdomains: 'abcd',
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var mapboxlayer = L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
            maxZoom: ZoomLevel.maxZoomedIn,
            id: 'mapbox.streets',
            accessToken: 'pk.eyJ1IjoiYmJyb29rMTU0IiwiYSI6ImNpcXN3dnJrdDAwMGNmd250bjhvZXpnbWsifQ.Nf9Zkfchos577IanoKMoYQ',
        });

        var mapBox2layer = L.tileLayer('http://{s}.tiles.mapbox.com/v3/gvenech.m13knc8e/{z}/{x}/{y}.png', {
            attribution: '',
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var openstreetlayer = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '',
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var arcgislayer = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: '',
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var googleHybrid = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: ZoomLevel.maxZoomedIn,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        });

        var googleStreets = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var googleSat = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            maxZoom: ZoomLevel.maxZoomedIn,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        });

        var googleTerrain = L.tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var googleRoads = L.tileLayer('https://{s}.google.com/vt/lyrs=h&x={x}&y={y}&z={z}', {
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var openstreetmap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: ZoomLevel.maxZoomedIn,
        });

        var defaultTileLayer = {
            domain: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            maxZoom: ZoomLevel.maxZoomedIn,
        };

        let baseMaps = {
            // "OpenStreets": openstreetlayer,
            // "ArcGIS": arcgislayer,
            "Positron": CartoDB_PositronNoLabels,
            "DarkMatter": CartoDB_DarkMatter,
            // "Hydda": hydda,
            // "BaseCarto": basemapCartoNoLabels,
            // "MapBox": mapboxlayer,
            // "MapBox Alt": mapBox2layer,
            "Google Hybrid": googleHybrid,
            "Google Streets": googleStreets,
            "Google Sat": googleSat,
            "Google Terrain": googleTerrain,
            // "Google Roads": googleRoads,
            // "Wikipedia":wikipedia,
        };

        return baseMaps;
    }


}