import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DataService, FilterOptions, INTIAL_FILTEROPTIONS, Sort_State } from 'src/app/services/data.service';
import { LocationDerivedModel } from 'src/app/services/models/location-derived-model.service';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { NavigationService } from '@app/services/navigation.service';
import { GraphService } from '@app/services';

@Component({
	selector: 'location-paginated-table',
	templateUrl: './location-paginated-table.component.html',
	styleUrls: ['./location-paginated-table.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class LocationPaginatedTableComponent implements OnInit {
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	@Input() displayedColumns: string[] = ['name'];
	@Input() isPaginated = true;

	public locations = ['name'];

	public displayData: MatTableDataSource<LocationDerivedModel> = new MatTableDataSource<LocationDerivedModel>([]);

	@Input() pageSize = 25;
	public currentPage = 0;

	public isLoadingResults = true;
	public isRateLimitReached = false;

	public length = 0;
	public options: FilterOptions = INTIAL_FILTEROPTIONS;

	//expandedElement - Declared in order to supress Error on compile
	public expandedElement;

	constructor(
		public navService: NavigationService,
		public dataService: DataService,
		public graphService: GraphService
	) {

	}

	public ngOnInit() {
		this.setFilteredItems('');

		this.dataService.serverDataCounts.location_count.subscribe(value => {
			this.length = value;
			this.getArray();
		});

		this.getArray();
	}

	ionViewWillLeave() {
		this.pageSize = 10;
		this.currentPage = 0;
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.pageSize = e.pageSize;
		this.getArray();
	}

	private async getArray(): Promise<void> {
		this.isLoadingResults = true;

		return new Promise<void>((resolve, reject) => {
			this.dataService.filterLocations(this.options).then((response) => {
				if (response.length != 0) {
					this.isLoadingResults = false;
					this.length = response.length;
					response = response.slice(this.currentPage * this.pageSize, this.currentPage * this.pageSize + this.pageSize);
					this.displayData = new MatTableDataSource<LocationDerivedModel>(response);
				}
				resolve();
			});
		});
	}

	setFilteredItems(searchTerm: string) {
		this.options.term = searchTerm;

		this.dataService.setLocationSearchSimilarity(this.options.term);

		if (this.options.term.length == 0) this.dataService.sortLocations(Sort_State.Alphabetical);
		else this.dataService.sortLocations(Sort_State.Similarity);

		this.currentPage = 0;

		this.getArray();

	}

	goToLocationDetails(location: LocationDerivedModel){
		this.navService.navigateAsRoot(this.navService.routes.locationDetails, location)
	}

}
