import { Injectable } from '@angular/core';
import opencage from 'opencage-api-client';

const geoCode_key: string = 'a81faa83bd864280ab179502a17ab2f6'

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor() { }

  public async findAddress(address: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      opencage.geocode({ q: address, key: geoCode_key}).then((response: any) => {

        if (response.results.length > 0) {
          resolve(response.results[0].geometry);
          return true;
        } else {
          reject();
        }

      }).catch(err => {
        reject(err);
      });
    });
  }
  
}
