import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@modules';
import { VisgraphComponent } from './visgraph/visgraph.component';
import { UserComponent } from '@app/user/user.component';
import { AssetPaginatedTableComponent } from './tables/asset-paginated-table/asset-paginated-table.component';
import { StationPaginatedTableComponent } from './tables/station-paginated-table/station-paginated-table.component';
import { LocationPaginatedTableComponent } from './tables/location-paginated-table/location-paginated-table.component';
import { LocationTableComponent } from './tables/location-table/location-table.component';
import { FloorTableComponent } from './tables/floor-table/floor-table.component';
import { StationTableComponent } from './tables/station-table/station-table.component';
import { AssetTableComponent } from './tables/asset-table/asset-table.component';
import { MapImageComponent } from './map/map-image/map-image.component';
import { MapAllLocationsComponent } from './map/map-all-locations/map-all-locations.component';
import { MapPlaceLocationComponent } from './map/map-place-location/map-place-location.component';
import { MapPlaceStationComponent } from './map/map-place-station/map-place-station.component';
import { MapSingleAssetComponent } from './map/map-single-asset/map-single-asset.component';
import { MapSingleLocationComponent } from './map/map-single-location/map-single-location.component';
import { MapSingleStationComponent } from './map/map-single-station/map-single-station.component';
import { MapDrawComponent } from './map/map-draw/draw.component';
import { LoaderComponent } from '.';
import { RefreshComponent } from './refresh/refresh.component';

@NgModule({
  declarations: [
    UserComponent,

    MapDrawComponent,
    MapAllLocationsComponent,
    MapImageComponent,
    MapPlaceLocationComponent,
    MapPlaceStationComponent,
    MapSingleAssetComponent,
    MapPlaceStationComponent,
    MapSingleAssetComponent,
    MapSingleLocationComponent,
    MapSingleStationComponent,

    RefreshComponent,

    AssetPaginatedTableComponent,
    StationPaginatedTableComponent,
    LocationPaginatedTableComponent,
    LocationTableComponent,
    FloorTableComponent,
    StationTableComponent,
    AssetTableComponent,
    LoaderComponent,
    VisgraphComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    AngularMaterialModule,
  ],
  exports: [
    UserComponent,

    MapDrawComponent,
    MapAllLocationsComponent,
    MapImageComponent,
    MapPlaceLocationComponent,
    MapPlaceStationComponent,
    MapSingleAssetComponent,
    MapPlaceStationComponent,
    MapSingleAssetComponent,
    MapSingleLocationComponent,
    MapSingleStationComponent,
    
    RefreshComponent,

    AssetPaginatedTableComponent,
    StationPaginatedTableComponent,
    LocationPaginatedTableComponent,
    LocationTableComponent,
    FloorTableComponent,
    StationTableComponent,
    AssetTableComponent,
    LoaderComponent,
    VisgraphComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
