import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { GRPCResolverService } from '@app/services/grpc-resolver.service';
import { AuthenticationService } from '@services';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {


  constructor(
    public grpcService: GRPCResolverService,
    public dataService: DataService,
    private authService: AuthenticationService,
    private apiService: ApiService
    ) { 
  }
  
  ngOnInit() {

  }
 
  ionViewWillEnter(){

  }

  ionViewDidEnter(){
  }

  ionViewWillLeave(){

  }

  ionViewDidLeave(){

  }

  logout() {
    this.authService.logout();
  }

}