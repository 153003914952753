
// NOTE :: This is a work in progress. This is what i plan on using to extend from type "Things" from protobufs
// It may not work out exactly as this is typed, but at least this is a start

/// Thing
///    |_ Devices
///         |_ PureLocate
///         |_ ... other devices
///

/// Goals:
///  - These interfaces are directly generated from protos
///  - These interfaces match up with what is being parsed from the devices Kaitai Parser to be "castable" over the device's untyped parsed message

import { GroupType } from '../groups/groups.model'

export interface Device {
    id: string;
    type: number; // Group Type
    groups?: string[];
    details?: string,
	keyValueList?: JSON[],
}

export const INITIAL_DEVICE: Device = {
    id: "undefined",
    type: GroupType.unknown,
};

// Interfaces Derived off this should come as concequence of the protobuf for that specific "thing"