import * as leaflet from 'leaflet';
import { AssetDerivedModel, LocationDerivedModel, StationDerivedModel } from '@app/services/models';
import { Point } from '@protos';
import { Assets, Stations, Locations } from '@app/services/graph.service';
import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { MarkerFilter, StatusFilter } from '@app/_models/map/map-constants';

enum MarkerColor {
  location_color = 'cadetblue',
  location_fill_color = 'cadetblue',
  station_color = '#9adff5',
  station_fill_color = '#60C3E2',
  asset_color = '#000000',
  asset_fill_color = '#000000',
  placement_color = 'red',
  placement_fill_color = 'red',
}

const defaultMarkerRadius: number = 0.25;
const defaultFillOpacity: number = 1;
const locationWeight: number = 15;
const placemenWeight: number = 15;

const LocationMarkerOptions: leaflet.CircleMarkerOptions = {
  color: MarkerColor.location_color,
  fillColor: MarkerColor.location_fill_color,
  radius: defaultMarkerRadius,
  fillOpacity: defaultFillOpacity,
  weight: locationWeight,
};

const stationMarkerOptions: leaflet.CircleMarkerOptions = {
  color: MarkerColor.station_color,
  fillColor: MarkerColor.station_fill_color,
  radius: defaultMarkerRadius,
  fillOpacity: defaultFillOpacity,
};

const assetMarkerOptions: leaflet.CircleMarkerOptions = {
  color: MarkerColor.asset_color,
  fillColor: MarkerColor.asset_fill_color,
  radius: defaultMarkerRadius,
  fillOpacity: .35,
  opacity: .0,
};

const placementMarkerOptions: leaflet.CircleMarkerOptions = {
  color: MarkerColor.placement_color,
  fillColor: MarkerColor.placement_fill_color,
  radius: defaultMarkerRadius,
  fillOpacity: defaultFillOpacity,
  weight: placemenWeight,
}

@Injectable({
  providedIn: 'root'
})
export class MarkerService {

  constructor(
    public navCtrl: NavController,
  ) { }

  private generateMarker(latLng: Point, options: leaflet.CircleMarkerOptions) {
    return leaflet.circle([latLng.getX(), latLng.getY()], options);
  }

  public generateStationMarker(station: StationDerivedModel): leaflet.Circle {
    if (!station) return;
    if (!station.getLatLong()) return;

    let latlng = station.getLatLong();
    let marker = this.generateMarker(latlng, stationMarkerOptions);
    let popup = this.createStationPopup(station);

    marker.bindPopup(popup);
    marker.bringToFront();

    return marker;
  }

  public generateAssetMarker(asset: AssetDerivedModel): leaflet.Circle {
    if (!asset) return
    if (!asset.getLatLong()) return;

    let marker = this.generateMarker(asset.calculated_position, assetMarkerOptions);
    let popup = this.createAssetPopup(asset)

    marker.bindPopup(popup)
    marker.bringToBack();

    var toggle = false;
    marker.on('click', () => {
      toggle = !toggle;
      if(toggle) marker.openPopup();
      else marker.closePopup();
    });
    marker.on('popupopen', ()=>{
      this.configureSelectedMarker(marker, asset)
    })

    marker.on('popupclose', ()=>{
      this.configureDefaultMarker(marker)
    })

    return marker;
  }

  public generateLocationMarker(location: LocationDerivedModel, map?:any): leaflet.Circle {
    if (!location) return;
    if (!location.getLatLong()) return;

    let latlng = location.getLatLong();
    let marker = this.generateMarker(latlng, LocationMarkerOptions);

    // this.setClickEvent(marker, clickType.click, onDoubleClick);

    marker.bindPopup(this.createLocationPopup(location));

    marker.on('mouseover', function (e) { this.openPopup(); });
    marker.on('mouseout', function (e) { this.closePopup(); });

    marker.on('click', (e:any) => {
      console.log("Clicked: ", e);
      map.setView(e.latlng, 19);
    });

    latlng = location.getLatLong();
    if (latlng) {
      marker.setLatLng([latlng.getX(), latlng.getY()]);
    }

    return marker;
  }

  public generatePlacementMarker(map: leaflet.Map): leaflet.Circle {
    if (!map) return;

    let point = new Point();
    point.setX(map.getCenter().lat);
    point.setY(map.getCenter().lng);


    let marker = this.generateMarker(point, placementMarkerOptions);
    map.on('move', function (e) {
      this.setLatLng(map.getCenter());
    }.bind(marker));

    return marker;
  }

  createLocationMarkers(locations: Locations, map?:any): leaflet.Circle[] {
    if (!locations) return;

    let markers: leaflet.Circle[] = [];

    Object.keys(locations).forEach(key => {
      let location = locations[key];

      let marker: leaflet.Circle<any>;
      marker = this.generateLocationMarker(location, map);
      markers.push(marker);
    });

    return markers;
  }


  public createStationsMarkers(stations: Stations, filter: MarkerFilter = { term: '', status: 0 }): leaflet.Circle[] {
    if (!stations) return;

    let markers: leaflet.Circle[] = [];
    Object.keys(stations).forEach(key => {
      let station = stations[key];
      let isValid = true;

      if (filter.status == StatusFilter.None && !station.isOnline) isValid = false;
      if (filter.status == StatusFilter.Offline && !station.isOnline) isValid = false;
      if (filter.status == StatusFilter.Online && station.isOnline) isValid = false;

      // if (filter.term) {
      //   if (similarity(filter.term, station.getName()) < likeness_threshold) isValid = false;
      // }

      if (isValid) markers.push(this.generateStationMarker(station));
    });

    return markers;
  }


  public createAssetsMarkers(assets: Assets, filter: MarkerFilter = { term: '', status: 0 }): leaflet.Circle[] {
    if (!assets) return;

    let markers: leaflet.Circle[] = [];
    Object.keys(assets).forEach(key => {
      let asset = assets[key];
      let isValid = true;

      if (filter.status == StatusFilter.None && !asset.isOnline) isValid = false;
      if (filter.status == StatusFilter.Offline && asset.isOnline) isValid = false;
      if (filter.status == StatusFilter.Online && !asset.isOnline) isValid = false;

      // if (filter.term) {
      //   if (similarity(filter.term, asset.getName()) < likeness_threshold) isValid = false;
      // }

      if (isValid) markers.push(this.generateAssetMarker(asset));
    });

    return markers;
  }

  public createLocationPopup(location: LocationDerivedModel): leaflet.Popup {
    let popup = new leaflet.Popup();

    popup.setContent("<h1>" + location.getName() + "</h1>" + "");

    return popup;
  }

  public createNavigationEvent(id: string, navigation: string[]) {
    return (event: leaflet.LeafletEventHandlerFn) => {
      let navigationExtras: NavigationExtras = {
        state: {
          id: id,
        }
      };
      this.navCtrl.navigateForward(navigation, navigationExtras);
    };
  }

  public createStationPopup(station: StationDerivedModel): leaflet.Popup {
    let text: string = '';
    text += "<h1>" + station.getName() + "</h1>";
    text += "Last Seen " + station.formatted_lastReport;
    text += "<br>At " + station.getName()
    text += "<br>RSSI:" + station.getData().getRecord().getWifiRssi() + " dBm";

    let popup = new leaflet.Popup();
    popup.setContent(text);

    return popup;
  }

  public createAssetPopup(asset: AssetDerivedModel): leaflet.Popup {
    let text: string = '';
    text += "<h1>" + asset.getName() + "</h1>";
    text += "Last Seen " + asset.formatted_elapsed;
    if(asset.hasData()){
      if (asset.getData().getCalculatedPosition().getName()) text += "<br>At " + asset.getData().getCalculatedPosition().getLocationName() + " near " + asset.getData().getCalculatedPosition().getName();
    }
    text += "<br>" + asset.formatted_time_arrived;
    if(asset.hasData()){
      text += "<br>RSSI:" + asset.getData().getCalculatedPosition().getRssi() + " dBm";
    }

    let popup = new leaflet.Popup();
    popup.setContent(text);

    return popup;
  }

  public configureSelectedMarker(current_marker: leaflet.Circle, asset: AssetDerivedModel) {
    let calculated_location = asset.calculated_position;
    current_marker.setLatLng([calculated_location.getX(), calculated_location.getY()]);

    current_marker.setRadius(asset.accuracy);
    current_marker.setStyle({ fillOpacity: 0.2, opacity: 1, })

    //this.leafletTempLayerGroup.clearLayers();
    //this.leafletTempLayerGroup.addLayer(leaflet.circle([calculated_location.latitude, calculated_location.longitude], { radius: .25, color: 'black', fillColor: 'black', fillOpacity: 1, interactive: false }));
  }

  public configureDefaultMarker(current_marker) {
    current_marker.setRadius(assetMarkerOptions.radius);
    current_marker.setStyle(assetMarkerOptions);

    //this.leafletTempLayerGroup.clearLayers();
  }


}