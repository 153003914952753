import { Injectable } from '@angular/core';
import { FloorMessage } from 'src/shared/protos/generated/data_pb';
@Injectable({
  providedIn: 'root'
})
export class FloorDerivedModel extends FloorMessage {

	public formatted_created: string = '';
	public formatted_modified: string = '';

  public search_similarity: number = 0;

  constructor(private message: FloorMessage) {
    super();
    Object.assign(this, message);

    this.formatted_created = new Date(message.getCreated()).toUTCString();
		this.formatted_modified = new Date(message.getModified()).toUTCString();
  }

}
