import { Injectable } from '@angular/core';
import { offline_threshold } from '@app/_helpers/constants';
import { formatToLastReport, getMinutes } from '@app/_helpers/time';
import { of } from 'rxjs';
import { StationMessage } from 'src/shared/protos/generated/data_pb';
import { GraphService } from '../graph.service';

@Injectable({
  providedIn: 'root'
})
export class StationDerivedModel extends StationMessage {

  public asset_count: number = 0;
  public asset_online_count: number = 0;

  public isOnline: boolean = false;
  public formatted_lastReport: string = '';
  public formatted_registered: string = '';
  public formatted_lastSeen: string = '';

  public search_similarity: number = 0;

  constructor(
    public message: StationMessage,
    ) {
    super();
    Object.assign(this, message);

    if (!message.getLastSeen()) {
      this.isOnline = false;
      this.formatted_lastReport = 'Unknown';
      return;
    }

    let date_ms = new Date(message.getLastSeen()).getTime();

    if (getMinutes(date_ms) < offline_threshold) this.isOnline = true;
    else this.isOnline = false;

    this.formatted_lastReport = formatToLastReport(date_ms);
  }
}
