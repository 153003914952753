import { Injectable } from '@angular/core';
import { offline_threshold } from '@app/_helpers/constants';
import { convertRssiToDistance } from '@app/_helpers/rssi';
import { formatToLastReport, formatToTimeElapsed, getMinutes } from '@app/_helpers/time';
import { AssetMessage, Asset_Station, Point } from 'src/shared/protos/generated/data_pb';
import * as _ from "lodash"
import moment, { min } from 'moment';

export enum AUX_STATE {
	NONE = 0,
	OUT,
	STORAGE,
	PASSIVE,
	ACTIVE,
}

@Injectable({
	providedIn: 'root'
})
export class AssetDerivedModel extends AssetMessage {

	public accuracy: number = 0;
	public isOnline: boolean = false;

	public formatted_lastReport: string = '';
	public formatted_registered: string = '';
	public formatted_lastSeen: string = '';
	public formatted_elapsed: string = '';
	public formatted_battery_updated: string = '';
	public formatted_battery_with_timestamp:string='';
	public formatted_time_arrived: string = '';
	public has_battery_level:boolean = false;
	public temperature: number = -128;


	public auxState: AUX_STATE = AUX_STATE.NONE;
	public auxStateFormatted: string = 'N/A';

	public rssiButtonOn:boolean = false;
	public rssiButtonPressedFormatted: string = 'Button Off';
	public rssiExtEnabled:boolean = false;
	public rssiExtEnabledFormatted: string = 'HT3 Disabled';
	public rssiExtPowered:boolean = false;
	public rssiExtPoweredFormatted: string = 'HT3 Ext Unpowered';
	public rssiUsbOn:boolean = false;
	public rssiUsbPoweredFormatted: string = 'USB Unpowered';
	public rssiMotion:boolean = false;
	public rssiMotionFormatted: string = 'Motion Off';

	public search_similarity: number = 0;

	private _calculated_position: Point = null;
	get calculated_position(){
		if(!this._calculated_position){
			this.getCalculatedPosition();
		}
		if(!this._calculated_position.getX() || !this._calculated_position.getY()) this._calculated_position = this.message.getLatLong();

		return this._calculated_position;
	}


	constructor(
		public message: AssetMessage) {
		super();
		Object.assign(this, message);

		if (!message.getLastSeen()) {
			this.isOnline = false;
			this.formatted_lastReport = 'Unknown';
			return
		}

		let date_ms = new Date(message.getLastSeen()).getTime();

		if (getMinutes(date_ms) < offline_threshold) this.isOnline = true;
		else this.isOnline = false;

		if(this.rssi_is_used){
			// longer "online" for rssi based tracking
			if (getMinutes(date_ms) < (60)) this.isOnline = true;
			else this.isOnline = false;
		}

		let register_date = new Date(message.getCreated());
		this.formatted_registered = register_date.toLocaleDateString();

		let last_seen_date = new Date(message.getLastSeen())
		this.formatted_lastSeen = last_seen_date.toLocaleDateString() + ' at ' + last_seen_date.toLocaleTimeString();

		this.formatted_lastReport = formatToLastReport(date_ms);
		this.formatted_elapsed = formatToTimeElapsed(message.getData().getCalculatedPosition().getTimestamp());
		if (this.getData()) this.accuracy = convertRssiToDistance(Number.parseInt(this.getData().getCalculatedPosition().getRssi()));

		if(this.getBatteryUpdatedTimestamp()){
			this.formatted_battery_updated = this.adjustedBatteryLevel() + "%";
			this.formatted_battery_with_timestamp = this.getBatteryLevel() + "%  - " + formatToLastReport(this.getBatteryUpdatedTimestamp());
			this.has_battery_level = true;
		}
		if(this.temperature){
			this.temperature = Math.round(this.temperature);
		}
	
		this.formatLocationEntered();
		this.formatRingInformation();
		this.formatRssiUsed();
	}

	private adjustedBatteryLevel() {
		var val = this.getBatteryLevel();
		return Math.floor((val == 100) ? val : (val > 70) ? val/2 :  (val > 50) ? val/3 : val/4)
	}

	formatRssiUsed(){
		if(this.rssi_is_used){
			// format battery
			if(this.eddy_raw_battery_0 || this.eddy_raw_battery_1){
				if(!this.eddy_raw_battery_0){
					this.eddy_raw_battery_0 = 0;
				}
				if(!this.eddy_raw_battery_1){
					this.eddy_raw_battery_1 = 0;
				}

				var buffer = new ArrayBuffer(2);
				var view = new DataView(buffer);
				view.setUint8(1, Number(this.eddy_raw_battery_0));
				view.setUint8(0, Number(this.eddy_raw_battery_1));

				this.eddy_raw_battery = view.getUint16(0, true);
			}


			if(this.rssi_last_usb_powered_epoch || this.rssi_last_usb_unpowered_epoch){
				if(this.rssi_last_usb_powered_epoch>=this.rssi_last_usb_unpowered_epoch || (this.rssi_last_usb_powered_epoch && this.rssi_last_usb_unpowered_epoch == undefined)){
					if(this.rssi_last_usb_powered_epoch>=this.rssi_last_usb_unpowered_epoch && this.rssi_last_usb_unpowered_epoch){
						this.rssiUsbPoweredFormatted = this.timeArrived("USB On Since ", this.rssi_last_usb_unpowered_epoch);
					}
					else {
						this.rssiUsbPoweredFormatted = this.timeArrived("USB On - Last Updated ", this.rssi_last_usb_powered_epoch);
					}
					if( (Date.now() - Number(this.rssi_last_usb_powered_epoch)) <= 24*60*60*1000){
						this.rssiUsbOn = true;
					}
				}
				else {
					if(this.rssi_last_usb_powered_epoch < this.rssi_last_usb_unpowered_epoch && this.rssi_last_usb_powered_epoch){
						this.rssiUsbOn = false;
						this.rssiUsbPoweredFormatted = this.timeArrived("USB Off Since ", this.rssi_last_usb_powered_epoch);;
					}
					else if(this.rssi_last_usb_unpowered_epoch){
						this.rssiUsbPoweredFormatted = this.timeArrived("USB Off - Last Updated ", this.rssi_last_usb_unpowered_epoch);
					}
					else {
						this.rssiUsbPoweredFormatted = "USB Unpowered";
					}
					
				}
			}

			if(this.rssi_last_ext_system_enabled_epoch || this.rssi_last_ext_system_disabled_epoch){
				if(this.rssi_last_ext_system_enabled_epoch>=this.rssi_last_ext_system_disabled_epoch || (this.rssi_last_ext_system_enabled_epoch && this.rssi_last_ext_system_disabled_epoch == undefined) ){
					if(this.rssi_last_ext_system_enabled_epoch>=this.rssi_last_ext_system_disabled_epoch && this.rssi_last_ext_system_disabled_epoch ){
						this.rssiExtEnabledFormatted = this.timeArrived("HT3 Enabled Since ", this.rssi_last_ext_system_disabled_epoch);
					}
					else {
						this.rssiExtEnabledFormatted = this.timeArrived("HT3 Enabled - Last Updated ", this.rssi_last_ext_system_enabled_epoch);
					}
					if( (Date.now() - Number(this.rssi_last_ext_system_enabled_epoch)) <= 24*60*60*1000){
						this.rssiExtEnabled = true;
					}
				}
				else {
					if(this.rssi_last_ext_system_enabled_epoch < this.rssi_last_ext_system_disabled_epoch && this.rssi_last_ext_system_enabled_epoch){
						this.rssiExtEnabledFormatted = this.timeArrived("HT3 Disabled Since ", this.rssi_last_ext_system_enabled_epoch);
					}
					else if(this.rssi_last_ext_system_disabled_epoch){
						this.rssiExtEnabledFormatted = this.timeArrived("HT3 Disabled - Last Updated ", this.rssi_last_ext_system_disabled_epoch);
					}
					else {
						this.rssiExtEnabledFormatted = "HT3 Disabled";
					}
				}
			}

			if(this.rssi_last_ext_powered_epoch || this.rssi_last_ext_unpowered_epoch){
				if(this.rssi_last_ext_powered_epoch>=this.rssi_last_ext_unpowered_epoch  || (this.rssi_last_ext_powered_epoch && this.rssi_last_ext_unpowered_epoch == undefined)){
					if(this.rssi_last_ext_powered_epoch>=this.rssi_last_ext_unpowered_epoch && this.rssi_last_ext_unpowered_epoch){
						this.rssiExtPoweredFormatted = this.timeArrived("HT3 Ext Powered Since ", this.rssi_last_ext_unpowered_epoch);
					}
					else{
						this.rssiExtPoweredFormatted = this.timeArrived("HT3 Ext Powered - Last Updated", this.rssi_last_ext_powered_epoch);
					}
					if( (Date.now() - Number(this.rssi_last_ext_powered_epoch)) <= 24*60*60*1000){
						this.rssiExtPowered = true;
					}
				}
				else {
					if(this.rssi_last_ext_powered_epoch<this.rssi_last_ext_unpowered_epoch && this.rssi_last_ext_powered_epoch){
						this.rssiExtPoweredFormatted = this.timeArrived("HT3 Ext Unpowered Since ", this.rssi_last_ext_powered_epoch);
					}
					else if(this.rssi_last_ext_unpowered_epoch){
						this.rssiExtPoweredFormatted = this.timeArrived("HT3 Ext Unpowered - Last Updated ", this.rssi_last_ext_unpowered_epoch);
					}
					else {
						this.rssiExtPoweredFormatted = "HT3 Ext Unpowered";
					}
				}
			}

			if(this.rssi_last_is_motion_epoch || this.rssi_last_no_motion_epoch){
				if(this.rssi_last_is_motion_epoch>=this.rssi_last_no_motion_epoch || (this.rssi_last_is_motion_epoch && this.rssi_last_no_motion_epoch == undefined) ){
					if(this.rssi_last_is_motion_epoch>=this.rssi_last_no_motion_epoch && this.rssi_last_no_motion_epoch){
						this.rssiMotionFormatted = this.timeArrived("Motion On Since ", this.rssi_last_no_motion_epoch);
					}
					else {
						this.rssiMotionFormatted = this.timeArrived("Motion On - Last Updated ", this.rssi_last_is_motion_epoch);
					}
					if( (Date.now() - Number(this.rssi_last_usb_powered_epoch)) <= 24*60*60*1000){
						this.rssiMotion = true;
					}
				}
				else {
					if(this.rssi_last_is_motion_epoch<this.rssi_last_no_motion_epoch && this.rssi_last_is_motion_epoch){
						this.rssiMotionFormatted = this.timeArrived("Motion Off Since ", this.rssi_last_is_motion_epoch);
					}
					else if(this.rssi_last_no_motion_epoch){
						this.rssiMotionFormatted = this.timeArrived("Motion Off - Last Updated ", this.rssi_last_no_motion_epoch);
					}
					else {
						this.rssiMotionFormatted = "Motion Off";
					}
				}
			}
			if(this.rssi_last_button_pressed_epoch || this.rssi_last_button_depressed_epoch){
				if(this.rssi_last_button_pressed_epoch>=this.rssi_last_button_depressed_epoch || (this.rssi_last_button_pressed_epoch && this.rssi_last_button_depressed_epoch == undefined) ){
					// in case rssi_last_button_depressed_epoch is somehow not seen.
					if(this.rssi_last_button_pressed_epoch>=this.rssi_last_button_depressed_epoch && this.rssi_last_button_depressed_epoch){
						this.rssiButtonPressedFormatted = this.timeArrived("Button Last Pressed ", this.rssi_last_button_depressed_epoch);
					}
					else{
						this.rssiButtonPressedFormatted = this.timeArrived("Button Pressed - Last Updated ", this.rssi_last_button_pressed_epoch);
					}
					if( (Date.now() - Number(this.rssi_last_button_pressed_epoch)) <= 10*60*1000){
						this.rssiButtonOn = true;
					}
				}
				else{
					if(this.rssi_last_button_pressed_epoch<this.rssi_last_button_depressed_epoch && this.rssi_last_button_pressed_epoch){
						this.rssiButtonPressedFormatted = this.timeArrived("Last Button Press ", this.rssi_last_button_pressed_epoch);
					}
					else if(this.rssi_last_button_depressed_epoch){
						this.rssiButtonPressedFormatted = this.timeArrived("Button Off - Last Updated ", this.rssi_last_button_depressed_epoch);
					}
					else {
						this.rssiButtonPressedFormatted = "Button Off";
					}
				}
			}

			if(this.isOnline){
				// Using simplified reporting rssi_last_active_epoch
				if(this.rssi_last_active_epoch>this.rssi_last_inactive_epoch || (this.rssi_last_active_epoch && this.rssi_last_inactive_epoch == undefined)){
					this.auxState = AUX_STATE.ACTIVE;
					if(this.rssi_last_active_epoch>this.rssi_last_inactive_epoch && this.rssi_last_inactive_epoch){
						if(this.rssiUsbOn){
							this.auxStateFormatted = this.timeArrived("Active (USB) - Since ", this.rssi_last_inactive_epoch);
						}
						else {
							this.auxStateFormatted = this.timeArrived("Active (HT3) - Since ", this.rssi_last_inactive_epoch);
						}
					}
					else {
						if(this.rssiUsbOn){
							this.auxStateFormatted = this.timeArrived("Active (USB) - Updated ", this.rssi_last_active_epoch);
						}
						else {
							this.auxStateFormatted = this.timeArrived("Active (HT3) - Updated ", this.rssi_last_active_epoch);
						}
					}
				}
				// this.rssi_last_active_epoch within last 7 days
				else if( this.rssi_last_active_epoch && (Date.now() - Number(this.rssi_last_active_epoch)) <= 7*24*60*60*1000){
					this.auxState = AUX_STATE.PASSIVE;
					this.auxStateFormatted = this.timeArrived("Passive : Last Active - ", this.rssi_last_active_epoch);
				}
				else if(this.rssi_last_active_epoch || this.rssi_last_inactive_epoch) {
					this.auxState = AUX_STATE.STORAGE;
					if(this.rssi_last_active_epoch){
						this.auxStateFormatted = this.timeArrived("Storage : Last Active - ", this.rssi_last_active_epoch);
					}
					else if(this.rssi_last_inactive_epoch){
						this.auxStateFormatted = "Storage : Never Active";
					}
					else {
						this.auxStateFormatted = "Storage : not seen";
					}
				}
				else {
					this.auxState = AUX_STATE.OUT;
					this.auxStateFormatted = "Storage : not seen";
				}
			}
			else {
				this.auxState = AUX_STATE.OUT;
				this.auxStateFormatted = "Out of Range";
			}


		}
	}


	private getCalculatedPosition(){
		if (this.message.hasData() && this.message.getData().getHistoryList().length > 1) {
			this._calculated_position = this.estimateLocationUsingHistory(this.message.getData().getHistoryList());
		}
		else {
			let latitude = _.get(this.message, 'reported_data.calculated_position.latitude');
			let longitude = _.get(this.message, 'reported_data.calculated_position.longitude');

			let calculated_position = new Point();
			calculated_position.setX(latitude)
			calculated_position.setY(longitude)
			this._calculated_position = calculated_position;
		}
	}

	private estimateLocationUsingHistory(history: Asset_Station[]): Point {
		let point = new Point();

		let total_number = history.length;
		let total_distance = 0
		let x = 0;
		let y = 0;

		if (total_number > 5) {
			// Assuming that history is sorted by RSSI. 
			// limit to using 5 strongest stations for estimated location. 
			history.slice(0, 5).map((location) => {
				total_distance += convertRssiToDistance(Number.parseInt(location.getRssi()));
			})

			total_number = 5;
			history.slice(0, 5).map((location) => {

				let scale = (1 - (convertRssiToDistance(Number.parseInt(location.getRssi())) / total_distance)) / (total_number - 1)
				x += location.getLatLong().getX() * scale;
				y += location.getLatLong().getY() * scale
			})
		}
		else {

			history.map((location) => {
				total_distance += convertRssiToDistance(Number.parseInt(location.getRssi()));
			})

			history.map((location) => {
				let scale = (1 - (convertRssiToDistance(Number.parseInt(location.getRssi())) / total_distance)) / (total_number - 1)

				x += location.getLatLong().getX() * scale;
				y += location.getLatLong().getY() * scale
			})
		}

		point.setX(x);
		point.setY(y);

		return point;

	}

	public ring_information:string ="";
	public formatRingInformation(){
		this.ring_information = "";
		if(this.getRingCommandSuccessfulTimestamp() >= this.getRingCommandCreatedTimestamp()){
			this.ring_information = this.timeArrived("Rang ",this.getRingCommandCreatedTimestamp());
			if(this.getRingCommandSuccessfulBy()){
				this.ring_information += " by " + this.getRingCommandSuccessfulBy();
			}
			if(this.getRingCommandSuccessfulCounter()>0){
				this.ring_information = this.ring_information + ", Total: " + this.getRingCommandSuccessfulCounter() + " rings";
			}
			return;
		}
		if(this.getRingCommandSentTimestamp() > this.getRingCommandCreatedTimestamp()){
			this.ring_information = this.timeArrived("Sent ",this.getRingCommandSentTimestamp());
			if(this.getRingCommandSentTo()){
				this.ring_information += " by " + this.getRingCommandSentTo();
			}
			return;
		}
		if(this.getRingCommandCreatedTimestamp() && (Date.now() - this.getRingCommandCreatedTimestamp()) <= 2*60*1000){
			this.ring_information += this.timeArrived("Trying To Ring ",this.getRingCommandCreatedTimestamp());
			return;
		}
		if(this.getRingCommandCreatedTimestamp() && (Date.now() - this.getRingCommandCreatedTimestamp()) > 2*60*1000){
			this.ring_information = this.timeArrived("Failed to Send ",this.getRingCommandCreatedTimestamp());
			return;
		}
	}

	public isRingable() :boolean {
		console.log(" Testing : ", this.getRingCommandCreatedTimestamp());
		if(this.getRingCommandSuccessfulTimestamp() >= this.getRingCommandCreatedTimestamp()){
			return true;
		}
		// if getRingCommandSentTimestamp() > than two minutes
		if(this.getRingCommandCreatedTimestamp() && (Date.now() - this.getRingCommandCreatedTimestamp()) > 2*60*1000){
			return true;
		}
		return false;
	}

	private timeArrived(prefix, timestamp) {
		if (timestamp) {

			let deltaTime = (new Date().getTime()) - timestamp;
			if (deltaTime < 0) { deltaTime = 0; }
			
			if ((deltaTime / 1000) < 120) {
				// get minutes and seconds ago
				let minutes = Math.floor(deltaTime / (1000 * 60));
				let seconds = Math.floor((deltaTime - (minutes * 1000 * 60)) / 1000);
				if(minutes != 0){
					if(minutes == 1){
						prefix = prefix + minutes + " Minute ";
					}
					else {
						prefix = prefix + minutes + " Minutes ";
					}
				}
				return prefix + seconds + " Seconds Ago";
			}
			else if (deltaTime / 60000 < 120) {
				return prefix + Math.floor(deltaTime / 60000) + " Minutes Ago";
			}
			else if (deltaTime / 3600000 < 24) {
				return prefix + Math.floor(deltaTime / 3600000) + " Hours Ago" + " On " + moment(new Date(timestamp)).format("ddd MM/DD hh:mm A");
				// return `${deltaTime/3600000} Hours Ago`;
			}
			else if (deltaTime / (3600000 * 24) < 30) {
				if(Math.floor(deltaTime / (24 * 3600000)) == 1){
					return prefix + Math.floor(deltaTime / (24 * 3600000)) + " Day Ago" + " On " + moment(new Date(timestamp)).format("ddd MM/DD hh:mm A");
				}
				else {
					return prefix + Math.floor(deltaTime / (24 * 3600000)) + " Days Ago" + " On " + moment(new Date(timestamp)).format("ddd MM/DD hh:mm A");
				}
			}
			else {
				// if prefix contains "since"
				if(prefix.indexOf("Since") != -1 || prefix.indexOf("since") != -1){
					return prefix + moment(new Date(timestamp)).format("ddd, MM/DD/YY, H:mm A");
				}
				return prefix+"on " + moment(new Date(timestamp)).format("ddd, MM/DD/YY, H:mm A");
			}
		}
		else {
			return " "
		}
	}

	public formatLocationEntered() {
		if(this.getLocationEnteredTimestamp()){
			this.formatted_time_arrived = this.timeArrived("Arrived ", this.getLocationEnteredTimestamp());
		}
		else {
			this.formatted_time_arrived = "N/A";
		}
	}


}