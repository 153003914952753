const minDistance: number = 1;
const maxDistance: number = 15;

export function convertRssiToDistance(rssi: number, measuredRssiAt1Meter = -69, environmentalScale = 2): number {
    if (!rssi) return;

    let distance = Math.pow(10, (measuredRssiAt1Meter - rssi) / (10 * environmentalScale));
    distance = Math.round(distance * 100) / 100;
    // window["LOG"] && console.log("distance", distance)
    if (distance > maxDistance) {
        distance = maxDistance
    } else if (distance < minDistance) {
        distance = minDistance;
    }

    return distance;
}