////////////////////////////////////////////////////////////
// Services & Providers
////////////////////////////////////////////////////////////
import { ErrorInterceptor, JwtInterceptor, AuthGuard } from '@helpers'
import { AngularMaterialModule } from '@modules';
import * as components from '@components'
import { DataService, GrpcService, AuthenticationService } from '@services'

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
///Ionic & Angular
////////////////////////////////////////////////////////////
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
/// Utils
////////////////////////////////////////////////////////////
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { GRPCResolverService } from './services/grpc-resolver.service';
import { BleService } from './services/ble/ble.service';
import { BLE } from '@ionic-native/ble/ngx';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { MarkerService } from './services/map/marker.service';
import { GraphLayerGroups } from './_models';
import { LoaderComponent } from '@components';
import { MatProgressSpinnerModule } from '@angular/material';
import { WifiService } from './services/ble/wifi.service';
import { AdminSuperMenuPageModule } from './admin/admin-super/admin-super-menu/admin-super-menu.module';
import { ApiService } from './services/api.service';
import { ScannerService } from './services/ble/scanner.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { DashboardPageModule } from './user/dashboard/dashboard.module';
import { WebIntent } from "@ionic-native/web-intent/ngx";
import { BleArPageModule } from './user/modals/ble-ar/ble-ar.module';
import { TagInputPagePageModule } from "./user/locate/tag-input-page/tag-input-page.module"
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { AppLauncher } from '@ionic-native/app-launcher/ngx';
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/ngx';
export function createTranslateLoader(http: HttpClient) { return new TranslateHttpLoader(http, '@assets/i18n/', '.json'); }


@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
		}),
		IonicModule.forRoot({ animated: false }),
		IonicStorageModule.forRoot(),
		WebBluetoothModule.forRoot(),
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		AngularMaterialModule,
		MatProgressSpinnerModule,
		ComponentsModule,
		AdminSuperMenuPageModule,
		DashboardPageModule,
		BleArPageModule,
		TagInputPagePageModule,
	],

	declarations: [
		AppComponent,
	],
	entryComponents: [
		AppComponent,
		components.AssetPaginatedTableComponent,
		components.StationPaginatedTableComponent,
		components.LocationPaginatedTableComponent,
		components.LocationTableComponent,
		components.FloorTableComponent,
		components.StationTableComponent,
		components.AssetTableComponent,
		components.VisgraphComponent,
		LoaderComponent,
	],
	providers: [
		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: initialize_settings,
		// 	multi: true,
		// 	deps: [HttpClient]
		// },
		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: initialize_app,
		// 	multi: true,
		// 	deps: [AppLoaderService]
		// },


		File,
		NativeGeocoder,
		BLE,
		AndroidPermissions,
		AppAvailability,
		InAppBrowser,
		// AppLauncher,

		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },

		StatusBar,
		SplashScreen,
		BarcodeScanner,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		Geolocation,
		AuthGuard,
		ApiService,
		AuthenticationService,
		GrpcService,
		DataService,
		GRPCResolverService,
		LoaderService,
		BleService,
		MarkerService,
		GraphLayerGroups,
		LoaderComponent,
		WifiService,
		ScannerService,
		BleService,
		WebIntent,
		WifiWizard2
	],
	exports: [LoaderComponent],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }


export function initialize_settings(http: HttpClient) {
	// console.log(" :: App_Initializer :: Initializing Settings");
	// return () => {
	// 	return http.get('https://api.github.com/users/sagar-ganatra')
	// 		.toPromise()
	// 		.then((resp) => {
	// 			console.log('Response 1 - ', resp);
	// 		});
	// };
}


