import { Component } from '@angular/core';
import { NavigationService, Routes } from '@app/services/navigation.service';

@Component({ templateUrl: 'user.component.html' })
export class UserComponent {

    constructor(
        private navService: NavigationService
    ) {

    }

    ngOnInit() {
        this.navService.navigateAsRoot(Routes.dashboard);
    }
}