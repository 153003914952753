import { Injectable } from '@angular/core';
import { LocationMessage } from 'src/shared/protos/generated/data_pb';

@Injectable({
  providedIn: 'root'
})
export class LocationDerivedModel extends LocationMessage{
 
  public station_count: number = 0;
  public asset_count: number = 0;

  public search_similarity: number = 0;

  constructor(message: LocationMessage) { 
    super();
    Object.assign(this, message);
  }

}

