import * as leaflet from 'leaflet';

interface LayerOptions {
    hidden: boolean;
    active: boolean;
}

export class FeatureGroup extends leaflet.FeatureGroup {
    name: string = 'New Layer';
    layerOptions: LayerOptions = {
        hidden: false,
        active: false,
    };

    constructor(
        test: string,
        layerGroup: leaflet.LayerGroup
    ) {
        super();
        this.name = test;
        layerGroup.getLayers().forEach(layer => {
            this.addLayer(layer);
        })
    }
}
