import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@services';
import { _http, _api } from '@app/_models/url';
/*
Most interceptors transform the outgoing request before passing it to the next interceptor in the chain, by calling next.handle(transformedReq). 
An interceptor may transform the response event stream as well, by applying additional RxJS operators on the stream returned by next.handle().

More rarely, an interceptor may handle the request entirely, and compose a new event stream instead of invoking next.handle(). 
This is an acceptable behavior, but keep in mind that further interceptors will be skipped entirely.

It is also rare but valid for an interceptor to return multiple responses on the event stream for a single request.
*/


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const user = this.authenticationService.userSubject.value;

        if (user == null) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',

                }
            });

            return next.handle(request)
        }else{
            request = request.clone({
                setHeaders: {

                    'Content-Type': 'application/json',
                    'Authorization': user.token,
                }
            });
            return next.handle(request);
        }
        
    }
}