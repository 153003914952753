import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BleArPageRoutingModule } from './ble-ar-routing.module';

import { BleArPage } from './ble-ar.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BleArPageRoutingModule
  ],
  declarations: [BleArPage]
})
export class BleArPageModule {}
