import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/services/api.service';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
})
export class RefreshComponent implements OnInit {
  public button_icon_name = 'refresh-outline'
  public retrieve_date: string = "...";

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.retrieveServerCounts();
  }

	refreshModel() {
    this.apiService.getFullModel()
    .then(()=>{

      var refresh_button = document.getElementById("refresh_button") as HTMLButtonElement;
      var refresh_text = document.getElementById("refresh_text") as HTMLSpanElement;

      this.button_icon_name = 'checkmark-outline'
      refresh_text.innerText = 'DATA UPDATED'
      refresh_button.disabled = true;

      setTimeout(() =>{
        this.button_icon_name = 'refresh-outline'
        refresh_text.innerText = 'UPDATE'
        refresh_button.disabled = false;
      }, 5000);

      this.retrieveServerCounts();
    });
	}

  retrieveServerCounts(){
    this.retrieve_date = new Date().toLocaleString();
  }

}
