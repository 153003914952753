
import { Device, INITIAL_DEVICE } from './device.model'

import { IDevice_State } from '../../shared/protobuf/protobuf/generated_code/ts/generic_settings/generic_settings';
import { IDataPayload } from '../../shared/protobuf/protobuf/generated_code/ts/pure_station/pure_station';
import { GroupType } from '../groups/groups.model';

export interface PureLocateDevice extends Device {

	// Currently used in only onboarding ... if interpreting protobufs from other places, this needs to be carefully re-examined
	proto_version?: number;

	// rethink this now .. probably handle as either an enum of last  type or something else
	fromServer?: boolean;
	fromBLE?: boolean;
	fromReport?: boolean;

	// from API/Fetching from store
	name?: string;
	macAddress?: string;
	uuid?: string;

	service?: string;

	last_seen?: Date
	station_rssi?: number;
	latitude?: number,
	longitude?: number,

	// location_id
	location?: string;
	// floor_id
	floor?: string;
	last_station_mac?: string;

	// BLE connection
	ble_connected?: boolean;
	ble_onboarded?: boolean;
	ble_macAddress?: string;
	
	// NOTE :: This is the CONNECTABLE uuid given by device.id
	// For android is macAddress (with colons, as per returned from BLE plugin)
	// for iOS is generated UUID
	// BUT always pass this to the BLE plugin to connect/send/receive from the BLE functions.
	ble_uuid?: string;
	ble_name?: string;
	ble_scannedAt?: Date;
	ble_rssi?: number;
	// Matches the Status designated in the protobuf
	ble_device_state_proto?: IDevice_State;
	// Decoded data from protobuf
	ble_telemetry_proto?: IDataPayload;

	// phone scan results



	// MQTT connection
	reported_receivedAt?: Date;
	reported_rssi?: number;

	// Really dont like this ... but ... it will do for the moment.
	reported_data?: any;

	settings?: any;

	// From the Server
	created_timestamp?: number,
	modified_timestamp?: number,

	// Used for alerts
	monitoring?: boolean;
	active_alert?: boolean;
}

// Initial condition with extension of 
export const INITIAL_PURE_LOCATE_DEVICE: Device = Object.assign({}, INITIAL_DEVICE, {
	ble_onboard: false,
	macAddress: "unknown",

});

export class DeviceFactory implements PureLocateDevice {

	type = GroupType.PureLocateDevice;
	id:string = '';
	macAddress:string = '';
	service = "BASE_STATION";
	proto_version = 0;
	ble_macAddress = '';

	public createBaseStationDevice(device: PureLocateDevice): PureLocateDevice {
		let ble_macAddress: string = this.toBleMacAddress(device.id);
		let wifiMac = this.toWifiMac(ble_macAddress);

		let update: PureLocateDevice = {
			type: GroupType.PureLocateDevice,
			id: wifiMac,
			macAddress: wifiMac,
			service: "BASE_STATION",
			proto_version: 0,
			ble_macAddress: ble_macAddress,
		};

		return update;
	}

	public createAssetDevice(id:string, name:string, rssi: number): PureLocateDevice {
		var update: PureLocateDevice = {
			type: GroupType.PureLocateDevice,
			id: this.toBleMacAddress(id),
			macAddress: this.toBleMacAddress(id),
			service: "TAG",
			fromBLE: true,
			ble_uuid: id,
			ble_scannedAt: new Date(),
			ble_name: name || "Unknown",
			name: name || 'Unknown - ' + this.toBleMacAddress(id),
			ble_rssi: rssi,
		};

		return update;
	}

	public setDeviceNameWithRssi(device: PureLocateDevice, scan: any) {
		device.ble_name = scan.name || "Unknown";
		device.ble_rssi = scan.rssi;
	}

	private toWifiMac(ble_macAddress: string): string {
		let lastByte = this.getLastByte(ble_macAddress);
		return ble_macAddress.substring(0, 10) + lastByte
	}

	private toBleMacAddress(id: string): string {
		return id.replace(/:/g, '').toLowerCase();
	}

	private getLastByte(address: string): string {
		let lastByte = (parseInt(address.slice(-2), 16) - 2).toString(16);

		if (lastByte.length == 1) {
			lastByte = "0" + lastByte;
		}

		return lastByte;
	}
}