import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DataService, FilterOptions, INTIAL_FILTEROPTIONS, Sort_State } from 'src/app/services/data.service';
import { StationDerivedModel } from 'src/app/services/models/station-derived-model.service';
import { NavigationService } from '@app/services/navigation.service';
import { GraphService } from '@app/services';

@Component({
	selector: 'station-paginated-table',
	templateUrl: './station-paginated-table.component.html',
	styleUrls: ['./station-paginated-table.component.scss'],
})
export class StationPaginatedTableComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@Input() enabledColumns = ['online', 'name', 'macAddress', 'connected_assets',  'last_updated', 'rssi'];
	@Input() displayedColumns = ['online', 'name', 'macAddress', 'connected_assets',  'last_updated', 'rssi'];
	@Input() isPaginated = true;

	public displayData: MatTableDataSource<StationDerivedModel> = new MatTableDataSource<StationDerivedModel>([]);

	@Input() pageSize = 25;
	public currentPage = 0;
	public length = 0;
	public options: FilterOptions = INTIAL_FILTEROPTIONS;

	public isLoadingResults = true;
	public isRateLimitReached = false;

	constructor(
		public navService: NavigationService,
		public dataService: DataService,
		public graphService: GraphService,
	) { }

	public ngOnInit() {
		this.setFilteredItems('');

		this.dataService.serverDataCounts.station_total_count.subscribe(value => {
			this.length = value;
			this.getArray();
		});


	}

	ionViewWillLeave() {
		this.pageSize = 10;
		this.currentPage = 0;
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.pageSize = e.pageSize;
		this.getArray();
	}

	private async getArray(): Promise<void> {
		this.isLoadingResults = true;

		return new Promise<void>((resolve) => {
			this.dataService.filterStations(this.options).then((response) => {
				if (response.length != 0) {
					this.isLoadingResults = false;
					this.length = response.length;
					response = response.slice(this.currentPage * this.pageSize, this.currentPage * this.pageSize + this.pageSize);
					this.displayData = new MatTableDataSource<StationDerivedModel>(response);
				}
				resolve();
			});
		});
	}

	getOnlineAssets(model: StationDerivedModel): number {
		let assetIds = model.getProtoChildrenList();
		let count = 0;

		assetIds.forEach(assetID => {
			let asset = this.graphService.getAsset(assetID)
			if (asset) {
				if (asset.isOnline) {
					count++;
				}
			}
		})

		return count;
	}

	setFilterState(state: number) {
		this.options.status = state;
		this.currentPage = 0;
		this.getArray();
	}

	setFilteredItems(searchTerm: string) {
		this.options.term = searchTerm;

		this.dataService.setStationSearchSimilarity(this.options.term);

		if (this.options.term.length == 0) this.dataService.sortStations(Sort_State.Alphabetical);
		else this.dataService.sortStations(Sort_State.Similarity);

		this.currentPage = 0;

		this.getArray();
	}
}
