import { Component, ElementRef, ViewChild } from '@angular/core';
import { MapBuilder } from '@app/_models/map/map-builder';
import { DrawService } from '@app/services/map/draw.service';
import { DataService } from '@app/services';
import { Platform, AlertController } from '@ionic/angular';
import { of } from 'rxjs';
import { ZoomLevel } from '@app/_models/map/map-constants';

@Component({
  selector: 'map-single-station',
  templateUrl: './map-single-station.component.html',
  styleUrls: ['../map.component.scss'],
})
export class MapSingleStationComponent extends MapBuilder {
  @ViewChild('map', { static: false }) mapContainer: ElementRef;

  constructor(
    public dataService: DataService,
    public drawService: DrawService,
    public platform: Platform,
    public alertController: AlertController,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.initializeMap(this.mapContainer.nativeElement);
  }

  public initializeMap(mapId: string) {
    this.generateMap(mapId);
    this.mapObs = of(this.map);

    this.map.on('zoomend', () => {
      this.draw();
    });

    if (this.initial_zoom) {
      this.setViewFromPoint(this.initial_zoom, ZoomLevel.stationsZoomLevel);
    }


    if (this.stationModel) this.setViewFromPoint(this.stationModel.getLatLong());

    this.filter.addAssetsDrpdwn(this.map);

    this.draw();
  }

  private draw() {
    this.clear();

    this.drawService.drawFloor(this.layerGroups, this.floorModel);
    let marker = this.drawService.drawStation(this.layerGroups, this.stationModel)
    this.drawService.drawStationAssets(this.layerGroups, this.stationModel, { status: this.filter.assetOptionState });

    this.map.on('resize', () => {
      marker.openPopup();
    });

  }

  private clear() {
    this.layerGroups.clearLayers();
  }

}
