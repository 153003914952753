import { AuthGuard,  } from '@helpers';
import { CordovaGuard } from '@app/_helpers/cordova.guard';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Role } from './_models/role';
import { GRPCResolverService } from './services/grpc-resolver.service';

const routes: Routes = [

	
	{
		path: '',
		loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
	},
	{
		path: 'user',
		// canActivate: [AuthGuard],
		// data: { roles: [Role.Admin] },
		resolve: { GRPCResolverService },
		loadChildren: './user/user-routing.module#UserRoutingModule'
	},
	{
		path: 'admin/auto-onboard',
		loadChildren: () => import('./admin/admin-super/auto-onboard/auto-onboard.module').then(m => m.AutoOnboardPageModule)
	},
	{
		path: 'admin',
		data: { roles: [Role.Admin] },
		canActivate: [AuthGuard],
		loadChildren: './admin/admin-routing.module#AdminRoutingModule'
	},
	// {
	//   path: 'admin',
	//   canActivate: [AuthGuard],
	//   data: { roles: [Role.Admin] },
	//   loadChildren: './admin/admin-routing.module#AdminRoutingModule'
	// },
	{ path: '**', redirectTo: '' },

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }