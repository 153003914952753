//TODO - set latlng with the users geoLocation
//const defaultLatLng: leaflet.LatLngExpression = [34.453701, -119.688631];
export const defaultLatLng: L.LatLngExpression = [39.8283, -98.5795];

export const enum StatusFilter {
	All = 1,
	None = 2,
	Online = 3,
	Offline = 4,
}

export const enum ZoomLevel {
    defaultZoom = 4,
    maxZoomedIn = 22,
    maxZoomedOut = 4,
    assetsZoomLevel = 22,
    stationsZoomLevel = 20,
    locationZoomLevel = 18,
}

export interface MarkerFilter {
    term?: string;
    status: StatusFilter;
  }