import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import {
   MatCardModule,
   MatButtonModule,
   MatToolbarModule,
   MatIconModule,
   MatBadgeModule,
   MatSidenavModule,
   MatListModule,
   MatGridListModule,
   MatGridList,
   MatFormFieldModule,
   MatInputModule,
   MatSelectModule,
   MatRadioModule,
   MatDatepickerModule,
   MatNativeDateModule,
   MatChipsModule,
   MatTooltipModule,
   MatTableModule,
   MatPaginatorModule,
   MatAutocompleteModule,
   MatBottomSheetModule,
   MatButtonToggleModule,
   MatCheckboxModule,
   MatStepperModule,
   MatDialogModule,
   MatDividerModule,
   MatExpansionModule,
   MatMenuModule,
   MatProgressBarModule,
   MatProgressSpinnerModule,
   MatRippleModule,
   MatSliderModule,
   MatSlideToggleModule,
   MatSnackBarModule,
   MatSortModule,
   MatTabsModule,
   MatTreeModule,
} from '@angular/material';

const modules = [
   MatCardModule,
   MatButtonModule,
   MatToolbarModule,
   MatIconModule,
   MatSidenavModule,
   MatBadgeModule,
   MatListModule,
   MatGridListModule,
   MatInputModule,
   MatFormFieldModule,
   MatSelectModule,
   MatRadioModule,
   MatDatepickerModule,
   MatChipsModule,
   MatTooltipModule,
   MatTableModule,
   MatPaginatorModule,
   MatAutocompleteModule,
   MatBottomSheetModule,
   MatButtonToggleModule,
   MatCheckboxModule,
   MatStepperModule,
   MatDialogModule,
   MatDividerModule,
   MatExpansionModule,
   MatMenuModule,
   MatProgressBarModule,
   MatProgressSpinnerModule,
   MatRippleModule,
   MatSliderModule,
   MatSlideToggleModule,
   MatSnackBarModule,
   MatSortModule,
   MatTabsModule,
   MatTreeModule, 
];

@NgModule({
   imports:[
      modules
   ],

   exports: [
      modules
   ],
   declarations:[

   ],
   schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AngularMaterialModule { }