import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { AssetDerivedModel } from 'src/app/services/models/asset-derived-model.service';
import { NavigationService } from '@app/services/navigation.service';
import { GraphService } from '@app/services';

@Component({
	selector: 'asset-table',
	templateUrl: './asset-table.component.html',
	styleUrls: ['./asset-table.component.scss'],
})
export class AssetTableComponent implements OnInit {
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@Input() assetIDs: string[] = [];

	public displayData: MatTableDataSource<AssetDerivedModel>;
	public displayedColumns = ['online', 'name', 'battery', 'location_name', 'floor_name', 'last_report', 'accuracy'];
	public dataSource: MatTableDataSource<string>;
	public isLoadingResults = true;
	public isRateLimitReached = false;

	constructor(
		public navService: NavigationService,
		public dataService: DataService,
		public graphService: GraphService
	) { }

	public ngOnInit() {
		this.dataService.serverDataCounts.asset_total_count.subscribe(value => {
			this.getArray();
		});

		this.getArray();
	}

	ionViewWillLeave() {
		this.assetIDs = [];
	}

	private getArray() {
		if (!this.assetIDs) return;

		this.isLoadingResults = true;

		let assets: AssetDerivedModel[] = []
		this.assetIDs.forEach(assetID=>{
			var assetDM:AssetDerivedModel = this.graphService.getAsset(assetID)
			if(assetDM.isOnline){
				assets.push(this.graphService.getAsset(assetID));
			}
		});
		assets = assets.sort((a,b)=> (new Date(a.getLastSeen()).getTime())- new Date(b.getLastSeen()).getTime() )
		this.isLoadingResults = false;
		this.displayData = new MatTableDataSource<AssetDerivedModel>(assets);
	}

}
