import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';

export enum Routes {
  login = '/login',

  dashboard = 'user/dashboard',

  locations = 'user/locations',
  stations = 'user/stations',
  assets = 'user/locate',

  locationDetails = 'user/locations-details',
  stationDetails = 'user/stations-details',
  assetDetails = 'user/locate-details',

  locationEdit = 'user/locations-edit',
  locationEditImage = 'user/locations-edit-image',
  locationsEditJson = 'user/locations-edit-geojson',

  floorsEditImage = 'user/floor-edit-image',
  floorEditJson = 'user/floor-edit-geojson',

  floorEdit = 'user/floor-edit',
  stationEdit = 'user/stations-edit',
  assetEdit = 'user/locate-edit',

  adminMenu = 'admin/menu',
  users = 'admin/admin-users',
  userEdit = 'admin/admin-edit-user',
  userCreate = 'admin/admin-user-create',

  bleSearchAsset = 'user/ble-search-asset',
  bleSearchStation = 'user/ble-search-station',

  bleTracking = 'user/ble-tracking',
  bleTrackDevice = 'user/ble-track-device',

  onboard = 'user/onboard',
  onboardStation = 'user/onboard/station-onboard',
  onboardAsset = 'user/onboard/asset-onboard',

  registerAsset = 'user/onboard/register-asset',
  registerStation = 'user/onboard/register-station',
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public routes = Routes;

  private _nav_param: any;

  get NavParam(){
    return this._nav_param;
  }

  constructor(
    private navCtrl: NavController,
  ) { }

  navigateAsRoot(route: Routes);
  navigateAsRoot(route: Routes, data: any);
  navigateAsRoot(route: Routes, data?: any) {
    this._nav_param = data;

    let navigationExtras: NavigationExtras = {
      state: {
        data: data,
      }
    };

    this.navCtrl.navigateRoot([route], navigationExtras).catch(error => {
      console.log(error);
    });
  }
  navString(url:string){
    this.navCtrl.navigateRoot(url).catch(error => {
      console.log(error);
    });
  }

}
