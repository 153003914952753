import * as L from "leaflet";
import { StatusFilter } from "./map-constants";

export class MapFilter {

    private container: HTMLElement;

    private _stationOptionState: StatusFilter = 1;
    get stationOptionState() {
        return this._stationOptionState;
    }
    set stationOptionState(state: StatusFilter) {
        this._stationOptionState = state;
    }

    private _assetOptionState: StatusFilter = 1;
    get assetOptionState() {
        return this._assetOptionState;
    }
    set assetOptionState(state: StatusFilter) {
        this._assetOptionState = state;
    }

    public addToMap(map: L.Map) {
        var stationOptions = L.Control.extend({
            options: {
                position: 'bottomleft'
            },
            onAdd: () => {
                this.container = L.DomUtil.create('div', 'position: relative;');
                return this.container;
            },
        });

        map.addControl(new stationOptions);
    }

    public addStationDrpdwn(map: L.Map) {
        var stationContainer = L.DomUtil.create('div', 'dropdown');
        var dropbtnContainer = L.DomUtil.create('button', 'dropbtn');
        dropbtnContainer.innerHTML = "Stations";

        var dropbtnContentContainer = L.DomUtil.create('div', 'dropdown-content');

        stationContainer.appendChild(dropbtnContainer);
        stationContainer.appendChild(dropbtnContentContainer);

        var option1Container = L.DomUtil.create('a');
        option1Container.innerHTML = "Show All Stations";
        option1Container.addEventListener('click', () => {
            this.stationOptionState = StatusFilter.All;
            map.fireEvent('zoomend');
        });
        var option2Container = L.DomUtil.create('a');
        option2Container.innerHTML = "Hide All Stations";
        option2Container.addEventListener('click', () => {
            this.stationOptionState = StatusFilter.None;
            map.fireEvent('zoomend');
        });
        var option3Container = L.DomUtil.create('a');
        option3Container.innerHTML = "Online Stations";
        option3Container.addEventListener('click', () => {
            this.stationOptionState = StatusFilter.Online;
            map.fireEvent('zoomend');
        });
        var option4Container = L.DomUtil.create('a');
        option4Container.innerHTML = "Offline Stations";
        option4Container.addEventListener('click', () => {
            this.stationOptionState = StatusFilter.Offline;
            map.fireEvent('zoomend');
        });

        dropbtnContentContainer.appendChild(option1Container);
        dropbtnContentContainer.appendChild(option2Container);
        dropbtnContentContainer.appendChild(option3Container);
        dropbtnContentContainer.appendChild(option4Container);

        this.container.appendChild(stationContainer);
    }

    public addAssetsDrpdwn(map: L.Map) {
        var assetContainer = L.DomUtil.create('div', 'dropdown');

        var dropbtnContainer = L.DomUtil.create('button', 'dropbtn');
        dropbtnContainer.innerHTML = "Assets";

        var dropbtnContentContainer = L.DomUtil.create('div', 'dropdown-content');

        assetContainer.appendChild(dropbtnContainer);
        assetContainer.appendChild(dropbtnContentContainer);

        var option1Container = L.DomUtil.create('a');
        option1Container.innerHTML = "Show All Assets";
        option1Container.addEventListener('click', () => {
            this.assetOptionState = (StatusFilter.All);
            map.fireEvent('zoomend');
        });
        var option2Container = L.DomUtil.create('a');
        option2Container.innerHTML = "Hide All Assets";
        option2Container.addEventListener('click', () => {
            this.assetOptionState = StatusFilter.None;
            map.fireEvent('zoomend');
        });
        var option3Container = L.DomUtil.create('a');
        option3Container.innerHTML = "Online Assets";
        option3Container.addEventListener('click', () => {
            this.assetOptionState = StatusFilter.Online;
            map.fireEvent('zoomend');
        });
        var option4Container = L.DomUtil.create('a');
        option4Container.innerHTML = "Offline Assets";
        option4Container.addEventListener('click', () => {
            this.assetOptionState = StatusFilter.Offline;
            map.fireEvent('zoomend');
        });

        dropbtnContentContainer.appendChild(option1Container);
        dropbtnContentContainer.appendChild(option2Container);
        dropbtnContentContainer.appendChild(option3Container);
        dropbtnContentContainer.appendChild(option4Container);

        this.container.appendChild(assetContainer);
    }

}