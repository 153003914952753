export function formatToDateTime(date_ms: number): string{
    let dateTime = 'Unknown'

    let date = new Date(date_ms);
    dateTime = date.getMonth().toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear().toString().padStart(2, '0') + ', ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0');

    return dateTime;
}

export function formatToTimeElapsed(date_ms: number): string {
    let dateTime = 'Unknown'

    let years = getYears(date_ms);
    let days = getDays(date_ms);
    let hours = getHours(date_ms);
    let minutes = getMinutes(date_ms);
    let seconds = getSeconds(date_ms);

    if(days > 356){
        if (years == 1) dateTime = (years) + " year ago";
        else dateTime = years + " years ago";
    }else if (days >= 1 && days < 356) {
        if (days == 1) dateTime = (days) + " day ago";
        else dateTime = days + " days ago";
    } else if (hours >= 1 && hours < 24) {
        if (hours == 1) dateTime = (hours) + " hour ago";
        else dateTime = hours + " hours ago";
    } else if (minutes >= 1 && minutes < 60) {
        if (minutes == 1) dateTime = minutes + " minute ago";
        else dateTime = minutes + " minutes ago";
    } else {
        if (seconds == 1) dateTime = seconds + " second ago";
        else dateTime = seconds + " seconds ago";
    }

    return dateTime;
}

export function formatToLastReport(date_ms: number){
    let dateTime = 'Unknown'

    let hours = getHours(date_ms);
    let minutes = getMinutes(date_ms);
    let seconds = getSeconds(date_ms);

    if (hours > 24) {
        let date = new Date(date_ms);
        dateTime = (date.getMonth()+1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear().toString().padStart(2, '0') + ', ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0');
    } else if (hours >= 1 && hours < 24) {
        if (hours == 1) dateTime = (hours) + " hour ago";
        else dateTime = hours + " hours ago";
    } else if (minutes >= 1 && minutes < 60) {
        if (minutes == 1) dateTime = minutes + " minute ago";
        else dateTime = minutes + " minutes ago";
    } else {
        if (seconds == 1) dateTime = seconds + " second ago";
        else dateTime = seconds + " seconds ago";
    }

    return dateTime;
}

export function getYears(date_ms: number) {
    var timeDiff = new Date().getTime() - date_ms;
    return Math.round(timeDiff / (1000 * 60 * 60 * 24 * 356));
}

export function getDays(date_ms: number) {
    var timeDiff = new Date().getTime() - date_ms;
    return Math.round(timeDiff / (1000 * 60 * 60 * 24));
}

export function getHours(date_ms: number) {
    var timeDiff = new Date().getTime() - date_ms;
    return Math.round(timeDiff / (1000 * 60 * 60));
}

export function getMinutes(date_ms: number) {
    var timeDiff = new Date().getTime() - date_ms;
    return Math.round(timeDiff / (1000 * 60));
}

export function getSeconds(date_ms: number) {
    var timeDiff = new Date().getTime() - date_ms;
    return Math.round(timeDiff / 1000);
}