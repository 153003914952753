import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MapBuilder } from '@app/_models/map/map-builder';
import { LocationDerivedModel, FloorDerivedModel, DataService, INTIAL_FILTEROPTIONS, FilterOptions, Sort_State, AssetDerivedModel, StationDerivedModel } from '@app/services';
import { BehaviorSubject, of } from 'rxjs';
import { DrawService } from '@app/services/map/draw.service';
import { Platform, AlertController } from '@ionic/angular';
import { RowDefinitions, Locations, GraphService } from '@app/services/graph.service';
import { ZoomLevel } from '@app/_models/map/map-constants';

@Component({
  selector: 'map-all-locations',
  templateUrl: './map-all-locations.component.html',
  styleUrls: ['../map.component.scss'],
})
export class MapAllLocationsComponent extends MapBuilder implements OnInit {
  @ViewChild('map', { static: false }) mapContainer: ElementRef;

  public zoomLevel: number = 0

  public _user_selected_floor: BehaviorSubject<FloorDerivedModel> = new BehaviorSubject<FloorDerivedModel>(undefined);
  public _active_Location: LocationDerivedModel = undefined;
  public _active_floor: FloorDerivedModel = undefined;
  get active_floor() {
    return this._active_floor;
  }

  public options: FilterOptions = INTIAL_FILTEROPTIONS;
  public set search_term(term: string) {
    this.options.term = term;

    this.dataService.setAssetSearchSimilarity(term);
    this.dataService.setStationSearchSimilarity(term);

    this.dataService.sortAssets(Sort_State.Similarity);
    this.dataService.sortStations(Sort_State.Similarity);

    let device: AssetDerivedModel | StationDerivedModel = undefined;
    let assets: AssetDerivedModel[] = [];
    let stations: StationDerivedModel[] = [];
    this.dataService.filterAssets(this.options)
      .then((res) => { assets = res })
      .then(() => this.dataService.filterStations(this.options))
      .then((res) => stations = res)
      .then(() => {
        if (assets.length != 0 && stations.length != 0) {
          device = assets[0]
          if (device.search_similarity <= stations[0].search_similarity) device = stations[0]
        } else if (assets.length != 0) device = assets[0]
        else if (stations.length != 0) device = stations[0]

        if (device) {
          this.clear();
          this.setViewFromPoint(device.getLatLong(), ZoomLevel.assetsZoomLevel);
          this.setLocation();

          if(device instanceof AssetDerivedModel) this.drawService.drawAsset(this.layerGroups, device);
          if(device instanceof StationDerivedModel) this.drawService.drawStation(this.layerGroups, device);

          this.drawService.drawFloor(this.layerGroups, this._active_floor);
        }
      })
  }

  constructor(
    public dataService: DataService,
    public drawService: DrawService,
    public platform: Platform,
    public alertController: AlertController,
    private graph: GraphService
  ) {
    super();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.initializeMap(this.mapContainer.nativeElement);

    setTimeout(() => {
      this.centerMap(this.graph.getRow(RowDefinitions.LOCATIONS) as Locations);
    }, 1000);
  }

  ngViewDidEnter() {

  }

  public setView(latLng, zoomLevel) {
    this.map.setView(latLng, zoomLevel)
  }

  private initializeMap(mapId: string) {
    this.generateMap(mapId);
    this.mapObs = of(this.map);

    if (this.initial_zoom) {
      this.setViewFromPoint(this.initial_zoom, ZoomLevel.stationsZoomLevel);
    }

    this._user_selected_floor.subscribe(() => {
      this._active_floor = this._user_selected_floor.value;
      console.log(this._active_floor);
      this.draw();
    });

    this.map.on('zoomend', () => {
      this.draw();
    });

    this.dataService.serverDataCounts.location_count.subscribe(() => {
      this.clear();
      this.draw();
    });

    this.filter.addStationDrpdwn(this.map);
    this.filter.addAssetsDrpdwn(this.map);

    this.draw();
  }

  private draw() {
    this.clear();

    this.zoomLevel = this.map.getZoom();
    if (this.map.getZoom() > ZoomLevel.locationZoomLevel) { //zoomed in
      this.setLocation();

      this.drawService.drawFloor(this.layerGroups, this._active_floor);
      this.drawService.drawFloorAssets(this.layerGroups, this._active_floor, { term: this.options.term, status: this.filter.assetOptionState });
      this.drawService.drawFloorStations(this.layerGroups, this._active_floor, { term: this.options.term, status: this.filter.stationOptionState });

    } else { //zoomed out
      if (this._user_selected_floor.value) {
        this._user_selected_floor.next(undefined);
        return;
      }

      let locations = this.graph.getRow(RowDefinitions.LOCATIONS) as Locations;
      this.menu.setLocationsMenu(locations);
      this.drawService.drawLocationMarkers(this.layerGroups, this.map);
    }
  }

  private setLocation() {
    this._active_Location = this.findActiveLocation(this.graph.getRow(RowDefinitions.LOCATIONS) as Locations);
    this._active_floor = this.graph.getFloor(this.findActiveFloorId(this._active_Location, this._user_selected_floor.value));

    let floors = this.dataService.getAllLocationFloors(this._active_Location);
    this.menu.setFloorsMenu(floors);
  }

  private clear() {
    this._active_floor = undefined;
    this._active_Location = undefined;
    this.layerGroups.clearLayers();
    this.menu.clearMenu();
  }

}
