/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.data.proto.Address', null, global);
goog.exportSymbol('proto.data.proto.AssetMessage', null, global);
goog.exportSymbol('proto.data.proto.Asset_Page', null, global);
goog.exportSymbol('proto.data.proto.Asset_Station', null, global);
goog.exportSymbol('proto.data.proto.Asset_data', null, global);
goog.exportSymbol('proto.data.proto.AssetsListMessage', null, global);
goog.exportSymbol('proto.data.proto.Empty', null, global);
goog.exportSymbol('proto.data.proto.EntityListsMessage', null, global);
goog.exportSymbol('proto.data.proto.Error', null, global);
goog.exportSymbol('proto.data.proto.FloorListMessage', null, global);
goog.exportSymbol('proto.data.proto.FloorMessage', null, global);
goog.exportSymbol('proto.data.proto.FloorplanMessage', null, global);
goog.exportSymbol('proto.data.proto.GeoJsonMessage', null, global);
goog.exportSymbol('proto.data.proto.GraphMessage', null, global);
goog.exportSymbol('proto.data.proto.LocationMessage', null, global);
goog.exportSymbol('proto.data.proto.LocationRequest', null, global);
goog.exportSymbol('proto.data.proto.Location_Page', null, global);
goog.exportSymbol('proto.data.proto.LocationsListMessage', null, global);
goog.exportSymbol('proto.data.proto.OrganizationMessage', null, global);
goog.exportSymbol('proto.data.proto.Point', null, global);
goog.exportSymbol('proto.data.proto.Response', null, global);
goog.exportSymbol('proto.data.proto.StationMessage', null, global);
goog.exportSymbol('proto.data.proto.StationMessage_Page', null, global);
goog.exportSymbol('proto.data.proto.Station_Settings', null, global);
goog.exportSymbol('proto.data.proto.Station_System_Record', null, global);
goog.exportSymbol('proto.data.proto.Station_data', null, global);
goog.exportSymbol('proto.data.proto.StationsListMessage', null, global);
goog.exportSymbol('proto.data.proto.UserInfo', null, global);
goog.exportSymbol('proto.data.proto.UserMessage', null, global);
goog.exportSymbol('proto.data.proto.UsersMessage', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.EntityListsMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.EntityListsMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.EntityListsMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.EntityListsMessage.displayName = 'proto.data.proto.EntityListsMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.EntityListsMessage.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.EntityListsMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.EntityListsMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.EntityListsMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.EntityListsMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.data.proto.LocationMessage.toObject, includeInstance),
    floorsList: jspb.Message.toObjectList(msg.getFloorsList(),
    proto.data.proto.FloorMessage.toObject, includeInstance),
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    proto.data.proto.StationMessage.toObject, includeInstance),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.data.proto.AssetMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.EntityListsMessage}
 */
proto.data.proto.EntityListsMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.EntityListsMessage;
  return proto.data.proto.EntityListsMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.EntityListsMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.EntityListsMessage}
 */
proto.data.proto.EntityListsMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.LocationMessage;
      reader.readMessage(value,proto.data.proto.LocationMessage.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 2:
      var value = new proto.data.proto.FloorMessage;
      reader.readMessage(value,proto.data.proto.FloorMessage.deserializeBinaryFromReader);
      msg.addFloors(value);
      break;
    case 3:
      var value = new proto.data.proto.StationMessage;
      reader.readMessage(value,proto.data.proto.StationMessage.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    case 4:
      var value = new proto.data.proto.AssetMessage;
      reader.readMessage(value,proto.data.proto.AssetMessage.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.EntityListsMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.EntityListsMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.EntityListsMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.EntityListsMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.data.proto.LocationMessage.serializeBinaryToWriter
    );
  }
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.data.proto.FloorMessage.serializeBinaryToWriter
    );
  }
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.data.proto.StationMessage.serializeBinaryToWriter
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.data.proto.AssetMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LocationMessage locations = 1;
 * @return {!Array.<!proto.data.proto.LocationMessage>}
 */
proto.data.proto.EntityListsMessage.prototype.getLocationsList = function() {
  return /** @type{!Array.<!proto.data.proto.LocationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.LocationMessage, 1));
};


/** @param {!Array.<!proto.data.proto.LocationMessage>} value */
proto.data.proto.EntityListsMessage.prototype.setLocationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.data.proto.LocationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.LocationMessage}
 */
proto.data.proto.EntityListsMessage.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.data.proto.LocationMessage, opt_index);
};


proto.data.proto.EntityListsMessage.prototype.clearLocationsList = function() {
  this.setLocationsList([]);
};


/**
 * repeated FloorMessage floors = 2;
 * @return {!Array.<!proto.data.proto.FloorMessage>}
 */
proto.data.proto.EntityListsMessage.prototype.getFloorsList = function() {
  return /** @type{!Array.<!proto.data.proto.FloorMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.FloorMessage, 2));
};


/** @param {!Array.<!proto.data.proto.FloorMessage>} value */
proto.data.proto.EntityListsMessage.prototype.setFloorsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.data.proto.FloorMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.FloorMessage}
 */
proto.data.proto.EntityListsMessage.prototype.addFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.data.proto.FloorMessage, opt_index);
};


proto.data.proto.EntityListsMessage.prototype.clearFloorsList = function() {
  this.setFloorsList([]);
};


/**
 * repeated StationMessage stations = 3;
 * @return {!Array.<!proto.data.proto.StationMessage>}
 */
proto.data.proto.EntityListsMessage.prototype.getStationsList = function() {
  return /** @type{!Array.<!proto.data.proto.StationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.StationMessage, 3));
};


/** @param {!Array.<!proto.data.proto.StationMessage>} value */
proto.data.proto.EntityListsMessage.prototype.setStationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.data.proto.StationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.StationMessage}
 */
proto.data.proto.EntityListsMessage.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.data.proto.StationMessage, opt_index);
};


proto.data.proto.EntityListsMessage.prototype.clearStationsList = function() {
  this.setStationsList([]);
};


/**
 * repeated AssetMessage assets = 4;
 * @return {!Array.<!proto.data.proto.AssetMessage>}
 */
proto.data.proto.EntityListsMessage.prototype.getAssetsList = function() {
  return /** @type{!Array.<!proto.data.proto.AssetMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.AssetMessage, 4));
};


/** @param {!Array.<!proto.data.proto.AssetMessage>} value */
proto.data.proto.EntityListsMessage.prototype.setAssetsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.data.proto.AssetMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.AssetMessage}
 */
proto.data.proto.EntityListsMessage.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.data.proto.AssetMessage, opt_index);
};


proto.data.proto.EntityListsMessage.prototype.clearAssetsList = function() {
  this.setAssetsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.LocationsListMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.LocationsListMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.LocationsListMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.LocationsListMessage.displayName = 'proto.data.proto.LocationsListMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.LocationsListMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.LocationsListMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.LocationsListMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.LocationsListMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.LocationsListMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.data.proto.LocationMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.LocationsListMessage}
 */
proto.data.proto.LocationsListMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.LocationsListMessage;
  return proto.data.proto.LocationsListMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.LocationsListMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.LocationsListMessage}
 */
proto.data.proto.LocationsListMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.LocationMessage;
      reader.readMessage(value,proto.data.proto.LocationMessage.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.LocationsListMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.LocationsListMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.LocationsListMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.LocationsListMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.data.proto.LocationMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LocationMessage locations = 1;
 * @return {!Array.<!proto.data.proto.LocationMessage>}
 */
proto.data.proto.LocationsListMessage.prototype.getLocationsList = function() {
  return /** @type{!Array.<!proto.data.proto.LocationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.LocationMessage, 1));
};


/** @param {!Array.<!proto.data.proto.LocationMessage>} value */
proto.data.proto.LocationsListMessage.prototype.setLocationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.data.proto.LocationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.LocationMessage}
 */
proto.data.proto.LocationsListMessage.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.data.proto.LocationMessage, opt_index);
};


proto.data.proto.LocationsListMessage.prototype.clearLocationsList = function() {
  this.setLocationsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.FloorListMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.FloorListMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.FloorListMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.FloorListMessage.displayName = 'proto.data.proto.FloorListMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.FloorListMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.FloorListMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.FloorListMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.FloorListMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.FloorListMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    floorsList: jspb.Message.toObjectList(msg.getFloorsList(),
    proto.data.proto.FloorMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.FloorListMessage}
 */
proto.data.proto.FloorListMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.FloorListMessage;
  return proto.data.proto.FloorListMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.FloorListMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.FloorListMessage}
 */
proto.data.proto.FloorListMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.FloorMessage;
      reader.readMessage(value,proto.data.proto.FloorMessage.deserializeBinaryFromReader);
      msg.addFloors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.FloorListMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.FloorListMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.FloorListMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.FloorListMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.data.proto.FloorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FloorMessage floors = 1;
 * @return {!Array.<!proto.data.proto.FloorMessage>}
 */
proto.data.proto.FloorListMessage.prototype.getFloorsList = function() {
  return /** @type{!Array.<!proto.data.proto.FloorMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.FloorMessage, 1));
};


/** @param {!Array.<!proto.data.proto.FloorMessage>} value */
proto.data.proto.FloorListMessage.prototype.setFloorsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.data.proto.FloorMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.FloorMessage}
 */
proto.data.proto.FloorListMessage.prototype.addFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.data.proto.FloorMessage, opt_index);
};


proto.data.proto.FloorListMessage.prototype.clearFloorsList = function() {
  this.setFloorsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.StationsListMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.StationsListMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.StationsListMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.StationsListMessage.displayName = 'proto.data.proto.StationsListMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.StationsListMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.StationsListMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.StationsListMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.StationsListMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.StationsListMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    proto.data.proto.StationMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.StationsListMessage}
 */
proto.data.proto.StationsListMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.StationsListMessage;
  return proto.data.proto.StationsListMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.StationsListMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.StationsListMessage}
 */
proto.data.proto.StationsListMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.StationMessage;
      reader.readMessage(value,proto.data.proto.StationMessage.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.StationsListMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.StationsListMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.StationsListMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.StationsListMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.data.proto.StationMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StationMessage stations = 1;
 * @return {!Array.<!proto.data.proto.StationMessage>}
 */
proto.data.proto.StationsListMessage.prototype.getStationsList = function() {
  return /** @type{!Array.<!proto.data.proto.StationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.StationMessage, 1));
};


/** @param {!Array.<!proto.data.proto.StationMessage>} value */
proto.data.proto.StationsListMessage.prototype.setStationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.data.proto.StationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.StationMessage}
 */
proto.data.proto.StationsListMessage.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.data.proto.StationMessage, opt_index);
};


proto.data.proto.StationsListMessage.prototype.clearStationsList = function() {
  this.setStationsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.AssetsListMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.AssetsListMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.AssetsListMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.AssetsListMessage.displayName = 'proto.data.proto.AssetsListMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.AssetsListMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.AssetsListMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.AssetsListMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.AssetsListMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.AssetsListMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.data.proto.AssetMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.AssetsListMessage}
 */
proto.data.proto.AssetsListMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.AssetsListMessage;
  return proto.data.proto.AssetsListMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.AssetsListMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.AssetsListMessage}
 */
proto.data.proto.AssetsListMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.AssetMessage;
      reader.readMessage(value,proto.data.proto.AssetMessage.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.AssetsListMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.AssetsListMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.AssetsListMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.AssetsListMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.data.proto.AssetMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssetMessage assets = 1;
 * @return {!Array.<!proto.data.proto.AssetMessage>}
 */
proto.data.proto.AssetsListMessage.prototype.getAssetsList = function() {
  return /** @type{!Array.<!proto.data.proto.AssetMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.AssetMessage, 1));
};


/** @param {!Array.<!proto.data.proto.AssetMessage>} value */
proto.data.proto.AssetsListMessage.prototype.setAssetsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.data.proto.AssetMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.AssetMessage}
 */
proto.data.proto.AssetsListMessage.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.data.proto.AssetMessage, opt_index);
};


proto.data.proto.AssetsListMessage.prototype.clearAssetsList = function() {
  this.setAssetsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.GraphMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.GraphMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.GraphMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.GraphMessage.displayName = 'proto.data.proto.GraphMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.GraphMessage.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.GraphMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.GraphMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.GraphMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.GraphMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    protoChildrenList: jspb.Message.getRepeatedField(msg, 1),
    protoParentsList: jspb.Message.toObjectList(msg.getProtoParentsList(),
    proto.data.proto.Empty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.GraphMessage}
 */
proto.data.proto.GraphMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.GraphMessage;
  return proto.data.proto.GraphMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.GraphMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.GraphMessage}
 */
proto.data.proto.GraphMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoChildren(value);
      break;
    case 2:
      var value = new proto.data.proto.Empty;
      reader.readMessage(value,proto.data.proto.Empty.deserializeBinaryFromReader);
      msg.addProtoParents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.GraphMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.GraphMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.GraphMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.GraphMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtoChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getProtoParentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.data.proto.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string proto_children = 1;
 * @return {!Array.<string>}
 */
proto.data.proto.GraphMessage.prototype.getProtoChildrenList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array.<string>} value */
proto.data.proto.GraphMessage.prototype.setProtoChildrenList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.data.proto.GraphMessage.prototype.addProtoChildren = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.data.proto.GraphMessage.prototype.clearProtoChildrenList = function() {
  this.setProtoChildrenList([]);
};


/**
 * repeated Empty proto_parents = 2;
 * @return {!Array.<!proto.data.proto.Empty>}
 */
proto.data.proto.GraphMessage.prototype.getProtoParentsList = function() {
  return /** @type{!Array.<!proto.data.proto.Empty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.Empty, 2));
};


/** @param {!Array.<!proto.data.proto.Empty>} value */
proto.data.proto.GraphMessage.prototype.setProtoParentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.data.proto.Empty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.Empty}
 */
proto.data.proto.GraphMessage.prototype.addProtoParents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.data.proto.Empty, opt_index);
};


proto.data.proto.GraphMessage.prototype.clearProtoParentsList = function() {
  this.setProtoParentsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.UserInfo.displayName = 'proto.data.proto.UserInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modified: jspb.Message.getFieldWithDefault(msg, 6, ""),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.UserInfo}
 */
proto.data.proto.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.UserInfo;
  return proto.data.proto.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.UserInfo}
 */
proto.data.proto.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModified(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModified();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional string organization = 1;
 * @return {string}
 */
proto.data.proto.UserInfo.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.data.proto.UserInfo.prototype.setOrganization = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.data.proto.UserInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.UserInfo.prototype.setUsername = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.data.proto.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.UserInfo.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.data.proto.UserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.data.proto.UserInfo.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created = 5;
 * @return {string}
 */
proto.data.proto.UserInfo.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.data.proto.UserInfo.prototype.setCreated = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string modified = 6;
 * @return {string}
 */
proto.data.proto.UserInfo.prototype.getModified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.data.proto.UserInfo.prototype.setModified = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 role = 7;
 * @return {number}
 */
proto.data.proto.UserInfo.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.data.proto.UserInfo.prototype.setRole = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.OrganizationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.OrganizationMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.OrganizationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.OrganizationMessage.displayName = 'proto.data.proto.OrganizationMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.OrganizationMessage.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.OrganizationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.OrganizationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.OrganizationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.OrganizationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.data.proto.Address.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modified: jspb.Message.getFieldWithDefault(msg, 6, ""),
    apiUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mqttPassword: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mqttPort: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mqttUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mqttUsername: jspb.Message.getFieldWithDefault(msg, 11, ""),
    otaUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    otaPort: jspb.Message.getFieldWithDefault(msg, 13, 0),
    protoChildrenList: jspb.Message.getRepeatedField(msg, 14),
    protoParentsList: jspb.Message.getRepeatedField(msg, 15)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.OrganizationMessage}
 */
proto.data.proto.OrganizationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.OrganizationMessage;
  return proto.data.proto.OrganizationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.OrganizationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.OrganizationMessage}
 */
proto.data.proto.OrganizationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.Address;
      reader.readMessage(value,proto.data.proto.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModified(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMqttPassword(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMqttPort(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMqttUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMqttUsername(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtaUrl(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOtaPort(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoChildren(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoParents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.OrganizationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.OrganizationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.OrganizationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.OrganizationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.data.proto.Address.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModified();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getApiUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMqttPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMqttPort();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getMqttUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMqttUsername();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOtaUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOtaPort();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getProtoChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getProtoParentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.data.proto.Address}
 */
proto.data.proto.OrganizationMessage.prototype.getAddress = function() {
  return /** @type{?proto.data.proto.Address} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Address, 1));
};


/** @param {?proto.data.proto.Address|undefined} value */
proto.data.proto.OrganizationMessage.prototype.setAddress = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.data.proto.OrganizationMessage.prototype.clearAddress = function() {
  this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.OrganizationMessage.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created = 4;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setCreated = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string modified = 6;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getModified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setModified = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string api_url = 7;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getApiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setApiUrl = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mqtt_password = 8;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getMqttPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setMqttPassword = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 mqtt_port = 9;
 * @return {number}
 */
proto.data.proto.OrganizationMessage.prototype.getMqttPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.data.proto.OrganizationMessage.prototype.setMqttPort = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string mqtt_url = 10;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getMqttUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setMqttUrl = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mqtt_username = 11;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getMqttUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setMqttUsername = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ota_url = 12;
 * @return {string}
 */
proto.data.proto.OrganizationMessage.prototype.getOtaUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.data.proto.OrganizationMessage.prototype.setOtaUrl = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint32 ota_port = 13;
 * @return {number}
 */
proto.data.proto.OrganizationMessage.prototype.getOtaPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.data.proto.OrganizationMessage.prototype.setOtaPort = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string proto_children = 14;
 * @return {!Array.<string>}
 */
proto.data.proto.OrganizationMessage.prototype.getProtoChildrenList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/** @param {!Array.<string>} value */
proto.data.proto.OrganizationMessage.prototype.setProtoChildrenList = function(value) {
  jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.data.proto.OrganizationMessage.prototype.addProtoChildren = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


proto.data.proto.OrganizationMessage.prototype.clearProtoChildrenList = function() {
  this.setProtoChildrenList([]);
};


/**
 * repeated string proto_parents = 15;
 * @return {!Array.<string>}
 */
proto.data.proto.OrganizationMessage.prototype.getProtoParentsList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/** @param {!Array.<string>} value */
proto.data.proto.OrganizationMessage.prototype.setProtoParentsList = function(value) {
  jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.data.proto.OrganizationMessage.prototype.addProtoParents = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


proto.data.proto.OrganizationMessage.prototype.clearProtoParentsList = function() {
  this.setProtoParentsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.UsersMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.UsersMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.UsersMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.UsersMessage.displayName = 'proto.data.proto.UsersMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.UsersMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.UsersMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.UsersMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.UsersMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.UsersMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.data.proto.UserMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.UsersMessage}
 */
proto.data.proto.UsersMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.UsersMessage;
  return proto.data.proto.UsersMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.UsersMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.UsersMessage}
 */
proto.data.proto.UsersMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.UserMessage;
      reader.readMessage(value,proto.data.proto.UserMessage.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.UsersMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.UsersMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.UsersMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.UsersMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.data.proto.UserMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserMessage users = 1;
 * @return {!Array.<!proto.data.proto.UserMessage>}
 */
proto.data.proto.UsersMessage.prototype.getUsersList = function() {
  return /** @type{!Array.<!proto.data.proto.UserMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.UserMessage, 1));
};


/** @param {!Array.<!proto.data.proto.UserMessage>} value */
proto.data.proto.UsersMessage.prototype.setUsersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.data.proto.UserMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.UserMessage}
 */
proto.data.proto.UsersMessage.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.data.proto.UserMessage, opt_index);
};


proto.data.proto.UsersMessage.prototype.clearUsersList = function() {
  this.setUsersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.UserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.UserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.UserMessage.displayName = 'proto.data.proto.UserMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.UserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.UserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.UserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.UserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    organization: jspb.Message.getFieldWithDefault(msg, 5, ""),
    created: jspb.Message.getFieldWithDefault(msg, 6, ""),
    modified: jspb.Message.getFieldWithDefault(msg, 7, ""),
    version: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.UserMessage}
 */
proto.data.proto.UserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.UserMessage;
  return proto.data.proto.UserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.UserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.UserMessage}
 */
proto.data.proto.UserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModified(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.UserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.UserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.UserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.UserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModified();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 role = 1;
 * @return {number}
 */
proto.data.proto.UserMessage.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.data.proto.UserMessage.prototype.setRole = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.data.proto.UserMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.UserMessage.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.data.proto.UserMessage.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.UserMessage.prototype.setUsername = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.data.proto.UserMessage.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.data.proto.UserMessage.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string organization = 5;
 * @return {string}
 */
proto.data.proto.UserMessage.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.data.proto.UserMessage.prototype.setOrganization = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created = 6;
 * @return {string}
 */
proto.data.proto.UserMessage.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.data.proto.UserMessage.prototype.setCreated = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string modified = 7;
 * @return {string}
 */
proto.data.proto.UserMessage.prototype.getModified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.data.proto.UserMessage.prototype.setModified = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 version = 8;
 * @return {number}
 */
proto.data.proto.UserMessage.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.data.proto.UserMessage.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.LocationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.LocationMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.LocationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.LocationMessage.displayName = 'proto.data.proto.LocationMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.LocationMessage.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.LocationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.LocationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.LocationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.LocationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    latLong: (f = msg.getLatLong()) && proto.data.proto.Point.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    created: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modified: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imageId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    imageScale: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numberOfFloors: jspb.Message.getFieldWithDefault(msg, 9, 0),
    numberOfStations: jspb.Message.getFieldWithDefault(msg, 10, 0),
    numberOfAssets: jspb.Message.getFieldWithDefault(msg, 11, 0),
    protoChildrenList: jspb.Message.getRepeatedField(msg, 12),
    protoParent: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.LocationMessage}
 */
proto.data.proto.LocationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.LocationMessage;
  return proto.data.proto.LocationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.LocationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.LocationMessage}
 */
proto.data.proto.LocationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.Point;
      reader.readMessage(value,proto.data.proto.Point.deserializeBinaryFromReader);
      msg.setLatLong(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModified(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageScale(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfFloors(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfStations(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfAssets(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoChildren(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtoParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.LocationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.LocationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.LocationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.LocationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatLong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.data.proto.Point.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModified();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImageId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getImageScale();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumberOfFloors();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getNumberOfStations();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getNumberOfAssets();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getProtoChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getProtoParent();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional Point lat_long = 1;
 * @return {?proto.data.proto.Point}
 */
proto.data.proto.LocationMessage.prototype.getLatLong = function() {
  return /** @type{?proto.data.proto.Point} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Point, 1));
};


/** @param {?proto.data.proto.Point|undefined} value */
proto.data.proto.LocationMessage.prototype.setLatLong = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.data.proto.LocationMessage.prototype.clearLatLong = function() {
  this.setLatLong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.LocationMessage.prototype.hasLatLong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.data.proto.LocationMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.LocationMessage.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.data.proto.LocationMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.LocationMessage.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 version = 4;
 * @return {number}
 */
proto.data.proto.LocationMessage.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.data.proto.LocationMessage.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string created = 5;
 * @return {string}
 */
proto.data.proto.LocationMessage.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.data.proto.LocationMessage.prototype.setCreated = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string modified = 6;
 * @return {string}
 */
proto.data.proto.LocationMessage.prototype.getModified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.data.proto.LocationMessage.prototype.setModified = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string image_id = 7;
 * @return {string}
 */
proto.data.proto.LocationMessage.prototype.getImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.data.proto.LocationMessage.prototype.setImageId = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string image_scale = 8;
 * @return {string}
 */
proto.data.proto.LocationMessage.prototype.getImageScale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.data.proto.LocationMessage.prototype.setImageScale = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 number_of_floors = 9;
 * @return {number}
 */
proto.data.proto.LocationMessage.prototype.getNumberOfFloors = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.data.proto.LocationMessage.prototype.setNumberOfFloors = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 number_of_stations = 10;
 * @return {number}
 */
proto.data.proto.LocationMessage.prototype.getNumberOfStations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.data.proto.LocationMessage.prototype.setNumberOfStations = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 number_of_assets = 11;
 * @return {number}
 */
proto.data.proto.LocationMessage.prototype.getNumberOfAssets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.data.proto.LocationMessage.prototype.setNumberOfAssets = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated string proto_children = 12;
 * @return {!Array.<string>}
 */
proto.data.proto.LocationMessage.prototype.getProtoChildrenList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array.<string>} value */
proto.data.proto.LocationMessage.prototype.setProtoChildrenList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.data.proto.LocationMessage.prototype.addProtoChildren = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.data.proto.LocationMessage.prototype.clearProtoChildrenList = function() {
  this.setProtoChildrenList([]);
};


/**
 * optional string proto_parent = 13;
 * @return {string}
 */
proto.data.proto.LocationMessage.prototype.getProtoParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.data.proto.LocationMessage.prototype.setProtoParent = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.FloorMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.FloorMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.FloorMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.FloorMessage.displayName = 'proto.data.proto.FloorMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.FloorMessage.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.FloorMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.FloorMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.FloorMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.FloorMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    created: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modified: jspb.Message.getFieldWithDefault(msg, 6, ""),
    floorplan: (f = msg.getFloorplan()) && proto.data.proto.FloorplanMessage.toObject(includeInstance, f),
    geojson: (f = msg.getGeojson()) && proto.data.proto.GeoJsonMessage.toObject(includeInstance, f),
    assetCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    protoChildrenList: jspb.Message.getRepeatedField(msg, 10),
    protoParent: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.FloorMessage}
 */
proto.data.proto.FloorMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.FloorMessage;
  return proto.data.proto.FloorMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.FloorMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.FloorMessage}
 */
proto.data.proto.FloorMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModified(value);
      break;
    case 7:
      var value = new proto.data.proto.FloorplanMessage;
      reader.readMessage(value,proto.data.proto.FloorplanMessage.deserializeBinaryFromReader);
      msg.setFloorplan(value);
      break;
    case 8:
      var value = new proto.data.proto.GeoJsonMessage;
      reader.readMessage(value,proto.data.proto.GeoJsonMessage.deserializeBinaryFromReader);
      msg.setGeojson(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetCount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoChildren(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtoParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.FloorMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.FloorMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.FloorMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.FloorMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModified();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFloorplan();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.data.proto.FloorplanMessage.serializeBinaryToWriter
    );
  }
  f = message.getGeojson();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.data.proto.GeoJsonMessage.serializeBinaryToWriter
    );
  }
  f = message.getAssetCount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getProtoChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getProtoParent();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional uint32 level = 1;
 * @return {number}
 */
proto.data.proto.FloorMessage.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.data.proto.FloorMessage.prototype.setLevel = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.data.proto.FloorMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.FloorMessage.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.data.proto.FloorMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.FloorMessage.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 version = 4;
 * @return {number}
 */
proto.data.proto.FloorMessage.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.data.proto.FloorMessage.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string created = 5;
 * @return {string}
 */
proto.data.proto.FloorMessage.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.data.proto.FloorMessage.prototype.setCreated = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string modified = 6;
 * @return {string}
 */
proto.data.proto.FloorMessage.prototype.getModified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.data.proto.FloorMessage.prototype.setModified = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional FloorplanMessage floorplan = 7;
 * @return {?proto.data.proto.FloorplanMessage}
 */
proto.data.proto.FloorMessage.prototype.getFloorplan = function() {
  return /** @type{?proto.data.proto.FloorplanMessage} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.FloorplanMessage, 7));
};


/** @param {?proto.data.proto.FloorplanMessage|undefined} value */
proto.data.proto.FloorMessage.prototype.setFloorplan = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.data.proto.FloorMessage.prototype.clearFloorplan = function() {
  this.setFloorplan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.FloorMessage.prototype.hasFloorplan = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional GeoJsonMessage geoJson = 8;
 * @return {?proto.data.proto.GeoJsonMessage}
 */
proto.data.proto.FloorMessage.prototype.getGeojson = function() {
  return /** @type{?proto.data.proto.GeoJsonMessage} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.GeoJsonMessage, 8));
};


/** @param {?proto.data.proto.GeoJsonMessage|undefined} value */
proto.data.proto.FloorMessage.prototype.setGeojson = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.data.proto.FloorMessage.prototype.clearGeojson = function() {
  this.setGeojson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.FloorMessage.prototype.hasGeojson = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 asset_count = 9;
 * @return {number}
 */
proto.data.proto.FloorMessage.prototype.getAssetCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.data.proto.FloorMessage.prototype.setAssetCount = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated string proto_children = 10;
 * @return {!Array.<string>}
 */
proto.data.proto.FloorMessage.prototype.getProtoChildrenList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/** @param {!Array.<string>} value */
proto.data.proto.FloorMessage.prototype.setProtoChildrenList = function(value) {
  jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.data.proto.FloorMessage.prototype.addProtoChildren = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


proto.data.proto.FloorMessage.prototype.clearProtoChildrenList = function() {
  this.setProtoChildrenList([]);
};


/**
 * optional string proto_parent = 11;
 * @return {string}
 */
proto.data.proto.FloorMessage.prototype.getProtoParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.data.proto.FloorMessage.prototype.setProtoParent = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.StationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.StationMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.StationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.StationMessage.displayName = 'proto.data.proto.StationMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.StationMessage.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.StationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.StationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.StationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.StationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    latLong: (f = msg.getLatLong()) && proto.data.proto.Point.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    macaddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: jspb.Message.getFieldWithDefault(msg, 4, ""),
    location: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modified: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    service: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastSeen: jspb.Message.getFieldWithDefault(msg, 9, ""),
    data: (f = msg.getData()) && proto.data.proto.Station_data.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && proto.data.proto.Station_Settings.toObject(includeInstance, f),
    protoChildrenList: jspb.Message.getRepeatedField(msg, 12),
    protoParent: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.StationMessage}
 */
proto.data.proto.StationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.StationMessage;
  return proto.data.proto.StationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.StationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.StationMessage}
 */
proto.data.proto.StationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.Point;
      reader.readMessage(value,proto.data.proto.Point.deserializeBinaryFromReader);
      msg.setLatLong(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacaddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModified(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSeen(value);
      break;
    case 10:
      var value = new proto.data.proto.Station_data;
      reader.readMessage(value,proto.data.proto.Station_data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 11:
      var value = new proto.data.proto.Station_Settings;
      reader.readMessage(value,proto.data.proto.Station_Settings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoChildren(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtoParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.StationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.StationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.StationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.StationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatLong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.data.proto.Point.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMacaddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModified();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getService();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastSeen();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.data.proto.Station_data.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.data.proto.Station_Settings.serializeBinaryToWriter
    );
  }
  f = message.getProtoChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getProtoParent();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional Point lat_long = 1;
 * @return {?proto.data.proto.Point}
 */
proto.data.proto.StationMessage.prototype.getLatLong = function() {
  return /** @type{?proto.data.proto.Point} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Point, 1));
};


/** @param {?proto.data.proto.Point|undefined} value */
proto.data.proto.StationMessage.prototype.setLatLong = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.data.proto.StationMessage.prototype.clearLatLong = function() {
  this.setLatLong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.StationMessage.prototype.hasLatLong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string macAddress = 3;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getMacaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setMacaddress = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created = 4;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setCreated = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string location = 5;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setLocation = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string modified = 6;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getModified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setModified = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string service = 8;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setService = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string last_seen = 9;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getLastSeen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setLastSeen = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Station_data data = 10;
 * @return {?proto.data.proto.Station_data}
 */
proto.data.proto.StationMessage.prototype.getData = function() {
  return /** @type{?proto.data.proto.Station_data} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Station_data, 10));
};


/** @param {?proto.data.proto.Station_data|undefined} value */
proto.data.proto.StationMessage.prototype.setData = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.data.proto.StationMessage.prototype.clearData = function() {
  this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.StationMessage.prototype.hasData = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Station_Settings settings = 11;
 * @return {?proto.data.proto.Station_Settings}
 */
proto.data.proto.StationMessage.prototype.getSettings = function() {
  return /** @type{?proto.data.proto.Station_Settings} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Station_Settings, 11));
};


/** @param {?proto.data.proto.Station_Settings|undefined} value */
proto.data.proto.StationMessage.prototype.setSettings = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.data.proto.StationMessage.prototype.clearSettings = function() {
  this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.StationMessage.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated string proto_children = 12;
 * @return {!Array.<string>}
 */
proto.data.proto.StationMessage.prototype.getProtoChildrenList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array.<string>} value */
proto.data.proto.StationMessage.prototype.setProtoChildrenList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.data.proto.StationMessage.prototype.addProtoChildren = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.data.proto.StationMessage.prototype.clearProtoChildrenList = function() {
  this.setProtoChildrenList([]);
};


/**
 * optional string proto_parent = 13;
 * @return {string}
 */
proto.data.proto.StationMessage.prototype.getProtoParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.data.proto.StationMessage.prototype.setProtoParent = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Station_data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.Station_data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Station_data.displayName = 'proto.data.proto.Station_data';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Station_data.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Station_data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Station_data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Station_data.toObject = function(includeInstance, msg) {
  var f, obj = {
    record: (f = msg.getRecord()) && proto.data.proto.Station_System_Record.toObject(includeInstance, f),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Station_data}
 */
proto.data.proto.Station_data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Station_data;
  return proto.data.proto.Station_data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Station_data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Station_data}
 */
proto.data.proto.Station_data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.Station_System_Record;
      reader.readMessage(value,proto.data.proto.Station_System_Record.deserializeBinaryFromReader);
      msg.setRecord(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Station_data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Station_data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Station_data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Station_data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecord();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.data.proto.Station_System_Record.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional Station_System_Record record = 1;
 * @return {?proto.data.proto.Station_System_Record}
 */
proto.data.proto.Station_data.prototype.getRecord = function() {
  return /** @type{?proto.data.proto.Station_System_Record} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Station_System_Record, 1));
};


/** @param {?proto.data.proto.Station_System_Record|undefined} value */
proto.data.proto.Station_data.prototype.setRecord = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.data.proto.Station_data.prototype.clearRecord = function() {
  this.setRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.Station_data.prototype.hasRecord = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 timestamp = 2;
 * @return {number}
 */
proto.data.proto.Station_data.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.data.proto.Station_data.prototype.setTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Station_Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.Station_Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Station_Settings.displayName = 'proto.data.proto.Station_Settings';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Station_Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Station_Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Station_Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Station_Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    binversion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Station_Settings}
 */
proto.data.proto.Station_Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Station_Settings;
  return proto.data.proto.Station_Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Station_Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Station_Settings}
 */
proto.data.proto.Station_Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBinversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Station_Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Station_Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Station_Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Station_Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBinversion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.data.proto.Station_Settings.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.data.proto.Station_Settings.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 binVersion = 2;
 * @return {number}
 */
proto.data.proto.Station_Settings.prototype.getBinversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.data.proto.Station_Settings.prototype.setBinversion = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Station_System_Record = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.Station_System_Record.repeatedFields_, null);
};
goog.inherits(proto.data.proto.Station_System_Record, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Station_System_Record.displayName = 'proto.data.proto.Station_System_Record';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.Station_System_Record.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Station_System_Record.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Station_System_Record.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Station_System_Record} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Station_System_Record.toObject = function(includeInstance, msg) {
  var f, obj = {
    silicon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wifiRssi: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextOta: jspb.Message.getFieldWithDefault(msg, 3, ""),
    versionList: jspb.Message.getRepeatedField(msg, 4),
    settingversion: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Station_System_Record}
 */
proto.data.proto.Station_System_Record.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Station_System_Record;
  return proto.data.proto.Station_System_Record.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Station_System_Record} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Station_System_Record}
 */
proto.data.proto.Station_System_Record.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSilicon(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWifiRssi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextOta(value);
      break;
    case 4:
      var value = /** @type {!Array.<number>} */ (reader.readPackedUint32());
      msg.setVersionList(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSettingversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Station_System_Record.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Station_System_Record.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Station_System_Record} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Station_System_Record.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSilicon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWifiRssi();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNextOta();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersionList();
  if (f.length > 0) {
    writer.writePackedUint32(
      4,
      f
    );
  }
  f = message.getSettingversion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string silicon = 1;
 * @return {string}
 */
proto.data.proto.Station_System_Record.prototype.getSilicon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.data.proto.Station_System_Record.prototype.setSilicon = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 wifi_rssi = 2;
 * @return {number}
 */
proto.data.proto.Station_System_Record.prototype.getWifiRssi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.data.proto.Station_System_Record.prototype.setWifiRssi = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_ota = 3;
 * @return {string}
 */
proto.data.proto.Station_System_Record.prototype.getNextOta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.Station_System_Record.prototype.setNextOta = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated uint32 version = 4;
 * @return {!Array.<number>}
 */
proto.data.proto.Station_System_Record.prototype.getVersionList = function() {
  return /** @type {!Array.<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array.<number>} value */
proto.data.proto.Station_System_Record.prototype.setVersionList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.data.proto.Station_System_Record.prototype.addVersion = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.data.proto.Station_System_Record.prototype.clearVersionList = function() {
  this.setVersionList([]);
};


/**
 * optional uint32 settingVersion = 5;
 * @return {number}
 */
proto.data.proto.Station_System_Record.prototype.getSettingversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.data.proto.Station_System_Record.prototype.setSettingversion = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.AssetMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.AssetMessage.repeatedFields_, null);
};
goog.inherits(proto.data.proto.AssetMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.AssetMessage.displayName = 'proto.data.proto.AssetMessage';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.AssetMessage.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.AssetMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.AssetMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.AssetMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.AssetMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    latLong: (f = msg.getLatLong()) && proto.data.proto.Point.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    macaddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modified: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    service: jspb.Message.getFieldWithDefault(msg, 7, ""),
    data: (f = msg.getData()) && proto.data.proto.Asset_data.toObject(includeInstance, f),
    floor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastSeen: jspb.Message.getFieldWithDefault(msg, 10, ""),
    location: jspb.Message.getFieldWithDefault(msg, 11, ""),
    details: jspb.Message.getFieldWithDefault(msg, 12, ""),
    protoChildrenList: jspb.Message.toObjectList(msg.getProtoChildrenList(),
    proto.data.proto.Empty.toObject, includeInstance),
    protoParentsList: jspb.Message.getRepeatedField(msg, 14),
    batteryLevel: jspb.Message.getFieldWithDefault(msg, 15, 0),
    batteryUpdatedTimestamp: jspb.Message.getFieldWithDefault(msg, 16, 0),
    batteryUpdatedBy: jspb.Message.getFieldWithDefault(msg, 17, ""),
    batterySentCommandTimestamp: jspb.Message.getFieldWithDefault(msg, 18, 0),
    batterySentCommandTo: jspb.Message.getFieldWithDefault(msg, 19, ""),
    locationEnteredTimestamp: jspb.Message.getFieldWithDefault(msg, 20, 0),
    ringCommandCreatedTimestamp: jspb.Message.getFieldWithDefault(msg, 21, 0),
    ringCommandSentTimestamp: jspb.Message.getFieldWithDefault(msg, 22, 0),
    ringCommandSentTo: jspb.Message.getFieldWithDefault(msg, 23, ""),
    ringCommandSuccessfulTimestamp: jspb.Message.getFieldWithDefault(msg, 24, 0),
    ringCommandSuccessfulBy: jspb.Message.getFieldWithDefault(msg, 25, ""),
    ringCommandSuccessfulCounter: jspb.Message.getFieldWithDefault(msg, 26, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.AssetMessage}
 */
proto.data.proto.AssetMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.AssetMessage;
  return proto.data.proto.AssetMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.AssetMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.AssetMessage}
 */
proto.data.proto.AssetMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.Point;
      reader.readMessage(value,proto.data.proto.Point.deserializeBinaryFromReader);
      msg.setLatLong(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacaddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModified(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 8:
      var value = new proto.data.proto.Asset_data;
      reader.readMessage(value,proto.data.proto.Asset_data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSeen(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    case 13:
      var value = new proto.data.proto.Empty;
      reader.readMessage(value,proto.data.proto.Empty.deserializeBinaryFromReader);
      msg.addProtoChildren(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoParents(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryLevel(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBatteryUpdatedTimestamp(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatteryUpdatedBy(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBatterySentCommandTimestamp(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatterySentCommandTo(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLocationEnteredTimestamp(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRingCommandCreatedTimestamp(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRingCommandSentTimestamp(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setRingCommandSentTo(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRingCommandSuccessfulTimestamp(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setRingCommandSuccessfulBy(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRingCommandSuccessfulCounter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.AssetMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.AssetMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.AssetMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.AssetMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatLong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.data.proto.Point.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMacaddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModified();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getService();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.data.proto.Asset_data.serializeBinaryToWriter
    );
  }
  f = message.getFloor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastSeen();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProtoChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.data.proto.Empty.serializeBinaryToWriter
    );
  }
  f = message.getProtoParentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getBatteryLevel();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getBatteryUpdatedTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getBatteryUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBatterySentCommandTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      18,
      f
    );
  }
  f = message.getBatterySentCommandTo();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLocationEnteredTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getRingCommandCreatedTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getRingCommandSentTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getRingCommandSentTo();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getRingCommandSuccessfulTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getRingCommandSuccessfulBy();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getRingCommandSuccessfulCounter();
  if (f !== 0) {
    writer.writeUint64(
      26,
      f
    );
  }
};


/**
 * optional Point lat_long = 1;
 * @return {?proto.data.proto.Point}
 */
proto.data.proto.AssetMessage.prototype.getLatLong = function() {
  return /** @type{?proto.data.proto.Point} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Point, 1));
};


/** @param {?proto.data.proto.Point|undefined} value */
proto.data.proto.AssetMessage.prototype.setLatLong = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.data.proto.AssetMessage.prototype.clearLatLong = function() {
  this.setLatLong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.AssetMessage.prototype.hasLatLong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string macAddress = 3;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getMacaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setMacaddress = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created = 4;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setCreated = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string modified = 5;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getModified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setModified = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string service = 7;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setService = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Asset_data data = 8;
 * @return {?proto.data.proto.Asset_data}
 */
proto.data.proto.AssetMessage.prototype.getData = function() {
  return /** @type{?proto.data.proto.Asset_data} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Asset_data, 8));
};


/** @param {?proto.data.proto.Asset_data|undefined} value */
proto.data.proto.AssetMessage.prototype.setData = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.data.proto.AssetMessage.prototype.clearData = function() {
  this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.AssetMessage.prototype.hasData = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string floor = 9;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getFloor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setFloor = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string last_seen = 10;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getLastSeen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setLastSeen = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string location = 11;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setLocation = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string details = 12;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setDetails = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated Empty proto_children = 13;
 * @return {!Array.<!proto.data.proto.Empty>}
 */
proto.data.proto.AssetMessage.prototype.getProtoChildrenList = function() {
  return /** @type{!Array.<!proto.data.proto.Empty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.Empty, 13));
};


/** @param {!Array.<!proto.data.proto.Empty>} value */
proto.data.proto.AssetMessage.prototype.setProtoChildrenList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.data.proto.Empty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.Empty}
 */
proto.data.proto.AssetMessage.prototype.addProtoChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.data.proto.Empty, opt_index);
};


proto.data.proto.AssetMessage.prototype.clearProtoChildrenList = function() {
  this.setProtoChildrenList([]);
};


/**
 * repeated string proto_parents = 14;
 * @return {!Array.<string>}
 */
proto.data.proto.AssetMessage.prototype.getProtoParentsList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/** @param {!Array.<string>} value */
proto.data.proto.AssetMessage.prototype.setProtoParentsList = function(value) {
  jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.data.proto.AssetMessage.prototype.addProtoParents = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


proto.data.proto.AssetMessage.prototype.clearProtoParentsList = function() {
  this.setProtoParentsList([]);
};


/**
 * optional uint32 battery_level = 15;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getBatteryLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setBatteryLevel = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint64 battery_updated_timestamp = 16;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getBatteryUpdatedTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setBatteryUpdatedTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string battery_updated_by = 17;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getBatteryUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setBatteryUpdatedBy = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint64 battery_sent_command_timestamp = 18;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getBatterySentCommandTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setBatterySentCommandTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string battery_sent_command_to = 19;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getBatterySentCommandTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setBatterySentCommandTo = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional uint64 location_entered_timestamp = 20;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getLocationEnteredTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setLocationEnteredTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 ring_command_created_timestamp = 21;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getRingCommandCreatedTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setRingCommandCreatedTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 ring_command_sent_timestamp = 22;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getRingCommandSentTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setRingCommandSentTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string ring_command_sent_to = 23;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getRingCommandSentTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setRingCommandSentTo = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional uint64 ring_command_successful_timestamp = 24;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getRingCommandSuccessfulTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setRingCommandSuccessfulTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string ring_command_successful_by = 25;
 * @return {string}
 */
proto.data.proto.AssetMessage.prototype.getRingCommandSuccessfulBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.data.proto.AssetMessage.prototype.setRingCommandSuccessfulBy = function(value) {
  jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional uint64 ring_command_successful_counter = 26;
 * @return {number}
 */
proto.data.proto.AssetMessage.prototype.getRingCommandSuccessfulCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/** @param {number} value */
proto.data.proto.AssetMessage.prototype.setRingCommandSuccessfulCounter = function(value) {
  jspb.Message.setProto3IntField(this, 26, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Asset_data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.Asset_data.repeatedFields_, null);
};
goog.inherits(proto.data.proto.Asset_data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Asset_data.displayName = 'proto.data.proto.Asset_data';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.Asset_data.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Asset_data.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Asset_data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Asset_data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Asset_data.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastStation: (f = msg.getLastStation()) && proto.data.proto.Asset_Station.toObject(includeInstance, f),
    calculatedPosition: (f = msg.getCalculatedPosition()) && proto.data.proto.Asset_Station.toObject(includeInstance, f),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    proto.data.proto.Asset_Station.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Asset_data}
 */
proto.data.proto.Asset_data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Asset_data;
  return proto.data.proto.Asset_data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Asset_data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Asset_data}
 */
proto.data.proto.Asset_data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.data.proto.Asset_Station;
      reader.readMessage(value,proto.data.proto.Asset_Station.deserializeBinaryFromReader);
      msg.setLastStation(value);
      break;
    case 2:
      var value = new proto.data.proto.Asset_Station;
      reader.readMessage(value,proto.data.proto.Asset_Station.deserializeBinaryFromReader);
      msg.setCalculatedPosition(value);
      break;
    case 3:
      var value = new proto.data.proto.Asset_Station;
      reader.readMessage(value,proto.data.proto.Asset_Station.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Asset_data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Asset_data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Asset_data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Asset_data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastStation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.data.proto.Asset_Station.serializeBinaryToWriter
    );
  }
  f = message.getCalculatedPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.data.proto.Asset_Station.serializeBinaryToWriter
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.data.proto.Asset_Station.serializeBinaryToWriter
    );
  }
};


/**
 * optional Asset_Station last_station = 1;
 * @return {?proto.data.proto.Asset_Station}
 */
proto.data.proto.Asset_data.prototype.getLastStation = function() {
  return /** @type{?proto.data.proto.Asset_Station} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Asset_Station, 1));
};


/** @param {?proto.data.proto.Asset_Station|undefined} value */
proto.data.proto.Asset_data.prototype.setLastStation = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.data.proto.Asset_data.prototype.clearLastStation = function() {
  this.setLastStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.Asset_data.prototype.hasLastStation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Asset_Station calculated_position = 2;
 * @return {?proto.data.proto.Asset_Station}
 */
proto.data.proto.Asset_data.prototype.getCalculatedPosition = function() {
  return /** @type{?proto.data.proto.Asset_Station} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Asset_Station, 2));
};


/** @param {?proto.data.proto.Asset_Station|undefined} value */
proto.data.proto.Asset_data.prototype.setCalculatedPosition = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.data.proto.Asset_data.prototype.clearCalculatedPosition = function() {
  this.setCalculatedPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.Asset_data.prototype.hasCalculatedPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Asset_Station history = 3;
 * @return {!Array.<!proto.data.proto.Asset_Station>}
 */
proto.data.proto.Asset_data.prototype.getHistoryList = function() {
  return /** @type{!Array.<!proto.data.proto.Asset_Station>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.Asset_Station, 3));
};


/** @param {!Array.<!proto.data.proto.Asset_Station>} value */
proto.data.proto.Asset_data.prototype.setHistoryList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.data.proto.Asset_Station=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.Asset_Station}
 */
proto.data.proto.Asset_data.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.data.proto.Asset_Station, opt_index);
};


proto.data.proto.Asset_data.prototype.clearHistoryList = function() {
  this.setHistoryList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Asset_Station = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.Asset_Station, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Asset_Station.displayName = 'proto.data.proto.Asset_Station';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Asset_Station.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Asset_Station.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Asset_Station} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Asset_Station.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rssi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accuracy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    latLong: (f = msg.getLatLong()) && proto.data.proto.Point.toObject(includeInstance, f),
    location: jspb.Message.getFieldWithDefault(msg, 7, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Asset_Station}
 */
proto.data.proto.Asset_Station.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Asset_Station;
  return proto.data.proto.Asset_Station.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Asset_Station} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Asset_Station}
 */
proto.data.proto.Asset_Station.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRssi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccuracy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = new proto.data.proto.Point;
      reader.readMessage(value,proto.data.proto.Point.deserializeBinaryFromReader);
      msg.setLatLong(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Asset_Station.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Asset_Station.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Asset_Station} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Asset_Station.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRssi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccuracy();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getLatLong();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.data.proto.Point.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.data.proto.Asset_Station.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.data.proto.Asset_Station.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.data.proto.Asset_Station.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.Asset_Station.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rssi = 3;
 * @return {string}
 */
proto.data.proto.Asset_Station.prototype.getRssi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.Asset_Station.prototype.setRssi = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 accuracy = 4;
 * @return {number}
 */
proto.data.proto.Asset_Station.prototype.getAccuracy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.data.proto.Asset_Station.prototype.setAccuracy = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 timestamp = 5;
 * @return {number}
 */
proto.data.proto.Asset_Station.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.data.proto.Asset_Station.prototype.setTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Point lat_long = 6;
 * @return {?proto.data.proto.Point}
 */
proto.data.proto.Asset_Station.prototype.getLatLong = function() {
  return /** @type{?proto.data.proto.Point} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Point, 6));
};


/** @param {?proto.data.proto.Point|undefined} value */
proto.data.proto.Asset_Station.prototype.setLatLong = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.data.proto.Asset_Station.prototype.clearLatLong = function() {
  this.setLatLong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.Asset_Station.prototype.hasLatLong = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string location = 7;
 * @return {string}
 */
proto.data.proto.Asset_Station.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.data.proto.Asset_Station.prototype.setLocation = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string location_name = 8;
 * @return {string}
 */
proto.data.proto.Asset_Station.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.data.proto.Asset_Station.prototype.setLocationName = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Location_Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.Location_Page.repeatedFields_, null);
};
goog.inherits(proto.data.proto.Location_Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Location_Page.displayName = 'proto.data.proto.Location_Page';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.Location_Page.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Location_Page.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Location_Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Location_Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Location_Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfLocations: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numberOfStations: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfAssets: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.data.proto.LocationMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Location_Page}
 */
proto.data.proto.Location_Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Location_Page;
  return proto.data.proto.Location_Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Location_Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Location_Page}
 */
proto.data.proto.Location_Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfLocations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfStations(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfAssets(value);
      break;
    case 4:
      var value = new proto.data.proto.LocationMessage;
      reader.readMessage(value,proto.data.proto.LocationMessage.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Location_Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Location_Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Location_Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Location_Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfLocations();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNumberOfStations();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNumberOfAssets();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.data.proto.LocationMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 number_of_locations = 1;
 * @return {number}
 */
proto.data.proto.Location_Page.prototype.getNumberOfLocations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.data.proto.Location_Page.prototype.setNumberOfLocations = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 number_of_stations = 2;
 * @return {number}
 */
proto.data.proto.Location_Page.prototype.getNumberOfStations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.data.proto.Location_Page.prototype.setNumberOfStations = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 number_of_assets = 3;
 * @return {number}
 */
proto.data.proto.Location_Page.prototype.getNumberOfAssets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.data.proto.Location_Page.prototype.setNumberOfAssets = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated LocationMessage locations = 4;
 * @return {!Array.<!proto.data.proto.LocationMessage>}
 */
proto.data.proto.Location_Page.prototype.getLocationsList = function() {
  return /** @type{!Array.<!proto.data.proto.LocationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.LocationMessage, 4));
};


/** @param {!Array.<!proto.data.proto.LocationMessage>} value */
proto.data.proto.Location_Page.prototype.setLocationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.data.proto.LocationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.LocationMessage}
 */
proto.data.proto.Location_Page.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.data.proto.LocationMessage, opt_index);
};


proto.data.proto.Location_Page.prototype.clearLocationsList = function() {
  this.setLocationsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.StationMessage_Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.StationMessage_Page.repeatedFields_, null);
};
goog.inherits(proto.data.proto.StationMessage_Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.StationMessage_Page.displayName = 'proto.data.proto.StationMessage_Page';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.StationMessage_Page.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.StationMessage_Page.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.StationMessage_Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.StationMessage_Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.StationMessage_Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfStations: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numberOfStationsOnline: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfStationsOffline: jspb.Message.getFieldWithDefault(msg, 3, 0),
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    proto.data.proto.StationMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.StationMessage_Page}
 */
proto.data.proto.StationMessage_Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.StationMessage_Page;
  return proto.data.proto.StationMessage_Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.StationMessage_Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.StationMessage_Page}
 */
proto.data.proto.StationMessage_Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfStations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfStationsOnline(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfStationsOffline(value);
      break;
    case 5:
      var value = new proto.data.proto.StationMessage;
      reader.readMessage(value,proto.data.proto.StationMessage.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.StationMessage_Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.StationMessage_Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.StationMessage_Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.StationMessage_Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfStations();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNumberOfStationsOnline();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNumberOfStationsOffline();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.data.proto.StationMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 number_of_stations = 1;
 * @return {number}
 */
proto.data.proto.StationMessage_Page.prototype.getNumberOfStations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.data.proto.StationMessage_Page.prototype.setNumberOfStations = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 number_of_stations_online = 2;
 * @return {number}
 */
proto.data.proto.StationMessage_Page.prototype.getNumberOfStationsOnline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.data.proto.StationMessage_Page.prototype.setNumberOfStationsOnline = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 number_of_stations_offline = 3;
 * @return {number}
 */
proto.data.proto.StationMessage_Page.prototype.getNumberOfStationsOffline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.data.proto.StationMessage_Page.prototype.setNumberOfStationsOffline = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated StationMessage stations = 5;
 * @return {!Array.<!proto.data.proto.StationMessage>}
 */
proto.data.proto.StationMessage_Page.prototype.getStationsList = function() {
  return /** @type{!Array.<!proto.data.proto.StationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.StationMessage, 5));
};


/** @param {!Array.<!proto.data.proto.StationMessage>} value */
proto.data.proto.StationMessage_Page.prototype.setStationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.data.proto.StationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.StationMessage}
 */
proto.data.proto.StationMessage_Page.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.data.proto.StationMessage, opt_index);
};


proto.data.proto.StationMessage_Page.prototype.clearStationsList = function() {
  this.setStationsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Asset_Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.data.proto.Asset_Page.repeatedFields_, null);
};
goog.inherits(proto.data.proto.Asset_Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Asset_Page.displayName = 'proto.data.proto.Asset_Page';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.data.proto.Asset_Page.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Asset_Page.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Asset_Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Asset_Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Asset_Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfAssets: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numberOfAssetsOnline: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfAssetsOffline: jspb.Message.getFieldWithDefault(msg, 3, 0),
    assetList: jspb.Message.toObjectList(msg.getAssetList(),
    proto.data.proto.AssetMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Asset_Page}
 */
proto.data.proto.Asset_Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Asset_Page;
  return proto.data.proto.Asset_Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Asset_Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Asset_Page}
 */
proto.data.proto.Asset_Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfAssets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfAssetsOnline(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfAssetsOffline(value);
      break;
    case 4:
      var value = new proto.data.proto.AssetMessage;
      reader.readMessage(value,proto.data.proto.AssetMessage.deserializeBinaryFromReader);
      msg.addAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Asset_Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Asset_Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Asset_Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Asset_Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfAssets();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNumberOfAssetsOnline();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNumberOfAssetsOffline();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAssetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.data.proto.AssetMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 number_of_assets = 1;
 * @return {number}
 */
proto.data.proto.Asset_Page.prototype.getNumberOfAssets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.data.proto.Asset_Page.prototype.setNumberOfAssets = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 number_of_assets_online = 2;
 * @return {number}
 */
proto.data.proto.Asset_Page.prototype.getNumberOfAssetsOnline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.data.proto.Asset_Page.prototype.setNumberOfAssetsOnline = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 number_of_assets_offline = 3;
 * @return {number}
 */
proto.data.proto.Asset_Page.prototype.getNumberOfAssetsOffline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.data.proto.Asset_Page.prototype.setNumberOfAssetsOffline = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated AssetMessage asset = 4;
 * @return {!Array.<!proto.data.proto.AssetMessage>}
 */
proto.data.proto.Asset_Page.prototype.getAssetList = function() {
  return /** @type{!Array.<!proto.data.proto.AssetMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.data.proto.AssetMessage, 4));
};


/** @param {!Array.<!proto.data.proto.AssetMessage>} value */
proto.data.proto.Asset_Page.prototype.setAssetList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.data.proto.AssetMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.data.proto.AssetMessage}
 */
proto.data.proto.Asset_Page.prototype.addAsset = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.data.proto.AssetMessage, opt_index);
};


proto.data.proto.Asset_Page.prototype.clearAssetList = function() {
  this.setAssetList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.FloorplanMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.FloorplanMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.FloorplanMessage.displayName = 'proto.data.proto.FloorplanMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.FloorplanMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.FloorplanMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.FloorplanMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.FloorplanMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cornerOne: (f = msg.getCornerOne()) && proto.data.proto.Point.toObject(includeInstance, f),
    cornerTwo: (f = msg.getCornerTwo()) && proto.data.proto.Point.toObject(includeInstance, f),
    base64: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.FloorplanMessage}
 */
proto.data.proto.FloorplanMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.FloorplanMessage;
  return proto.data.proto.FloorplanMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.FloorplanMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.FloorplanMessage}
 */
proto.data.proto.FloorplanMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 2:
      var value = new proto.data.proto.Point;
      reader.readMessage(value,proto.data.proto.Point.deserializeBinaryFromReader);
      msg.setCornerOne(value);
      break;
    case 3:
      var value = new proto.data.proto.Point;
      reader.readMessage(value,proto.data.proto.Point.deserializeBinaryFromReader);
      msg.setCornerTwo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBase64(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.FloorplanMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.FloorplanMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.FloorplanMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.FloorplanMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCornerOne();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.data.proto.Point.serializeBinaryToWriter
    );
  }
  f = message.getCornerTwo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.data.proto.Point.serializeBinaryToWriter
    );
  }
  f = message.getBase64();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string image = 1;
 * @return {string}
 */
proto.data.proto.FloorplanMessage.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.data.proto.FloorplanMessage.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Point corner_one = 2;
 * @return {?proto.data.proto.Point}
 */
proto.data.proto.FloorplanMessage.prototype.getCornerOne = function() {
  return /** @type{?proto.data.proto.Point} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Point, 2));
};


/** @param {?proto.data.proto.Point|undefined} value */
proto.data.proto.FloorplanMessage.prototype.setCornerOne = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.data.proto.FloorplanMessage.prototype.clearCornerOne = function() {
  this.setCornerOne(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.FloorplanMessage.prototype.hasCornerOne = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Point corner_two = 3;
 * @return {?proto.data.proto.Point}
 */
proto.data.proto.FloorplanMessage.prototype.getCornerTwo = function() {
  return /** @type{?proto.data.proto.Point} */ (
    jspb.Message.getWrapperField(this, proto.data.proto.Point, 3));
};


/** @param {?proto.data.proto.Point|undefined} value */
proto.data.proto.FloorplanMessage.prototype.setCornerTwo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.data.proto.FloorplanMessage.prototype.clearCornerTwo = function() {
  this.setCornerTwo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.data.proto.FloorplanMessage.prototype.hasCornerTwo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string base64 = 4;
 * @return {string}
 */
proto.data.proto.FloorplanMessage.prototype.getBase64 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.data.proto.FloorplanMessage.prototype.setBase64 = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.GeoJsonMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.GeoJsonMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.GeoJsonMessage.displayName = 'proto.data.proto.GeoJsonMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.GeoJsonMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.GeoJsonMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.GeoJsonMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.GeoJsonMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    json: jspb.Message.getFieldWithDefault(msg, 1, ""),
    base64: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.GeoJsonMessage}
 */
proto.data.proto.GeoJsonMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.GeoJsonMessage;
  return proto.data.proto.GeoJsonMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.GeoJsonMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.GeoJsonMessage}
 */
proto.data.proto.GeoJsonMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBase64(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.GeoJsonMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.GeoJsonMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.GeoJsonMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.GeoJsonMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBase64();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string json = 1;
 * @return {string}
 */
proto.data.proto.GeoJsonMessage.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.data.proto.GeoJsonMessage.prototype.setJson = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string base64 = 2;
 * @return {string}
 */
proto.data.proto.GeoJsonMessage.prototype.getBase64 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.GeoJsonMessage.prototype.setBase64 = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Address.displayName = 'proto.data.proto.Address';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    zipcode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Address}
 */
proto.data.proto.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Address;
  return proto.data.proto.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Address}
 */
proto.data.proto.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZipcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getZipcode();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.data.proto.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.data.proto.Address.prototype.setStreet = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.data.proto.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.data.proto.Address.prototype.setCity = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.data.proto.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.data.proto.Address.prototype.setState = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 zipcode = 4;
 * @return {number}
 */
proto.data.proto.Address.prototype.getZipcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.data.proto.Address.prototype.setZipcode = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Point.displayName = 'proto.data.proto.Point';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFieldWithDefault(msg, 1, 0),
    y: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Point}
 */
proto.data.proto.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Point;
  return proto.data.proto.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Point}
 */
proto.data.proto.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 x = 1;
 * @return {number}
 */
proto.data.proto.Point.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.data.proto.Point.prototype.setX = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 y = 2;
 * @return {number}
 */
proto.data.proto.Point.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.data.proto.Point.prototype.setY = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Response.displayName = 'proto.data.proto.Response';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    allGood: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Response}
 */
proto.data.proto.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Response;
  return proto.data.proto.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Response}
 */
proto.data.proto.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllGood(value);
      break;
    case 2:
      var value = /** @type {!proto.data.proto.Error} */ (reader.readEnum());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllGood();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string all_good = 1;
 * @return {string}
 */
proto.data.proto.Response.prototype.getAllGood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.data.proto.Response.prototype.setAllGood = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {!proto.data.proto.Error}
 */
proto.data.proto.Response.prototype.getError = function() {
  return /** @type {!proto.data.proto.Error} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.data.proto.Error} value */
proto.data.proto.Response.prototype.setError = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.LocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.LocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.LocationRequest.displayName = 'proto.data.proto.LocationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.LocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.LocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.LocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.LocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldRequest: msg.getFieldRequest_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.LocationRequest}
 */
proto.data.proto.LocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.LocationRequest;
  return proto.data.proto.LocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.LocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.LocationRequest}
 */
proto.data.proto.LocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFieldRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.LocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.LocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.LocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.LocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldRequest_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes field_request = 1;
 * @return {string}
 */
proto.data.proto.LocationRequest.prototype.getFieldRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes field_request = 1;
 * This is a type-conversion wrapper around `getFieldRequest()`
 * @return {string}
 */
proto.data.proto.LocationRequest.prototype.getFieldRequest_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFieldRequest()));
};


/**
 * optional bytes field_request = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFieldRequest()`
 * @return {!Uint8Array}
 */
proto.data.proto.LocationRequest.prototype.getFieldRequest_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFieldRequest()));
};


/** @param {!(string|Uint8Array)} value */
proto.data.proto.LocationRequest.prototype.setFieldRequest = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.data.proto.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.data.proto.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.data.proto.Empty.displayName = 'proto.data.proto.Empty';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.data.proto.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.data.proto.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.data.proto.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.data.proto.Empty}
 */
proto.data.proto.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.data.proto.Empty;
  return proto.data.proto.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.data.proto.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.data.proto.Empty}
 */
proto.data.proto.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.data.proto.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.data.proto.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.data.proto.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.data.proto.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.data.proto.Error = {
  MISSING_INFO: 0
};

goog.object.extend(exports, proto.data.proto);
