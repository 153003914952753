import { Injectable } from '@angular/core';
import { LocationsListMessage, LocationMessage, FloorMessage, OrganizationMessage, AssetMessage, StationMessage, EntityListsMessage, UsersMessage, UserMessage } from 'src/shared/protos/generated/data_pb';
import { default_url } from '@app/_models';
import { GraphService, Services } from './graph.service';
import { DataService } from './data.service';
import { AuthenticationService } from './authentication.service';
import { WebService } from './web.service';
import { LocationDerivedModel, FloorDerivedModel, AssetDerivedModel, StationDerivedModel } from './models';
import { parseDevice, parseFloor, parseLocation, parseOrg, parseUser } from '@app/_helpers';

export enum Uri {
	Login = 'login',
	Organization = 'Organization/',
	Users = 'Users?/',
	User = 'User',
	Locations = 'locations/',
	Floors = 'floors/',
	Devices = 'devices/',
	Device_Settings = 'devices/settings',
	Export = 'exportDevices'
}

const options = {
	body: {
		'user': {
			'username': '',
			'pw': ''
		}
	}
};

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	public url: string = default_url;


	constructor(
		/* Private */
		public dataService: DataService,
		private auth: AuthenticationService,
		private web: WebService,
		private graph: GraphService
	) {
		this.auth.userSubject.subscribe(user => {
			if (!user) return;

			options.body.user.username = user.username;
			options.body.user.pw = user.password;
		})
	}

	async login(username: string, password: string): Promise<any> {
		let postBody = {
			"username": username,
			"pw": password,
		}

		return new Promise<any>((resolve, reject) => {
			this.web.post(Uri.Login, postBody).then(response => {
				resolve(response);
			}).catch((err: any) => {
				console.log(" :: Login FAILED : ", err);
				reject(err);
			});
		});
	}

	public async getUser(): Promise<UserMessage> {
		return new Promise<UserMessage>((resolve, reject) => {
			this.web.get(Uri.User).then(response => {
				resolve(parseUser(response.resp_data));
			}).catch(error => {
				reject(error);
			});
		});
	}

	///////////////
	//Org Functions
	public async getOrganization(): Promise<OrganizationMessage> {
		return new Promise<OrganizationMessage>((resolve, reject) => {
			this.web.get(Uri.Organization).then(response => {
				let organizationMessage = parseOrg(response.resp_data);
				resolve(this.graph.setOrganization(organizationMessage));
			}).catch(error => {

				reject(error);
			});
		});
	}
	public async getOrganizationForReports(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.web.get("organization/reports").then(response => {
				resolve(response.resp_data);
			}).catch(error => {

				reject(error);
			});
		});
	}

	public async saveOrganizationForReportSettings(settings:any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.web.put("organization/reports", settings).then(response => {
				resolve(response.resp_data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public async runOrganizationReport(settings:any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.web.put("organization/run", settings).then(response => {
				resolve(response.resp_data);
			}).catch(error => {
				reject(error);
			});
		});
	}
	public async downloadOrganizationReport(settings:any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			console.log("settings",settings)
			this.web.get("organization/manage/"+settings.report.epoch).then(response => {
				resolve(response.resp_data);
			}).catch(error => {
				reject(error);
			});
		});
	}
	public async downloadOrganizationReportAux(settings:any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			console.log("settings",settings)
			this.web.get("organization/manage/"+settings.report.epoch+"/aux").then(response => {
				resolve(response.resp_data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public async getUsers(): Promise<UsersMessage> {
		return new Promise<UsersMessage>((resolve, reject) => {
			this.web.get(Uri.Users).then(response => {
				let usersMessage = new UsersMessage();
				response.resp_data.forEach(user_resp => {
					let user = parseUser(user_resp);
					usersMessage.addUsers(user);
				});

				resolve(usersMessage);
			}).catch(error => {
				reject(error);
			});
		});
	}
	///////////////

	///////////////
	//Create Functions

	public async createUser(body: any): Promise<void>{
		return new Promise<void>((resolve, reject) => {
			this.web.post('signup', body)
				.then((resp) => {
					if(resp != 201){
						reject();
						return;
					}
					this.getFullModel()
				}).then(() => resolve())
				.catch(error => {
					reject(error);
				});
		});
	}

	public createUserInOrg(body: any): Promise<void> {
		return new Promise((resolve, reject) => {
			this.web.post('user', body)
				.then((resp) => {
					if(resp.res != 201){
						reject();
						return;
					}
					this.getFullModel()
				}).then(() => resolve())
				.catch(error => {
					reject(error);
				});
		});
	}

	public deleteUserInOrg(user_id: any): Promise<void> {
		return new Promise((resolve, reject) => {
			this.web.delete('user/'+user_id, {})
				.then((resp) => {
					console.log(resp);
					if(resp.res != 200){
						reject();
						return;
					}
					this.getFullModel();
				}).then(() => resolve())
				.catch(error => {
					reject(error);
				});
		});
	}

	public async createLocation(): Promise<LocationMessage> {
		var date = new Date();
		let body = {
			name: "New Location - "+ date.toLocaleString(),
		}

		return new Promise<LocationMessage>((resolve, reject) => {
			let location: any;
			this.web.post(Uri.Locations, body)
				.then((data) => location = parseLocation(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(location))
				.catch(error => {
					reject(error);
				});

		});
	}

	public async createFloor(location: LocationDerivedModel): Promise<FloorDerivedModel> {
		let body = {
			name: "New Floor",
			location: location.getId()
		}

		return new Promise<FloorDerivedModel>((resolve, reject) => {
			let floor: any;
			this.web.post(Uri.Floors, body)
				.then((data) => floor = parseFloor(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(floor))
				.catch(error => {
					reject(error);
				});
		});
	}

	public createDevice(body: any): Promise<AssetDerivedModel | StationDerivedModel> {
		return new Promise<AssetDerivedModel | StationDerivedModel>((resolve, reject) => {
			let device: any;
			this.web.post(Uri.Devices, body)
				.then((data) => device = parseDevice(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(device))
				.catch(error => {
					reject(error);
				});
		});
	}

	public createDeviceNoReload(body: any): Promise<AssetDerivedModel | StationDerivedModel> {
		return new Promise<AssetDerivedModel | StationDerivedModel>((resolve, reject) => {
			let device: any;
			this.web.post(Uri.Devices, body)
				.then((data) => device = parseDevice(data.resp_data))
				.then(() => resolve(device))
				.catch(error => {
					reject(error);
				});
		});
	}
	///////////////

	///////////////
	//Get Functions
	public async getFullModel(): Promise<void> {
		return new Promise<void>((resolve, reject) => {

			this.graph.clearData();
			this.getOrganization()
				.then(() => this.getLocations())
				.then(() => this.getAllFloorsForLocations())
				.then(() => this.getAllDevicesFromOrg())
				.then(() => {
					this.connectedAndSortModel();
					resolve();
				}).catch((err) => {
					console.log(err);
					reject();
				});

		});
	}

	public async connectedAndSortModel() {
		return new Promise<void>((resolve, reject) => {
			this.graph.setAllAssetParents();
			this.graph.setAllStationParents();
			this.dataService.sortLocations();
			this.dataService.sortStations();
			this.dataService.sortAssets();
			this.dataService.setAllCounts();
			resolve();
		});
	}

	public async getAllFloorsForLocations(): Promise<void> {
		return new Promise<void>((resolve, reject) => {

			const promises = [];
			this.dataService.filterLocations().then(locations =>
				locations.forEach(location => {
					location.getProtoChildrenList().forEach(floorId => {
						promises.push(this.getFloor(floorId));
					});
				})
			);

			Promise.all(promises)
				.then(() => resolve())
				.catch(error => {
					reject(error);
				});
		});
	}

	public async getLocations(): Promise<LocationsListMessage> {
		return new Promise<LocationsListMessage>((resolve, reject) => {
			this.web.get(Uri.Locations).then(response => {
				let locations = new LocationsListMessage()
				response.resp_data.forEach((location_resp) => {
					let locationMessage = parseLocation(location_resp);
					this.graph.setLocation(locationMessage);
					locations.addLocations(locationMessage);
				});
				resolve(locations);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public async getFloor(id: string): Promise<FloorDerivedModel> {
		return new Promise<FloorDerivedModel>((resolve, reject) => {
			this.web.get(Uri.Floors + id).then(response => {
				let floorMessage = parseFloor(response.resp_data);
				resolve(this.graph.setFloor(floorMessage));
			}).catch(error => {
				reject(error);
			});
		});
	}

	public async getAllDevicesFromOrg(): Promise<EntityListsMessage> {
		return new Promise<EntityListsMessage>((resolve, reject) => {
			this.web.get(Uri.Devices).then(response => {
				let entities = new EntityListsMessage();
				response.resp_data.forEach(device => {
					let deviceMessage = parseDevice(device);
					this.graph.setDevice(deviceMessage);
					if (deviceMessage) {
						if (deviceMessage.getService() == Services.Tag) entities.addAssets(deviceMessage as AssetMessage);
						else if (deviceMessage.getService() == Services.Station) entities.addStations(deviceMessage as StationMessage);
					}
				});
				resolve(entities);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public async getDevice(id: string): Promise<AssetDerivedModel | StationDerivedModel> {
		return new Promise<AssetDerivedModel | StationDerivedModel>((resolve, reject) => {
			this.web.get(Uri.Devices + id).then(response => {
				let deviceMessage = parseDevice(response);
				let model = this.graph.setDevice(deviceMessage);
				resolve(model);
			}).catch(error => {
				reject(error);
			});
		});
	}


	public getExport(body: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.web.post(Uri.Export, body).then(response => {
				if (response.resp_data) {
					resolve(response.resp_data);
				}
				else {
					reject();
				}
			}).catch(error => {
				reject(error);
			});;
		});
	};

	///////////////

	///////////////
	//Put Functions
	public async updateOrg(id: string, body: any): Promise<OrganizationMessage> {
		return new Promise<OrganizationMessage>((resolve, reject) => {
			let org: OrganizationMessage;
			this.web.put(Uri.Organization + id, body)
				.then((data) => org = parseOrg(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(org))
				.catch(error => {
					reject(error);
				});
		});
	}

	public async updateUser(id: string, body: any): Promise<UserMessage> {
		return new Promise<UserMessage>((resolve, reject) => {
			let user: UserMessage;
			this.web.put(Uri.User + id, body)
				.then((data) => user = parseUser(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(user))
				.catch(error => {
					reject(error);
				});
		});
	}

	public async updateLocation(id: string, body: any): Promise<LocationMessage> {
		return new Promise<LocationMessage>((resolve, reject) => {
			let location: LocationMessage;
			this.web.put(Uri.Locations + id, body)
				.then((data) => location = parseLocation(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(location))
				.catch(error => {
					reject(error);
				});
		});
	}

	public async updateFloor(id: string, body: any): Promise<FloorMessage> {
		return new Promise<FloorMessage>((resolve, reject) => {
			let floor: FloorMessage;
			this.web.put(Uri.Floors + id, body)
				.then((data) => floor = parseFloor(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(floor))
				.catch(error => {
					reject(error);
				});
		});
	}

	public async addNewDevice(body: any): Promise<AssetMessage | StationMessage> {
		return new Promise<AssetMessage | StationMessage>((resolve, reject) => {
			let device: AssetMessage | StationMessage;
			this.web.post(Uri.Devices, body)
				.then((data) => device = parseDevice(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(device))
				.catch(error => reject(error));
		});
	}

	public async importJson(body: any): Promise<AssetMessage | StationMessage> {
		return new Promise<AssetMessage | StationMessage>((resolve, reject) => {
			let device: AssetMessage | StationMessage;
			this.web.post(Uri.Devices+"json_import", body)
				.then(() => this.getFullModel())
				.then(() => resolve(device))
				.catch(error => reject(error));
		});
	}

	public async updateDevice(id: string, body: any): Promise<AssetMessage | StationMessage> {
		return new Promise<AssetMessage | StationMessage>((resolve, reject) => {
			let device: AssetMessage | StationMessage;
			this.web.put(Uri.Devices + id, body)
				.then((data) => device = parseDevice(data.resp_data))
				.then(() => this.getFullModel())
				.then(() => resolve(device))
				.catch(error => reject(error));
		});
	}

	public async ringAsset(id: string, ring:boolean): Promise<AssetDerivedModel> {
		return new Promise<AssetDerivedModel>((resolve, reject) => {
			this.web.put("devices/ring/" + id, {ring:ring})
				.then((data) => {
					let deviceMessage = parseDevice(data);
					let model = this.graph.setDevice(deviceMessage) as AssetDerivedModel;
					resolve(model);
				})
				.catch(error => reject(error));
		});
	}
	///////////////

	///////////////
	//Put Functions
	public async deleteLocation(id: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.web.delete(Uri.Locations + id, options)
				.then(() => this.getFullModel())
				.then(() => resolve())
				.catch(error => {
					reject(error);
				});
		});
	}

	public async deleteFloor(id: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.web.delete(Uri.Floors + id, options)
				.then(() => this.getFullModel())
				.then(() => resolve())
				.catch(error => {
					reject(error);
				});
		});
	}

	public async deleteDevice(id: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.web.delete(Uri.Devices + id, options)
				.then(() => this.getFullModel())
				.then(() => resolve())
				.catch(error => {
					reject(error);
				});
		});
	}

	public pushDeviceSettings(settings_paylaod) {
		return new Promise((resolve, reject) => {
			this.web.post(Uri.Device_Settings, settings_paylaod).then((res: any) => {
				resolve(res);
			}).catch(error => {
				reject(error);
			});
		});
	}
}
