import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@app/services/navigation.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'admin-super-menu',
  templateUrl: './admin-super-menu.page.html',
  styleUrls: ['./admin-super-menu.page.scss'],
})
export class AdminSuperMenuPage implements OnInit {

  constructor(
    public navService: NavigationService,
    public platform: Platform
  ) { }

  ngOnInit() {
  }

}
