import { Injectable } from '@angular/core';
import { DeviceFactory, PureLocateDevice } from 'src/shared/devices/pureLocate.device';
import { isNullOrUndefined } from 'util';
import { IDevice_State, Device_State } from 'src/shared/protobuf/protobuf/generated_code/ts/generic_settings/generic_settings';
import { BleService } from './ble.service';
import { BLE } from '@ionic-native/ble/ngx';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService extends DeviceFactory {

  constructor(
    private bleService: BleService,
    private ble: BLE
  ) {
    super();
  }
  // TODO :: revisit this with the open_telem options ... this is really messy interface right now
  // should be cleaner and match the state being passed to the server for devices.
  public sendTelemetryCommandV0(deviceID_in: string, type: number, input_string: string): Promise<void> {
    let deviceID = this.formatId(deviceID_in);
    return new Promise<void>((resolve, reject) => {
      if (isNullOrUndefined(input_string)) {
        return resolve();
      }

      let payload = this.createTelemtryPayload(input_string, type);
      this.bleService.connectAndSendTlmtryCommandV0(deviceID, payload)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  private createTelemtryPayload(input_string, type): Buffer {
    var flag = new Uint8Array(1);
    flag[0] = type;

    var data_payload = new Uint8Array(input_string.length + 1);

    for (var i = 0, strLen = input_string.length; i < strLen; i++) {
      data_payload[i] = input_string.charCodeAt(i);
    }

    return Buffer.concat([Buffer.from(flag.buffer), Buffer.from(data_payload)]);
  }

  public async tryWriteDeviceSettings(deviceID_in: string, data: Uint8Array): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let deviceID = this.formatId(deviceID_in);
      this.bleService.connectAndWriteSettings(deviceID, data).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public async commandTelemetryData(device: PureLocateDevice): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!device) reject();
      if (!device.ble_macAddress) reject();

      let deviceUpdate = this.createBaseStationDevice(device);
      deviceUpdate.ble_telemetry_proto = {}

      let deviceID = this.formatId(device.ble_macAddress);

      this.bleService.connectAndWriteTlmtryCommandV1(deviceID).then(() => {
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  }

  public async connectToPureStationV0(deviceID_in: string) {
    let deviceID = this.formatId(deviceID_in);

    return new Promise<void>((resolve, reject) => {
      this.bleService.tryConncetion(deviceID).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  connectToPureStationV1(station: PureLocateDevice): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.ble.connect(station.ble_uuid).subscribe(
        (ble_device) => {
          this.configurePureStation(station)
            .then(() => resolve())
            .catch((err) => reject(err))
        },
        (err) => {
          reject(err)
        }
      );
    });
  }

  configurePureStation(station: PureLocateDevice): Promise<void> {
    return new Promise((resolve, reject) => {
      let data = new Uint8Array(1);
      data[0] = 1;

      this.adjustMTU(station, 135)
        .then(() => this.ble.write(station.ble_uuid, "ff00", "c000", data.buffer))
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  listenDeviceStatus(device: PureLocateDevice) {
    this.ble.startNotification(device.ble_uuid, "FF00", "D000").subscribe( //Battery Level
      data => this.onDeviceStatusChange(device, data),
      (err) => { }
    );
  }

  private adjustMTU(station: PureLocateDevice, size: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.ble.requestMtu(station.ble_uuid, size).then(() => {
          resolve(true)
        },
          (error) => {
            reject(error)
          });
      }, 100);
    });
  }

  private onDeviceStatusChange(scannedDevice, data: any) {
    try {
      let decoded: IDevice_State = Device_State.toObject(Device_State.decode(new Uint8Array(data))) as IDevice_State;
      let deviceUpdate = this.createBaseStationDevice(scannedDevice);
      this.setDeviceNameWithRssi(deviceUpdate, scannedDevice);
      deviceUpdate.ble_device_state_proto = decoded;
    }
    catch (err) {
      console.log(" :: Decode Failed : More Data? : ", err);
    }
  }

  private formatId(id: string): string {
    return id.replace(/(..?)/g, '$1:').slice(0, -1).toUpperCase();
  }

}

