import { Injectable } from '@angular/core';
import { OrganizationMessage } from '@protos';

@Injectable({
  providedIn: 'root'
})
export class OrganizationDerivedModel extends OrganizationMessage {

  public MQTT_Path = "mqtts/";
  
  constructor(message: OrganizationMessage) { 
    super();
    Object.assign(this, message);
  }

}

