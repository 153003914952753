import { Component, ElementRef, ViewChild } from '@angular/core';
import { MapBuilder } from '@app/_models/map/map-builder';
import { DataService } from '@app/services';
import { DrawService } from '@app/services/map/draw.service';
import { Platform, AlertController } from '@ionic/angular';
import { of } from 'rxjs';
import { ZoomLevel } from '@app/_models/map/map-constants';

@Component({
  selector: 'map-place-location',
  templateUrl: './map-place-location.component.html',
  styleUrls: ['../map.component.scss'],
})
export class MapPlaceLocationComponent extends MapBuilder {
  @ViewChild('map', { static: false }) mapContainer: ElementRef;

  constructor(
    public dataService: DataService,
    public drawService: DrawService,
    public platform: Platform,
    public alertController: AlertController,
  ) {
    super();
  }

  public initializeMap(mapId: string) {
    this.generateMap(mapId);
    this.mapObs = of(this.map);

    this.map.on('zoomend', () => {
      this.draw();
    });

    if (this.initial_zoom) {
      this.setViewFromPoint(this.initial_zoom, ZoomLevel.stationsZoomLevel);
    }
  }

  ngAfterViewInit() {
    this.initializeMap(this.mapContainer.nativeElement);
    this.draw();
  }

  private draw() {
    this.clear();

    this.drawService.drawPlacement(this.map, this.layerGroups);
  }

  clear() {
    this.layerGroups.clearLayers();
  }

}