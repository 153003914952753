import { UserMessage } from '@protos';

export class User{//Do not extend

    public password: string = undefined;
    public token: string = undefined;

    public organization: string;
    public username: string;
    public email: string;
    public id: string;
    public created: string;
    public modified: string;
    public role: number;

    constructor(userMessage: UserMessage){
        Object.assign(this, userMessage);
        this.organization = (userMessage.getOrganization());
        this.username = (userMessage.getUsername());
        this.email = (userMessage.getEmail());
        this.id = (userMessage.getId());
        this.created = (userMessage.getCreated());
        this.modified = (userMessage.getModified());
        this.role = (userMessage.getRole());
    }
}