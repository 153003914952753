export enum colors {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    light = 'light',
    medium = 'medium',
    dark = 'dark',
}