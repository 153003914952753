import { Injectable } from '@angular/core';
import { PureLocateDevice } from 'src/shared/devices/pureLocate.device';
import * as _ from "lodash";
import { Storage } from '@ionic/storage';
import { ConnectionService } from './connection.service';
import { SSID } from './onboard.service';
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/ngx';

const STORAGE_WIFI_CREDENETIALS = 'WIFI_CREDENTIALS';

@Injectable({
  providedIn: 'root'
})
export class WifiService {

  constructor(
    private wifiWizard2: WifiWizard2,
    private storage: Storage,
    private connection: ConnectionService
  ) { }

  public setWifiCredentials(ssid): any[] {
    let supportedSSID = []

    ssid.fromStorage = true;
    var index = _.findIndex(supportedSSID, { SSID: ssid.SSID });
    supportedSSID.splice(index, 1, ssid);
    console.log(" :: SSID's to save the filter : ", supportedSSID.filter((ssid_filter) => ssid_filter.fromStorage));
    this.storage.set(STORAGE_WIFI_CREDENETIALS, JSON.stringify(supportedSSID.filter((ssid_filter) => ssid_filter.fromStorage)));

    return [];
  }

  public async scanWifi(device: PureLocateDevice): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.connection.commandTelemetryData(device)
        .then(() => this.wifiWizardScanForSSIDs())
        .then(supportedSSIDs => {
          supportedSSIDs = supportedSSIDs.concat(this.loadWiFiFromBaseStation(device));
          resolve(supportedSSIDs);
        }).catch((err) => {
          reject([]);
        });
    });
  }

  public deleteLocalWifi() {
    this.storage.remove(STORAGE_WIFI_CREDENETIALS);
  }

  public async wifiWizardScanForSSIDs(): Promise<any[]> {
    console.log(" :: Calling to scan for wifi ::");
    let supportedSSIDs = [];
    return new Promise<any[]>((resolve, reject) => {
      this.storage.get("WIFI_CREDENTIALS")
        .then(data => (data != null) ? supportedSSIDs = JSON.parse(data) : supportedSSIDs = [])
        .then(() => this.wifiWizard2.scan())
        .then((ssidList) => {
          supportedSSIDs = supportedSSIDs.concat(this.getSupportedSSIDList(ssidList));
          resolve(supportedSSIDs);
        }).catch((err) => {
          reject(supportedSSIDs);
        });
    });
  }

  private getSupportedSSIDList(ssidList): any[] {
    // console.log(" :: SSID list : ", ssidList);

    // https://developer.android.com/reference/android/net/wifi/ScanResult
    // GET ALL OF THE SSID's for 2.4 GHZ (supported)
    // Get us only the 2.4 list   -   https://en.wikipedia.org/wiki/List_of_WLAN_channels
    // GET ALL OF THE SSID's for 5 ghz (unsupported)
    // tempAllssid = _.orderBy(ssidList.filter((ssid) => (ssid.frequency > 2500) ? true : false), ['level', 'SSID'], ['desc', 'desc'])
    //   .map((ssid) => ssid); // Make sure these all have falues for expected field
    //let unsupportedSSIDs = [];
    // for (let index = 0; index < tempAllssid.length; index++) {
    //   if (_.find(unsupportedSSIDs, { SSID: tempAllssid[index].SSID }) == undefined) {
    //     unsupportedSSIDs.push(tempAllssid[index]);
    //   }
    // }

    var tempAllssid = _.orderBy(ssidList.filter((ssid) => (ssid.frequency > 0) ? true : false), ['level', 'SSID'], ['desc', 'desc'])
      .map((ssid) => ssid); // Make sure these all have falues for expected field

    // Merge the scan with the loaded from storage
    // Also removes the duplicates from our scan (the scan returns ALL the bssid's in area :: Causes problems with places with lots of AP's)
    let supportedSSIDs = [];
    for (let index = 0; index < tempAllssid.length; index++) {
      if (_.find(supportedSSIDs, { SSID: tempAllssid[index].SSID }) == undefined) {

        var newSSID: SSID = tempAllssid[index];
        newSSID.secure_level = 0;

        if (newSSID.SSID.includes("PL-") || newSSID.SSID.includes("PS-")) {
          console.log(" :: Skiping SSID : ", newSSID);
          continue;
        }
        if (tempAllssid[index].capabilities.includes("WEP")) {
          newSSID.secure_level = 1;
        }
        if (tempAllssid[index].capabilities.includes("WPA")) {
          newSSID.secure_level = 2;
        }
        // https://android.googlesource.com/platform/packages/apps/Settings/+/donut-release/src/com/android/settings/wifi/AccessPointState.java#348
        // /** String present in capabilities if the scan result is enterprise secured */
        // private static final String ENTERPRISE_CAPABILITY = "-EAP-";
        if (tempAllssid[index].capabilities.includes("-EAP-")) {
          newSSID.secure_level = 5;
        }
        supportedSSIDs.push(tempAllssid[index]);
      }
    }

    return supportedSSIDs;
  }

  private loadWiFiFromBaseStation(device: PureLocateDevice): any[] {
    let supportedSSID = []

    if (device.proto_version != 1) return supportedSSID; //
    if (!device.ble_telemetry_proto) return supportedSSID;
    if (!device.ble_telemetry_proto.macAddress) return supportedSSID;

    device.ble_telemetry_proto.wifiRecord.forEach(record => {
      if (_.find(supportedSSID, { SSID: record.ssid }) == undefined) {
        var newSSID: SSID = {
          SSID: record.ssid,
          level: record.signalStrength,
          // https://docs.espressif.com/projects/esp-idf/en/latest/api-reference/network/esp_wifi.html#_CPPv416wifi_auth_mode_t
          secure_level: record.authMode,
          fromStorage: false,
        }
        if (!newSSID.SSID.includes("PL-") || !newSSID.SSID.includes("PS-")) {
        } else {
          supportedSSID = supportedSSID.concat(newSSID);
        }
      }
    });

    return supportedSSID;
  }




}

