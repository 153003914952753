import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AdminSuperMenuPageRoutingModule } from './admin-super-menu-routing.module';

import { AdminSuperMenuPage } from './admin-super-menu.page';
import { AngularMaterialModule } from '@app/modules';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AngularMaterialModule,
    AdminSuperMenuPageRoutingModule
  ],
  declarations: [AdminSuperMenuPage]
})
export class AdminSuperMenuPageModule {}
