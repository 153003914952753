import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@app/services/navigation.service';
import { ModalController, Platform } from '@ionic/angular';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
// import { WebIntent } from '@ionic-native/web-intent/ngx';
// import { AppLauncher, AppLauncherOptions } from '@ionic-native/app-launcher/ngx';

const app_package = "com.purelocate.ble_ar"

@Component({
  selector: 'app-ble-ar',
  templateUrl: './ble-ar.page.html',
  styleUrls: ['./ble-ar.page.scss'],
})
export class BleArPage {
  public isAppValid = true;

  private macAddress = '';

  constructor(
    public modalController: ModalController,
    public navService: NavigationService,
    public router: Router,
    public route: ActivatedRoute,
    public platform: Platform,
    public appAvailability: AppAvailability,
    // public webIntent: WebIntent,
    // private appLauncher: AppLauncher,
  ) {
    let app;
    if (this.platform.is('ios')) {
      app = 'ble_ar://';
    } else if (this.platform.is('android')) {
      app = app_package;
    }

    // this.appAvailability.check(app)
    //   .then(() => this.isAppValid = true)
    //   .catch(() => {
    //     this.isAppValid = false;
    //     console.log(app + ' is NOT available')
    //   });

  }

  ngOnInit() {
    this.macAddress = `${this.macAddress}`;
    // const options: AppLauncherOptions = {
    //   //uri: this.macAddress
    // }

    // if (this.platform.is('ios')) {
    //   options.uri = 'ble_ar://'
    // } else {
    //   options.packageName = app_package
    // }


    // this.appLauncher.canLaunch(options)
    //   // .then(() => this.appLauncher.launch(options))
    //   .then(() => this.ble_ar())
    //   .catch((error: any) => {
    //     console.log(error)
    //   });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async ble_ar() {
    // const options = {
    //   action: "com.example.helloworld.VIEW_AR",
    //   extras: {
    //     strId: this.toBleUuid(this.macAddress)
    //   },
    // };

    // this.webIntent.startActivity(options)
    //   .then((success) => {
    //     console.log(success);
    //   }).catch((err) => {
    //     console.log(err);
    //   });
  }

  toBleUuid(macAddress: string): string{
    let bleMac = macAddress.toUpperCase().replace(/(..)/g, '$1:').slice(0,-1);
    return bleMac;
  }

}


