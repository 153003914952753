import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visgraph',
  templateUrl: './visgraph.component.html',
  styleUrls: ['./visgraph.component.scss'],
})
export class VisgraphComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
