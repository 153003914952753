import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'location-table',
  templateUrl: './location-table.component.html',
  styleUrls: ['./location-table.component.scss'],
})
export class LocationTableComponent implements OnInit {

  @Input() locationIDs: string[] = [];

  constructor() { }

  public ngOnInit() {}

}
