import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Component, ViewChild } from '@angular/core';
import { AuthenticationService } from '@services'
import { Role } from './_models';
import { MenuController, Platform } from '@ionic/angular';
import { BLE } from '@ionic-native/ble/ngx';
import { MatAccordion } from '@angular/material';
import { NavigationService } from './services/navigation.service';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;

    get isUser() {
        if (!this.auth.userSubject.getValue()) return false;
        return this.auth.userSubject.value.role >= Role.ReadOnly;
    }

    get isAdmin() {
        if (!this.auth.userSubject.getValue()) return false;
        return this.auth.userSubject.value.role >= Role.Admin;
    }

    public sidemenu_pages = {
        title: 'User', url: null, icon: 'person',
        user_pages: [
            //{ title_key: 'VISGRAPH_MENU', title: 'System', component: 'visgraphpage', icon: 'git-merge', url: 'user/visgraph' },
            { title_key: 'DASHBOARD_MENU', title: 'Dashboard', component: 'dashboard', icon: 'settings', url: 'user/dashboard' },
            { title_key: 'MAP_MENU', title: 'Map', component: 'map', icon: 'globe', url: 'user/map' },
            { title_key: 'LOCATIONS_MENU', title: 'Locations', component: 'locations', icon: 'business', url: 'user/locations' },
            { title_key: 'STATIONS_MENU', title: 'Stations', component: 'stations', image:"./../assets/icons/outlet.svg", url: 'user/stations' },
            { title_key: 'LOCATE_MENU', title: 'Assets', component: 'locate', icon: 'locate', url: 'user/locate' },
        ],
        admin: {
            title_key: 'ADMIN_MENU', title: 'Admin', component: 'admin', icon: 'settings', url: 'admin/menu'
        }
    };

    private mobile_pages = [
        { title_key: 'Tracking', title: 'Tracking', component: 'ble-tracking', icon: 'bluetooth', url: 'user/ble-tracking' },
        { title_key: 'Onboard', title: 'Onboard', component: 'onboard', icon: 'git-merge', url: 'user/onboard' },
    ];


    constructor(
        private menu: MenuController,
        private platform: Platform,
        private statusBar: StatusBar,
        private auth: AuthenticationService,
        private ble: BLE,
        private navService: NavigationService

    ) {
        this.platform.ready().then(() => {
            this.statusBar.hide();
            this.init_cordovaSettings();
        });
    }

    public async redirectPage(pageUrl) {
        if (pageUrl === '/logout') {
            this.logout();
        } else {
            this.navService.navigateAsRoot(pageUrl);
        }

        this.menu.toggle('slidingMenu');
    }

    public logout() {
        this.auth.logout();
    }

    private init_cordovaSettings() {
        if (this.platform.is('cordova')) {
            this.ble.enable();

            this.mobile_pages.forEach(mobile_page => {
                this.sidemenu_pages.user_pages.unshift(mobile_page)
            });

            this.statusBar.styleDefault();
            if (this.platform.is('android')) {
                this.statusBar.overlaysWebView(false);
                this.statusBar.backgroundColorByHexString('#000000');
            }
        }
    }


}

