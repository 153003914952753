import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { FloorDerivedModel } from 'src/app/services/models/floor-derived-model.service';
import { NavigationService } from '@app/services/navigation.service';
import { LocationDerivedModel, GraphService } from '@app/services';

@Component({
	selector: 'floor-table',
	templateUrl: './floor-table.component.html',
	styleUrls: ['./floor-table.component.scss'],
})
export class FloorTableComponent implements OnInit {
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@Input() location: LocationDerivedModel = undefined;

	public displayData: MatTableDataSource<FloorDerivedModel>;
	public floorColumns = ['name'];
	public displayedColumns = ['name', 'station_count', 'asset_count'];
	public dataSource: MatTableDataSource<string>;
	public isLoadingResults = true;
	public isRateLimitReached = false;

	constructor(
		public navService: NavigationService,
		public dataService: DataService,
		public graphService: GraphService,
	) { }

	public ngOnInit() {
		this.location = this.graphService.getLocation(this.location.getId());
		this.dataService.serverDataCounts.floor_count.subscribe(() => {
			this.getArray();
		});

		this.getArray();
	}

	ionViewWillLeave() {

	}

	private async getArray(): Promise<FloorDerivedModel[]> {
		if (!this.location) return;

		this.isLoadingResults = true;

		let floors: FloorDerivedModel[] = [];
		this.location.getProtoChildrenList().forEach(key => {
			let floor = this.graphService.getFloor(key);
			if (floor) floors.push(floor);
		});

		this.isLoadingResults = false;
		this.displayData = new MatTableDataSource<FloorDerivedModel>(floors);
	}
}
