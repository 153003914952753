import { Component, ElementRef, ViewChild } from '@angular/core';
import { MapBuilder } from '@app/_models/map/map-builder';
import { DataService, AssetDerivedModel, PlatformInfoService } from '@app/services';
import { DrawService } from '@app/services/map/draw.service';
import { Platform, AlertController } from '@ionic/angular';
import { of } from 'rxjs';
import * as leaflet from 'leaflet';
import { ZoomLevel } from '@app/_models/map/map-constants';

@Component({
  selector: 'map-single-asset',
  templateUrl: './map-single-asset.component.html',
  styleUrls: ['../map.component.scss'],
})
export class MapSingleAssetComponent extends MapBuilder {
  @ViewChild('map', { static: false }) mapContainer: ElementRef;


  constructor(
    public dataService: DataService,
    public drawService: DrawService,
    public platform: Platform,
    public alertController: AlertController,
    public platformInfoService:PlatformInfoService,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.initializeMap(this.mapContainer.nativeElement);
  }

  ionViewDidEnter() {

  }

  public initializeMap(mapId: string) {
    this.generateMap(mapId);
    this.mapObs = of(this.map);

    this.map.on('zoomend', () => {
      this.draw();
    });

    if (this.initial_zoom) {
      this.setViewFromPoint(this.initial_zoom, ZoomLevel.stationsZoomLevel);
    }

    this.dataService.serverDataCounts.location_count.subscribe(() => {
      this.draw();
    });

    if (this.assetModel) {
      this.setViewFromPoint(this.assetModel.getLatLong());
    }
    else {
      this.platformInfoService.giveCurrentPosition(false).then(pos => {
        console.log("Position from service: ", pos);
      }).catch(err => {
        console.error("Position Error: ", err);
      });
    }

    this.draw();

  }

  private draw() {
    this.clear();

    this.drawService.drawFloor(this.layerGroups, this.floorModel);

    if(this.assetModel.calculated_position){
      if(this.assetModel.calculated_position.getX() && this.assetModel.calculated_position.getY()){
        let marker = this.drawService.drawAsset(this.layerGroups, this.assetModel)
    
        this.configureSelectedAsset(marker, this.assetModel);
    
        this.map.on('resize', () => {
          marker.openPopup();
        });
      }
    }

  }

  private clear() {
    this.layerGroups.clearLayers();
  }

  private configureSelectedAsset(current_marker: leaflet.CircleMarker, asset: AssetDerivedModel) {
    let calculated_location = asset.getLatLong();
    current_marker.setLatLng([calculated_location.getX(), calculated_location.getY()]);

    current_marker.setRadius(asset.accuracy);
    current_marker.setStyle({ fillOpacity: 0.2, opacity: 1, })
  }

}