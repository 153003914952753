import * as leaflet from 'leaflet';

export class GraphLayerGroups {

    private _locationLayerGroup: leaflet.LayerGroup<any> = leaflet.layerGroup();
    private _floorLayerGroup: leaflet.LayerGroup<any> = leaflet.layerGroup();
    private _floorJsonLayerGroup: leaflet.LayerGroup<any> = leaflet.layerGroup();
    private _stationLayerGroup: leaflet.LayerGroup<any> = leaflet.layerGroup();
    private _assetLayerGroup: leaflet.LayerGroup<any> = leaflet.layerGroup();
    private _placementLayerGroup: leaflet.LayerGroup<any> = leaflet.layerGroup();

    generateOverlay(): leaflet.Control.LayersObject {
        let overlayMaps = {
            "Locations": this.locationLayerGroup,
            "FloorPlans": this.floorLayerGroup,
            "Stations": this.stationLayerGroup,
            "Assets": this.assetLayerGroup,
            "Drawings": this.floorJsonLayerGroup,
        };

        return overlayMaps;
    }

    addToMap(map: leaflet.Map) {
        this._locationLayerGroup.addTo(map);
        this._floorLayerGroup.addTo(map);
        this._floorJsonLayerGroup.addTo(map);
        this._stationLayerGroup.addTo(map);
        this._assetLayerGroup.addTo(map);
    }

    clearLayers() {
        this._floorJsonLayerGroup.clearLayers();
        this._locationLayerGroup.clearLayers();
        this._floorLayerGroup.clearLayers();
        this._stationLayerGroup.clearLayers();
        this._assetLayerGroup.clearLayers();
    }

    get locationLayerGroup() {
        return this._locationLayerGroup;
    }
    set locationLayerGroup(layers: leaflet.LayerGroup<any>) {
        this._locationLayerGroup.clearLayers();
        this._locationLayerGroup.addLayer(layers);
    }

    get floorLayerGroup() {
        return this._floorLayerGroup;
    }
    set floorLayerGroup(layers: leaflet.LayerGroup<any>) {
        this._floorLayerGroup.clearLayers();
        this._floorLayerGroup.addLayer(layers);
    }

    get floorJsonLayerGroup() {
        return this._floorJsonLayerGroup;
    }
    set floorJsonLayerGroup(layers: leaflet.LayerGroup<any>) {
        this._floorJsonLayerGroup.clearLayers();
        this._floorJsonLayerGroup.addLayer(layers);
    }

    get stationLayerGroup() {
        return this._stationLayerGroup;
    }
    set stationLayerGroup(layers: leaflet.LayerGroup<any>) {
        this._stationLayerGroup.clearLayers();
        this._stationLayerGroup.addLayer(layers);
    }

    get assetLayerGroup() {
        return this._assetLayerGroup;
    }
    set assetLayerGroup(layers: leaflet.LayerGroup<any>) {
        this._assetLayerGroup.clearLayers();
        this._assetLayerGroup.addLayer(layers);
    }

    get placementLayerGroup() {
        return this._placementLayerGroup;
    }
    set placementLayerGroup(layers: leaflet.LayerGroup<any>) {
        this._placementLayerGroup.clearLayers();
        this._placementLayerGroup.addLayer(layers);
    }

}
