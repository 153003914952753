import { Injectable } from '@angular/core';
import { OrganizationDerivedModel } from './models/organization-derived-model.service';
import { LocationDerivedModel } from './models/location-derived-model.service';
import { FloorDerivedModel } from './models/floor-derived-model.service';
import { StationDerivedModel } from './models/station-derived-model.service';
import { AssetDerivedModel } from './models/asset-derived-model.service';
import { GraphMessage, OrganizationMessage, LocationMessage, FloorMessage, StationMessage, AssetMessage } from 'src/shared/protos/generated/data_pb';

export enum Services {
	Tag = 'TAG',
	Station = 'BASE_STATION',
	Mobile = 'MOBILE_STATION',
}

export type Entites_t = OrganizationDerivedModel | LocationDerivedModel | FloorDerivedModel | StationDerivedModel | AssetDerivedModel;

export interface IDs {
	[key: string]: string;
}

export interface Entities {
	[key: string]: Entites_t;
}

export interface Graph {
	[key: string]: Entities,
}

export interface Organizations {
	[key: string]: OrganizationDerivedModel,
}

export interface Locations {
	[key: string]: LocationDerivedModel,
}
export interface Floors {
	[key: string]: FloorDerivedModel,
}

export interface Stations {
	[key: string]: StationDerivedModel,
}

export interface Assets {
	[key: string]: AssetDerivedModel,
}

export interface Unclaimed_Stations {
	[key: string]: StationDerivedModel,
}

export interface Unclaimed_Assets {
	[key: string]: AssetDerivedModel,
}

// From a proto 
export interface GraphDerivedMode extends GraphMessage {

	// other stuff here if you need that doesnt make sense to keep in proto
	node: number,
	logs: any,
	systemSettings: any,
	systemStatus: any,
	// ...
}

export const INITIAL_GRAPH: Graph = {

	logs: {},
	systemSettings: {},
	systemStatus: {},
	children: {},
	parent: null


}

export const INITIAL_ORGANIZATION: Organizations = {

}

export const INITIAL_LOCATION: Locations = {

}

export const INITIAL_FLOOR: Floors = {

}

export const INITIAL_STATION: Stations = {

}

export const INITIAL_ASSET: Assets = {

}

export const INITIAL_UNCLAIMED_STATION: Stations = {

}

export const INITIAL_UNCLAIMED_ASSET: Assets = {

}


export const INITIAL_ROW: Rows = {
	0: {},
	1: {},
	2: {},
	3: {},
	4: {},
	5: {},
	6: {},
	7: {},
}

// Holds all Rows
export interface Rows {
	0: Graph,
	1: Organizations,
	2: Locations,
	3: Floors,
	4: Stations,
	5: Assets,
	6: Unclaimed_Stations,
	7: Unclaimed_Assets
}

export enum RowDefinitions {
	ROOT = 0,
	ORGANIZATIONS,
	LOCATIONS,
	FLOORS,
	STATIONS,
	ASSETS,
	UNCLAIMED_STATIONS,
	UNCLAIMED_ASSETS,
	MAX_DEPTH
}

@Injectable({
	providedIn: 'root'
})
export class GraphService {

	public entities: Entities = {};
	public graph: Graph = INITIAL_GRAPH;
	public rows: Rows = INITIAL_ROW;

	constructor(
	) {
		// console.log(this.rows);
	}

	/////////////
	// get entity
	public getOrganization(id: string): OrganizationDerivedModel {
		if (this.rows[RowDefinitions.ORGANIZATIONS][id]) return (this.rows[RowDefinitions.ORGANIZATIONS][id]);
	}

	public getLocation(id: string): LocationDerivedModel {
		if (this.rows[RowDefinitions.LOCATIONS][id]) return (this.rows[RowDefinitions.LOCATIONS][id]);
	}

	public getFloor(id: string): FloorDerivedModel {
		if (this.rows[RowDefinitions.FLOORS][id]) return (this.rows[RowDefinitions.FLOORS][id]);
	}

	public getStation(id: string): StationDerivedModel {
		if (this.rows[RowDefinitions.STATIONS][id]) return (this.rows[RowDefinitions.STATIONS][id]);
		if (this.rows[RowDefinitions.UNCLAIMED_STATIONS][id]) return (this.rows[RowDefinitions.UNCLAIMED_STATIONS][id]);
	}

	public getAsset(id: string): AssetDerivedModel {
		if (this.rows[RowDefinitions.ASSETS][id]) return (this.rows[RowDefinitions.ASSETS][id]);
		if (this.rows[RowDefinitions.UNCLAIMED_ASSETS][id]) return (this.rows[RowDefinitions.UNCLAIMED_ASSETS][id]);
	}

	public getUnclaimedStation(id: string): StationDerivedModel {
		if (this.rows[RowDefinitions.UNCLAIMED_STATIONS][id]) return (this.rows[RowDefinitions.UNCLAIMED_STATIONS][id]);
	}

	public getUclaimedAsset(id: string): AssetDerivedModel {
		if (this.rows[RowDefinitions.UNCLAIMED_ASSETS][id]) return (this.rows[RowDefinitions.UNCLAIMED_ASSETS][id]);
	}

	public async getEntity(type: RowDefinitions, id: string) {
		switch (type) {
			case (RowDefinitions.LOCATIONS):
				return this.rows[RowDefinitions.LOCATIONS][id];
			case (RowDefinitions.FLOORS):
				return this.rows[RowDefinitions.FLOORS][id];
			case (RowDefinitions.STATIONS):
				return this.rows[RowDefinitions.STATIONS][id];
			case (RowDefinitions.ASSETS):
				return this.rows[RowDefinitions.ASSETS][id];
			default:
				break;
		}
	}
	/////////////

	/////////////
	// set entity
	setOrganization(message: OrganizationMessage): OrganizationDerivedModel {
		let model = new OrganizationDerivedModel(message);
		this.rows[RowDefinitions.ORGANIZATIONS][model.getId()] = model;
		return model;
	}

	setLocation(message: LocationMessage): LocationDerivedModel {
		let model = new LocationDerivedModel(message);
		this.rows[RowDefinitions.LOCATIONS][model.getId()] = model;
		return model;
	}

	setFloor(message: FloorMessage): FloorDerivedModel {
		let model = new FloorDerivedModel(message);
		this.rows[RowDefinitions.FLOORS][model.getId()] = model;
		return model;
	}

	setStation(message: StationMessage): StationDerivedModel {
		if (message.getProtoParent() == undefined) { this.setUnclaimedStation(message); return; }
		if (this.getFloor(message.getProtoParent()) == undefined) { this.setUnclaimedStation(message); return; }

		let model = new StationDerivedModel(message);
		this.rows[RowDefinitions.STATIONS][model.getId()] = model;

		return model;
	}

	setDevice(message: AssetMessage | StationMessage): AssetDerivedModel | StationDerivedModel {
		if (!message) return;

		let model: AssetDerivedModel | StationDerivedModel = undefined;
		if (message.getService() == Services.Tag) {
			model = this.setAsset(message as AssetMessage);

		} else if (message.getService() == Services.Station) {
			model = this.setStation(message as StationMessage);
		}

		return model;
	}

	setAsset(message: AssetMessage): AssetDerivedModel {
		if (message.getProtoParentsList().length == 0) {
			return this.setUnclaimedAsset(message);
		}
		let model = new AssetDerivedModel(message);
		this.rows[RowDefinitions.ASSETS][model.getId()] = model;

		return model;
	}

	setUnclaimedStation(message: StationMessage): StationDerivedModel {
		let model = new StationDerivedModel(message);
		this.rows[RowDefinitions.UNCLAIMED_STATIONS][model.getId()] = model;
		return model;
	}

	setUnclaimedAsset(message: AssetMessage): AssetDerivedModel {
		let model = new AssetDerivedModel(message);
		this.rows[RowDefinitions.UNCLAIMED_ASSETS][model.getId()] = model;
		return model;
	}
	/////////////

	/////////////
	// delete entity
	removeLocation(id: string) {
		delete this.rows[RowDefinitions.LOCATIONS][id];
	}

	removeFloor(id: string) {
		delete this.rows[RowDefinitions.FLOORS][id];
	}

	removeStation(id: string) {
		delete this.rows[RowDefinitions.STATIONS][id];
	}

	removeAsset(id: string) {
		delete this.rows[RowDefinitions.ASSETS][id];
	}
	/////////////

	/////////////
	// Row Managment
	public clearData() {
		this.graph = {};
		this.rows = [{}, {}, {}, {}, {}, {}, {}, {}];
	}

	public getRow(row: RowDefinitions): (Assets | Stations | Floors | Locations) {
		return this.rows[row];
	}
	/////////////


	/////////////
	//Temp functions

	setAllAssetParents() {
		let stations = this.getRow(RowDefinitions.STATIONS) as Stations;
		Object.keys(stations).forEach(key => {
			stations[key].setProtoChildrenList([]);
		});

		let assets = this.getRow(RowDefinitions.ASSETS) as Assets;
		Object.keys(assets).forEach(key => {
			let asset = assets[key] as AssetDerivedModel;
			if (asset.getData().getCalculatedPosition() != undefined) {
				let station = this.getStation(asset.getData().getCalculatedPosition().getId());
				if (station) {
					station.addProtoChildren(key);
				}
			}
		});
	}

	setAllStationParents() {
		let assets = this.getRow(RowDefinitions.ASSETS) as Assets;
		Object.keys(assets).forEach(key => {
			assets[key].setProtoChildrenList([]);
		});

		let stations = this.getRow(RowDefinitions.STATIONS) as Stations;
		Object.keys(stations).forEach(key => {
			let station = stations[key] as StationDerivedModel;
			let floor = this.getFloor(station.getProtoParent());
			if (floor) {
				floor.addProtoChildren(key);
			}
		});
	}

	setAllFloorParents() {

	}

	setLocationParents(location: LocationDerivedModel) {
		let organization = this.getOrganization(location.getProtoParent());
		if (organization) {
			organization.addProtoChildren(location.getId());
		}
	}

	setFloorParents(floor: FloorDerivedModel) {
		let location = this.getLocation(floor.getProtoParent());
		if (location) {
			location.addProtoChildren(floor.getId());
		}
	}

	setStationParents(station: StationDerivedModel) {
		let floor = this.getFloor(station.getProtoParent());
		if (floor) {
			floor.addProtoChildren(station.getId());
		}
	}

	setAssetParents(asset: AssetDerivedModel) {
		if (asset.getData().getCalculatedPosition() != undefined) {
			let station = this.getStation(asset.getData().getCalculatedPosition().getId());
			if (station) {
				station.addProtoChildren(asset.getId());
			}
		}
	}

	////////////

}
