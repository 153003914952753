import { Injectable } from '@angular/core';
import { LocationDerivedModel } from './models/location-derived-model.service';
import { FloorDerivedModel } from './models/floor-derived-model.service';
import { StationDerivedModel } from './models/station-derived-model.service';
import { AssetDerivedModel } from './models/asset-derived-model.service';
import { BehaviorSubject } from 'rxjs';
import { GraphService, Assets, RowDefinitions, Stations, Locations, Floors } from './graph.service';

const SearchLikeness = 0.4;

export enum Filter_Status {
	All = 0,
	Online,
	Offline,
	Battery,
	Aux_on,
	Aux_off,
}

export enum Sort_State {
	Alphabetical = 0,
	Matching,
	Similarity,
	Rssi,
	Battery
}

export interface FilterOptions {
	term?: string,
	status?: Filter_Status,
	claimed?: boolean,
	unclaimed?: boolean,
}

export const INTIAL_FILTEROPTIONS: FilterOptions = {
	term: '',
	status: 0,
	claimed: true,
	unclaimed: false,
}


interface ServerDataCount {
	location_count: BehaviorSubject<number>;
	floor_count: BehaviorSubject<number>;

	station_total_count: BehaviorSubject<number>;
	station_online_count: BehaviorSubject<number>;
	station_offline_count: BehaviorSubject<number>;

	asset_total_count: BehaviorSubject<number>;
	asset_online_count: BehaviorSubject<number>;
	asset_offline_count: BehaviorSubject<number>;
}

@Injectable({
	providedIn: 'root'
})
export class DataService {

	public serverDataCounts = <ServerDataCount>{};

	constructor(
		private graph: GraphService,
	) {

		this.serverDataCounts.location_count = new BehaviorSubject<number>(0);
		this.serverDataCounts.floor_count = new BehaviorSubject<number>(0);

		this.serverDataCounts.station_total_count = new BehaviorSubject<number>(0);
		this.serverDataCounts.station_online_count = new BehaviorSubject<number>(0);
		this.serverDataCounts.station_offline_count = new BehaviorSubject<number>(0);

		this.serverDataCounts.asset_total_count = new BehaviorSubject<number>(0);
		this.serverDataCounts.asset_online_count = new BehaviorSubject<number>(0);
		this.serverDataCounts.asset_offline_count = new BehaviorSubject<number>(0);

		// this.generateFakeFullOrganization(5, 3, 3, 5);
	}

	/////////////
	// get with filter
	public async filterLocations(options?: FilterOptions): Promise<LocationDerivedModel[]> {
		return new Promise<LocationDerivedModel[]>((resolve, reject) => {
			let pageData: LocationDerivedModel[] = [];
			let locations: Locations = this.graph.getRow(RowDefinitions.LOCATIONS) as Locations;

			let keys = Object.keys(locations);
			if (options) {
				if (options.term != '') {
					keys = keys.filter(key => {
						return (locations[key].search_similarity >= SearchLikeness);
					});
				}
			}

			keys.forEach(key => {
				pageData.push(locations[key]);
			});

			resolve(pageData);
		});
	}

	public async filterStations(options?: FilterOptions, unclaimed?: boolean): Promise<StationDerivedModel[]> {
		return new Promise<StationDerivedModel[]>((resolve, reject) => {
			let pageData: StationDerivedModel[] = [];

			let stations: Stations = Object.assign({}, this.graph.getRow(RowDefinitions.STATIONS) as Stations, this.graph.getRow(RowDefinitions.UNCLAIMED_STATIONS) as Stations);

			let keys = Object.keys(stations);
			if (options) {
				if (options.status == Filter_Status.Online) {
					keys = keys.filter(key => {
						return stations[key].isOnline
					});
				}

				if (options.status == Filter_Status.Offline) {
					keys = keys.filter(key => {
						return !stations[key].isOnline
					});
				}

				if (options.term != '') {
					keys = keys.filter(key => {
						return (stations[key].search_similarity >= SearchLikeness);
					});
					keys.sort((a, b) => {
						return stations[b].search_similarity - stations[a].search_similarity;
					})
				}
			}
			keys.forEach(key => {
				pageData.push(stations[key]);
			});
			resolve(pageData);
		});
	}

	public async filterAssets(options: FilterOptions = {}): Promise<AssetDerivedModel[]> {
		return new Promise<AssetDerivedModel[]>((resolve) => {
			let pageData: AssetDerivedModel[] = [];

			let assets: Assets = Object.assign({}, this.graph.getRow(RowDefinitions.ASSETS) as Assets, this.graph.getRow(RowDefinitions.UNCLAIMED_ASSETS) as Assets);

			let keys = Object.keys(assets);

			if(options.term){
				if (options.term != '') {
					keys = keys.filter(key => {
						return (assets[key].search_similarity >= SearchLikeness);
					});
					keys.sort((a, b) => {
						return assets[b].search_similarity - assets[a].search_similarity;
					})
				}
			}

			if(options.status){
				if (options.status == Filter_Status.Online) {
					keys = keys.filter(key => {
						return assets[key].isOnline
					});
				}
				if (options.status == Filter_Status.Offline) {
					keys = keys.filter(key => {
						return !assets[key].isOnline
					});
				}
				if (options.status == Filter_Status.Battery) {
					var batteries = keys.filter(key => {
						return assets[key].has_battery_level
					});

					batteries.sort( (a, b) => {
						return assets[a].getBatteryLevel() - assets[b].getBatteryLevel();
					});

					keys = batteries.concat(keys.filter(key => {
						return !assets[key].has_battery_level
					}));
				}
				if(options.status == Filter_Status.Aux_on){
					keys = keys.filter(key => {
						return assets[key].rssi_is_used;
					}).sort((a, b) => {
						return assets[b].auxState - assets[a].auxState;
					});
				}
				if(options.status == Filter_Status.Aux_off){
					keys = keys.filter(key => {
						return assets[key].rssi_is_used;
					}).sort((a, b) => {
						return assets[a].auxState - assets[b].auxState;
					});
				}
			}

			keys.forEach(key => {
				pageData.push(assets[key]);
			});

			resolve(pageData);
		});
	}

	getAllLocationFloors(location: LocationDerivedModel): Floors {
		if (!location) return;

		let floorKeys = location.getProtoChildrenList();
		let floors: Floors = {};
		floorKeys.forEach(key => {
			floors[key] = this.graph.getFloor(key);
		});

		return floors;
	}

	getAllLocationStations(location: LocationDerivedModel): Stations {
		if (!location) return;

		let floorKeys = location.getProtoChildrenList();
		let stations: Stations = {};
		floorKeys.forEach(key => {
			let floorStations = this.getAllFloorStations(this.graph.getFloor(key));
			stations = Object.assign(stations, floorStations);
		});

		return stations;
	}

	getAllFloorStations(floor: FloorDerivedModel): Stations {
		if (!floor) return {};

		let stations: Stations = {};
		floor.getProtoChildrenList().forEach(floorKey => {
			stations[floorKey] = this.graph.getStation(floorKey)
		});

		return stations
	}

	getAllLocationAssets(location: LocationDerivedModel): Assets {
		if (!location) return {};

		let assets: Assets = {};
		location.getProtoChildrenList().forEach(floorKey => {
			let floor = this.graph.getFloor(floorKey);
			let floorAssets = this.getAllFloorAssets(floor)
			assets = Object.assign(assets, floorAssets);
		});

		return assets;
	}

	getAllFloorAssets(floor: FloorDerivedModel): Assets {
		if (!floor) return {};

		let assets: Assets = {};
		floor.getProtoChildrenList().forEach(stationKey => {
			let station = this.graph.getStation(stationKey);
			let stationAssets = this.getAllStationAssets(station)
			assets = Object.assign(assets, stationAssets);
		});

		return assets;
	}

	getAllStationAssets(station: StationDerivedModel): Assets {
		if (!station) return {};

		let assets: Assets = {}
		let assetKeys = station.getProtoChildrenList();
		assetKeys.forEach(assetKey => {
			assets[assetKey] = this.graph.getAsset(assetKey);
		});

		return assets;
	}

	getStationByMacAddress(macAddress: string) : StationDerivedModel | undefined {
		var foundStation = undefined;
		
		let stations = this.graph.getRow(RowDefinitions.STATIONS) as Stations;
		Object.keys(stations).forEach(key => {
			if (stations[key].getMacaddress() == macAddress) foundStation = stations[key];
		});
		if(foundStation){
			return foundStation;
		}

		let unclaimedStations = this.graph.getRow(RowDefinitions.UNCLAIMED_STATIONS) as Stations;
		Object.keys(unclaimedStations).forEach(key => {
			if (unclaimedStations[key].getMacaddress() == macAddress) foundStation = unclaimedStations[key];
		});

		return foundStation;
	}

	isStationOnboarded(macAddress: string): boolean {
		var isOnboarded = false;

		let stations = this.graph.getRow(RowDefinitions.STATIONS) as Stations;
		Object.keys(stations).forEach(key => {
			if (stations[key].getMacaddress() == macAddress) isOnboarded = true;
		});

		return isOnboarded;
	}

	isAssetOnboarded(macAddress: string): boolean {
		var isOnboarded = false;

		let assets = this.graph.getRow(RowDefinitions.ASSETS) as Assets;
		Object.keys(assets).forEach(key => {
			if (assets[key].getMacaddress() == macAddress) isOnboarded = true;
		});

		return isOnboarded;
	}

	/////////////

	/////////////
	//Helper functions
	//Sorting - workaround for having fullmodel

	//.returns a zero (not found) or 1 (found)
	// Adding a check for MAC address specifically. Since likeness being 1 a "perfect"
	// match we'll add a likeness of one if we hard find a mac found at all w/ the search terms.
	private termIsIncluded(searchTerm, deviceTerm): number {
		var isIncluded = +deviceTerm.toLowerCase().includes(searchTerm.toLowerCase());
		if(isIncluded){

			return 0.99;
		}
		return 0;
	}

	public setLocationSearchSimilarity(searchTerm: string) {
		if (searchTerm == '') return;

		let locations: Locations = this.graph.getRow(RowDefinitions.LOCATIONS) as Locations;
		let keys = Object.keys(locations);

		var similarity = require('similarity');
		keys.forEach(key => {
			let likeness = Math.max(
				similarity(searchTerm, locations[key].getId()),
				similarity(searchTerm, locations[key].getName()),
				this.termIsIncluded(searchTerm, locations[key].getId()),
				this.termIsIncluded(searchTerm, locations[key].getName()),
			);

			locations[key].search_similarity = likeness;
		});
	}

	public setStationSearchSimilarity(searchTerm: string) {
		if (searchTerm == '') return;

		let stations: Stations = Object.assign({}, this.graph.getRow(RowDefinitions.STATIONS) as Stations, this.graph.getRow(RowDefinitions.UNCLAIMED_STATIONS) as Stations);
		let keys = Object.keys(stations);

		var similarity = require('similarity');
		keys.forEach(key => {
			let likeness = Math.max(
				similarity(searchTerm, stations[key].getId()),
				similarity(searchTerm, stations[key].getName()),
				similarity(searchTerm, stations[key].getMacaddress()),
				// Added the custom mac search function
				this.termIsIncluded(searchTerm, stations[key].getId()),
				this.termIsIncluded(searchTerm, stations[key].getName()),
				this.termIsIncluded(searchTerm, stations[key].getMacaddress())
			);

			stations[key].search_similarity = likeness;
		});
	}

	public setAssetSearchSimilarity(searchTerm: string, claimed:boolean=true, unclaimed:boolean=false) {
		if (searchTerm == '') return;

		var assets:Assets = Object.assign({}, this.graph.getRow(RowDefinitions.ASSETS) as Assets,  this.graph.getRow(RowDefinitions.UNCLAIMED_ASSETS) as Assets);

		var similarity = require('similarity');

		Object.keys(assets).forEach(key => {
			let likeness = Math.max(
				similarity(searchTerm, assets[key].getId()),
				similarity(searchTerm, assets[key].getName()),
				similarity(searchTerm, assets[key].getMacaddress()),
				// Added the custom mac search function
				this.termIsIncluded(searchTerm, assets[key].getId()),
				this.termIsIncluded(searchTerm, assets[key].getName()),
				this.termIsIncluded(searchTerm, assets[key].getMacaddress())
			);

			assets[key].search_similarity = likeness;
		});
	}

	public sortLocations(sort?: Sort_State) {
		let keys = Object.keys(this.graph.rows[RowDefinitions.LOCATIONS]);

		var new_obj = {};

		keys.sort((a, b) => {

			let textA, textB;
			switch (sort) {
				case Sort_State.Similarity:
					let simA = this.graph.rows[RowDefinitions.LOCATIONS][a].search_similarity;
					let simB = this.graph.rows[RowDefinitions.LOCATIONS][b].search_similarity;
					return (simA > simB) ? -1 : (simA < simB) ? 1 : 0;
				default:
					textA = this.graph.rows[RowDefinitions.LOCATIONS][a].getName()[0].toUpperCase();
					textB = this.graph.rows[RowDefinitions.LOCATIONS][b].getName()[0].toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			}

		})
			.forEach(key => {
				new_obj[key] = this.graph.rows[RowDefinitions.LOCATIONS][key];
			});

		this.graph.rows[RowDefinitions.LOCATIONS] = new_obj;
	}

	public sortStations(sort?: Sort_State) {
		let keys = Object.keys(this.graph.rows[RowDefinitions.STATIONS]);

		var new_obj = {};

		keys.sort((a, b) => {
			switch (sort) {
				case Sort_State.Similarity:
					let simA = this.graph.rows[RowDefinitions.STATIONS][a].search_similarity;
					let simB = this.graph.rows[RowDefinitions.STATIONS][b].search_similarity;

					return (simA > simB) ? -1 : (simA < simB) ? 1 : 0;
				default:
					var textA = this.graph.rows[RowDefinitions.STATIONS][a].getName()[0].toUpperCase();
					var textB = this.graph.rows[RowDefinitions.STATIONS][b].getName()[0].toUpperCase();
					if (textA < textB) return -1;
					if (textA > textB) return 1;
					return 0;
			}

		}).forEach(key => {
			new_obj[key] = this.graph.rows[RowDefinitions.STATIONS][key];
		});

		this.graph.rows[RowDefinitions.STATIONS] = new_obj;
	}

	public sortAssets(sort?: Sort_State): string[] {
		let keys = Object.keys(this.graph.rows[RowDefinitions.ASSETS]);

		var new_obj = {};

		keys.sort((a, b) => {

			switch (sort) {
				case Sort_State.Similarity:
					let simA = this.graph.rows[RowDefinitions.ASSETS][a].search_similarity;
					let simB = this.graph.rows[RowDefinitions.ASSETS][b].search_similarity;
					return (simA > simB) ? -1 : (simA < simB) ? 1 : 0;
				default:
					if (!this.graph.rows[RowDefinitions.ASSETS][a].getName()[0] || !this.graph.rows[RowDefinitions.ASSETS][b].getName()[0]) return 0;
					var textA = this.graph.rows[RowDefinitions.ASSETS][a].getName()[0].toUpperCase();
					var textB = this.graph.rows[RowDefinitions.ASSETS][b].getName()[0].toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			}

		}).forEach(key => {
			new_obj[key] = this.graph.rows[RowDefinitions.ASSETS][key];
		});

		this.graph.rows[RowDefinitions.ASSETS] = new_obj;
		return keys;
	}
	/////////////

	/////////////
	//Temporary method 
	public setAllCounts() {

		this.serverDataCounts.location_count.next(Object.keys(this.graph.rows[RowDefinitions.LOCATIONS]).length);
		this.serverDataCounts.floor_count.next(Object.keys(this.graph.rows[RowDefinitions.FLOORS]).length);

		let stationKeys = Object.keys(this.graph.rows[RowDefinitions.STATIONS]);
		this.serverDataCounts.station_total_count.next(stationKeys.length);
		let stationsOnline = 0
		let stationsOffline = 0;
		stationKeys.forEach(key => {
			let station = this.graph.getStation(key);
			if (station.isOnline) stationsOnline++;
			else stationsOffline++;

			let floorID = station.getProtoParent();
			let floor = this.graph.getFloor(floorID);
			if (floor) {
				let locationID = floor.getProtoParent();
				let location = this.graph.getLocation(locationID);

				if (location) location.station_count++;
			}
		});

		this.serverDataCounts.station_online_count.next(stationsOnline);
		this.serverDataCounts.station_offline_count.next(stationsOffline);


		let assetsKeys = Object.keys(this.graph.rows[RowDefinitions.ASSETS] );
		let unclaimedAssetKeys = Object.keys(this.graph.rows[RowDefinitions.UNCLAIMED_ASSETS]);


		let assetsOnline = 0
		let assetsOffline = 0;
		assetsKeys.forEach(key => {
			let asset = this.graph.getAsset(key);

			if (asset.isOnline) assetsOnline++;
			else assetsOffline++;

			let station = this.graph.getStation(asset.getProtoParentsList()[0]);
			if (station) {
				let floorID = station.getProtoParent();
				let floor = this.graph.getFloor(floorID);
				if (floor) {
					let locationID = floor.getProtoParent();
					let location = this.graph.getLocation(locationID);

					if (location) location.asset_count++;
				}
			}
		});
		
		this.serverDataCounts.asset_total_count.next(assetsKeys.length + unclaimedAssetKeys.length);
		this.serverDataCounts.asset_online_count.next(assetsOnline);
		this.serverDataCounts.asset_offline_count.next(assetsOffline+unclaimedAssetKeys.length);
	}
	/////////////

}