import { Component, ViewChild, ElementRef } from '@angular/core';
import { FloorDerivedModel, DataService } from '@app/services';
import * as leaflet from 'leaflet';
import { MapBuilder } from '@app/_models/map/map-builder';
import { DrawService } from '@app/services/map/draw.service';
import { Platform, AlertController } from '@ionic/angular';
import { of } from 'rxjs';
import { ZoomLevel } from '@app/_models/map/map-constants';

const iconSize: number = 40;

const centerIcon = leaflet.icon({
  iconUrl: 'assets/icons/leaflet/MapMarkerCenter.png',
  iconRetinaUrl: 'assets/icons/leaflet/MapMarkerCenter.png',
  iconSize: [iconSize, iconSize],
});

const panIcon = leaflet.icon({
  iconUrl: 'assets/icons/leaflet/MapMarkerCenter.png',
  iconRetinaUrl: 'assets/icons/leaflet/MapMarkerCenter.png',
  iconSize: [iconSize, iconSize],
});

const cornerIconsPP = leaflet.icon({
  iconUrl: 'assets/icons/leaflet/MapMarkerCornerPP.png',
  iconRetinaUrl: 'assets/icons/leaflet/MapMarkerCornerPP.png',
  iconSize: [iconSize, iconSize],
});

const cornerIconsPN = leaflet.icon({
  iconUrl: 'assets/icons/leaflet/MapMarkerCornerPN.png',
  iconRetinaUrl: 'assets/icons/leaflet/MapMarkerCornerPN.png',
  iconSize: [iconSize, iconSize],
});

const cornerIconsNP = leaflet.icon({
  iconUrl: 'assets/icons/leaflet/MapMarkerCornerNP.png',
  iconRetinaUrl: 'assets/icons/leaflet/MapMarkerCornerNP.png',
  iconSize: [iconSize, iconSize],
});

const cornerIconsNN = leaflet.icon({
  iconUrl: 'assets/icons/leaflet/MapMarkerCornerNN.png',
  iconRetinaUrl: 'assets/icons/leaflet/MapMarkerCornerNN.png',
  iconSize: [iconSize, iconSize],
});

@Component({
  selector: 'map-image',
  templateUrl: './map-image.component.html',
  styleUrls: ['../map.component.scss'],
})
export class MapImageComponent extends MapBuilder {
  @ViewChild('map', { static: false }) mapContainer: ElementRef;

  floor: FloorDerivedModel;

  public _markerCenter: leaflet.Marker;
  public _markerOne: leaflet.Marker;
  public _markerTwo: leaflet.Marker;
  public _image: string = '';

  public opacity: number = 100;
  public imageLayer: leaflet.ImageOverlay;
  public markerSwitch: boolean = false;
  public markerOneOffset: [number, number] = [0, 0];
  public markerTwoOffset: [number, number] = [0, 0];

  public image_url:string = "";

  get markerOne() {
    return this._markerOne;
  }

  get markerTwo() {
    return this._markerTwo;
  }

  get image() {
    if(this.image_url.length>0){
      return this.image_url;
    }
    return this._image;
  }

  constructor(
    public dataService: DataService,
    public drawService: DrawService,
    public platform: Platform,
    public alertController: AlertController,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.initializeMap(this.mapContainer.nativeElement);
    this.menu.openSideMenu();
  }

  public initializeMap(mapId: string) {
    this.generateMap(mapId);
    this.mapObs = of(this.map);

    if (this.initial_zoom) {
      this.setViewFromPoint(this.initial_zoom, ZoomLevel.stationsZoomLevel);
    }

    this.initializeImage();
  }

  public initializeImage() {
    if (!this.floorModel) return;

    this.clearAllMarkers();
    this._markerOne = this.createMarkerOne().addTo(this.map);
    this._markerTwo = this.createMarkerTwo().addTo(this.map);
    this._markerCenter = this.createMarkerCenter().addTo(this.map);
    this._image = this.floorModel.getFloorplan().getImage()
    this.imageLayer = this.createImage(this.floorModel.getFloorplan().getImage()).addTo(this.map);
    this.draw();
  }

  public viewUrl(){
    this._image = this.image_url;
    this.updateImage(this._image);
  }

  public clearImage() {
    this.imageLayer.setUrl('');
  }

  public previewFile(imageInput: any) {

    const file: Blob = imageInput.files[0];
    let reader = new FileReader();
    const realFileReader = (reader as any)._realReader;

    if (this.platform.is('cordova')) {
      realFileReader.addEventListener('load', (event: any) => {
        this._image = event.target.result;
        this.updateImage(this._image);
        reader.removeEventListener;
      });

      reader.readAsDataURL(file);
      this.placeImageCorners();
    } else {
      reader.addEventListener('load', (event: any) => {
        this._image = event.target.result;
        this.updateImage(this._image);
        reader.removeEventListener;
      });

      reader.readAsDataURL(file);
      // this.placeImageCorners();
    }
  }

  public updateImage(imageUrl?: string, opacity: number = this.opacity / 100) {
    if (imageUrl) this.imageLayer.setUrl(imageUrl);

    let imageBounds: leaflet.LatLngBoundsExpression = new leaflet.LatLngBounds(this._markerOne.getLatLng(), this._markerTwo.getLatLng());
    this.imageLayer.setBounds(imageBounds);
    this.imageLayer.setOpacity(opacity);
  }


  private createImage(imageUrl: string = ''): leaflet.ImageOverlay {
    let imageBounds: leaflet.LatLngBoundsExpression = new leaflet.LatLngBounds(this._markerOne.getLatLng(), this._markerTwo.getLatLng());
    let imageLayer = leaflet.imageOverlay(imageUrl, imageBounds).addTo(this.map);
    return imageLayer;
  }

  private createMarkerOne(): leaflet.Marker {
    if (!this.floorModel) return;

    let latlng: leaflet.LatLng = <leaflet.LatLng>({});
    // latlng.lat = this.floorModel.getFloorplan().getCornerOne().getX();
    // latlng.lng = this.floorModel.getFloorplan().getCornerOne().getY();

    latlng.lat = this.map.getCenter().lat-.0001;
    latlng.lng = this.map.getCenter().lng-.0001;

    let marker = new leaflet.Marker(latlng, { icon: cornerIconsPP, draggable: true });

    this.setMarkerDrag(marker);
    this.setMarkerDragEnd(marker);

    return marker;
  }

  private createMarkerTwo(): leaflet.Marker {
    if (!this.floorModel) return;

    let latlng: leaflet.LatLng = <leaflet.LatLng>({});
    // latlng.lat = this.floorModel.getFloorplan().getCornerTwo().getX();
    // latlng.lng = this.floorModel.getFloorplan().getCornerTwo().getY();
    latlng.lat = this.map.getCenter().lat+.0001;
    latlng.lng = this.map.getCenter().lng+.0001;

    let marker = new leaflet.Marker(latlng, { icon: cornerIconsPP, draggable: true });

    this.setMarkerDrag(marker);
    this.setMarkerDragEnd(marker);

    return marker;
  }

  private createMarkerCenter(): leaflet.Marker {
    var latCenter = (this._markerTwo.getLatLng().lat + this._markerOne.getLatLng().lat) / 2;
    var lngCenter = (this._markerTwo.getLatLng().lng + this._markerOne.getLatLng().lng) / 2;
    let marker = new leaflet.Marker([latCenter, lngCenter], { icon: centerIcon, draggable: true });

    marker.on('dragstart', (e) => {
      this.markerOneOffset = [this._markerOne.getLatLng().lat - e.target._latlng.lat, this._markerOne.getLatLng().lng - e.target._latlng.lng];
      this.markerTwoOffset = [this._markerTwo.getLatLng().lat - e.target._latlng.lat, this._markerTwo.getLatLng().lng - e.target._latlng.lng];
    });

    marker.on('drag', (e) => {
      let pointOne = new leaflet.LatLng(
        e.target._latlng.lat + this.markerOneOffset[0],
        e.target._latlng.lng + this.markerOneOffset[1]
      );

      this._markerOne.setLatLng(pointOne);

      let pointTwo = new leaflet.LatLng(
        e.target._latlng.lat + this.markerTwoOffset[0],
        e.target._latlng.lng + this.markerTwoOffset[1]
      );

      this._markerTwo.setLatLng(pointTwo);

      this.updateMarkerOneIcon();
      this.updateMarkerTwoIcon();
      this.updateImage(this._image);
    });

    return marker
  }


  private setMarkerDrag(marker: leaflet.Marker) {
    marker.on('drag', (e) => {
      this.updateMarkerCenter();
      this.updateImage(this._image);
    });
  }

  private setMarkerDragEnd(marker: leaflet.Marker) {
    marker.on('dragend', (e) => {
      this.draw();
    });
  }

  //TODO - finish - not working as inteded yet
  private placeImageCorners() {
    this.clearAllMarkers();
    this.markerSwitch = false;

    this._markerOne = this.createMarkerOne().addTo(this.map);
    this._markerOne.dragging.enable();
  }

  private updateMarkerOneIcon() {
    var xquad = this._markerOne.getLatLng().lat - this._markerTwo.getLatLng().lat;
    var yquad = this._markerOne.getLatLng().lng - this._markerTwo.getLatLng().lng;
    this.updateMarkerIcon(this._markerOne, xquad, yquad);
  }

  private updateMarkerTwoIcon() {
    var xquad = this._markerTwo.getLatLng().lat - this._markerOne.getLatLng().lat;
    var yquad = this._markerTwo.getLatLng().lng - this._markerOne.getLatLng().lng;
    this.updateMarkerIcon(this._markerTwo, xquad, yquad);
  }

  private updateMarkerIcon(marker: leaflet.Marker, xquad: number, yquad: number) {
    if (!marker) return;

    if (Math.sign(xquad) == 1 && Math.sign(yquad) == 1) {
      marker.setIcon(cornerIconsNN);
    } else if (Math.sign(xquad) == 1 && Math.sign(yquad) == -1) {
      marker.setIcon(cornerIconsPN);
    } else if (Math.sign(xquad) == -1 && Math.sign(yquad) == 1) {
      marker.setIcon(cornerIconsNP);
    } else {
      marker.setIcon(cornerIconsPP);
    }
  }

  private updateMarkerCenter() {
    var latCenter = (this._markerTwo.getLatLng().lat + this._markerOne.getLatLng().lat) / 2;
    var lngCenter = (this._markerTwo.getLatLng().lng + this._markerOne.getLatLng().lng) / 2;
    this._markerCenter.setLatLng([latCenter, lngCenter]);
  }

  private hideImage() {
    this.updateImage(this._image, 0);
  }

  private draw() {
    if (!this.floorModel) return;

    this.updateMarkerOneIcon();
    this.updateMarkerTwoIcon();
    this.updateMarkerCenter();
    this.updateImage(this._image);
  }

  private clearAllMarkers() {
    if (this._markerOne)
      this._markerOne.remove();

    if (this._markerTwo)
      this._markerTwo.remove();

    if (this._markerCenter)
      this._markerCenter.remove();

    if (this.imageLayer)
      this.imageLayer.remove();
  }

}
