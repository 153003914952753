import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
/*
Interface that classes can implement to be a data provider. A data provider class can be used with the router to resolve data during navigation. 
The interface defines a resolve() method that will be invoked when the navigation starts. 
The router will then wait for the data to be resolved before the route is finally activated.
*/

@Injectable()
export class GRPCResolverService implements Resolve<any> {

    public initialized$: Observable<any>
    private retrieved: boolean = false;

    constructor(
        private apiService: ApiService,
        private auth: AuthenticationService,
    ) {

        //Reset on logout
        this.auth.userSubject.subscribe(value => {
            if (value == null) this.retrieved = false;
        })
    }

    //This will eventually be switched to init grpc
    //This is called on page refresh, or when routed into user pages
    //Does not get called twice when navigating inside user pages
    //Will get called if user leaves user pages, and comes navigates back.
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('Getting Fullmodel');

        if (!this.retrieved) {
            navigator.geolocation.getCurrentPosition(function () { }, function () { });

            this.apiService.getFullModel()
                .then(() =>{ 
                    this.retrieved = true;
                    this.initialized$ = of(true)
                })
                .catch(() => this.retrieved = false)

        }

    }
}
