import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Injectable({
	providedIn: 'root'
})
export class PlatformInfoService {

	constructor(
		public platform: Platform,
		private geolocation: Geolocation,
		// private loader: LoaderComponent
	) {
		platform.ready().then(() => {
			this.updateCurrentPosition();
		})
	}

	////////////////////////////////////////////////////////////////////////////////
	// Position 
	////////////////////////////////////////////////////////////////////////////////
	public positionLoading = new BehaviorSubject<boolean>(false);
	private position: any = null;
	private positionCheckTimeout = 3000;

	private positionLoaderPopup = null;
	private positionLoadingOptions = {
		spinner: 'bubbles',
		content: 'Getting Current Location',
		enableBackdropDismiss: true,
	};

	// This will get the curernt location and update the current location in this provider
	public updateCurrentPosition() {
		if (!this.positionLoading.value && this.platform.is('cordova')) {
			this.positionLoading.next(true);
			this.geolocation.getCurrentPosition().then((resp) => {
				this.position = resp;
				this.positionLoading.next(false);
			}).catch((error) => {
				console.error(' :: PlatformInfoProvider : Error Position', error);
				this.positionLoading.next(false);
			});
		}
	}

	// This will return the most recent position, show loading if position has not yet been found
	public giveCurrentPosition(showLoading: boolean) {
		return new Promise((resolve, reject) => {
			if (this.position == null) {
				if (!this.positionLoading.value) {
					this.updateCurrentPosition();
				}
				var sub = this.positionLoading.subscribe((stillLoading: boolean) => {
					if (stillLoading && showLoading) {
						// this.loader.presentLoading();
					}
					else {
						if (this.positionLoaderPopup != null) {
							// this.loader.dismiss();
						}
						if (this.position != null) {
							sub.unsubscribe();
							resolve(this.position);
						}
						else {
							sub.unsubscribe();
							reject(" :: PlatformInfoProvider : Position Timeout ::");
						}
					}
				})
				setTimeout(() => {
					this.positionLoading.next(false);
				}, this.positionCheckTimeout)
			}
			else {
				resolve(this.position);
			}
		});
	}

	// https://www.geodatasource.com/developers/javascript
	public gpsDistanceCalc(lat1, lon1, lat2, lon2) {
		var radlat1 = Math.PI * lat1 / 180
		var radlat2 = Math.PI * lat2 / 180
		var theta = lon1 - lon2
		var radtheta = Math.PI * theta / 180
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) { dist = 1; }
		dist = Math.acos(dist)
		dist = dist * 180 / Math.PI
		dist = dist * 60 * 1.1515
		dist = dist * 1.609344 // Put it in "K"
		return dist
	}

	////////////////////////////////////////////////////////////////////////////////
	// WiFi Scan
	////////////////////////////////////////////////////////////////////////////////

}
