import { Component, ElementRef, ViewChild } from '@angular/core';
import { MapBuilder } from '@app/_models/map/map-builder';
import { LocationDerivedModel, FloorDerivedModel, DataService } from '@app/services';
import { BehaviorSubject, of } from 'rxjs';
import { DrawService } from '@app/services/map/draw.service';
import { Platform, AlertController } from '@ionic/angular';
import { RowDefinitions, Locations, GraphService } from '@app/services/graph.service';
import { ZoomLevel } from '@app/_models/map/map-constants';


@Component({
	selector: 'map-place-station',
	templateUrl: './map-place-station.component.html',
	styleUrls: ['../map.component.scss'],
})
export class MapPlaceStationComponent extends MapBuilder {
	@ViewChild('map', { static: false }) mapContainer: ElementRef;

	private _active_Location: LocationDerivedModel = undefined;
	get active_Location() {
		return this._active_Location;
	}

	private _active_floor: FloorDerivedModel = undefined;
	get active_floor() {
		return this._active_floor;
	}

	public _user_selected_floor: BehaviorSubject<FloorDerivedModel> = new BehaviorSubject<FloorDerivedModel>(undefined);

	constructor(
		public dataService: DataService,
		public drawService: DrawService,
		public platform: Platform,
		public alertController: AlertController,
		private graph: GraphService,
	) {
		super();
	}

	ngAfterViewInit() {
		this.initializeMap();
	}

	public initializeMap() {
		setTimeout(() => {
			var mapId = this.mapContainer.nativeElement;
			this.generateMap(mapId);
			this.mapObs = of(this.map);
	
			this.map.on('zoomend', () => {
				this.draw();
			});
	
			if (this.initial_zoom) {
				this.setViewFromPoint(this.initial_zoom, ZoomLevel.stationsZoomLevel);
			}
	
			this._user_selected_floor.subscribe((value) => {
				this._active_floor = value;
				this.draw();
			});
	
			this.map.on('zoomend', () => {
				this.draw();
			});
	
			this.dataService.serverDataCounts.location_count.subscribe(() => {
				this.clear();
				this.draw();
			});
	
			if (this.stationModel) this.setViewFromPoint(this.stationModel.getLatLong());
			this.draw();
			console.log("CALLING THE INITIALIZE MAP :: DRAW");

		}), 1000;
	}

	public draw() {
		this.clear();

		if (this.map.getZoom() >= ZoomLevel.locationZoomLevel) { //zoomed in
			this._active_Location = this.findActiveLocation(this.graph.getRow(RowDefinitions.LOCATIONS) as Locations);
			this._active_floor = this.graph.getFloor(this.findActiveFloorId(this._active_Location, this._user_selected_floor.value));

			let floors = this.dataService.getAllLocationFloors(this._active_Location);
			this.menu.setFloorsMenu(floors);

			this.drawService.drawFloor(this.layerGroups, this._active_floor);
			this.drawService.drawStation(this.layerGroups, this.stationModel);
		} else { //zoomed out
			if (this._user_selected_floor.value) {
				this._user_selected_floor.next(undefined);
				return;
			}

			let locations = this.graph.getRow(RowDefinitions.LOCATIONS) as Locations;
			this.menu.setLocationsMenu(locations);

			this.drawService.drawLocationMarkers(this.layerGroups);
		}

		this.drawService.drawPlacement(this.map, this.layerGroups);
	}

	private clear() {
		this._active_floor = undefined;
		this._active_Location = undefined;

		this.layerGroups.clearLayers();
		this.menu.clearMenu();
	}

}
