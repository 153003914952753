import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { StationDerivedModel } from 'src/app/services/models/station-derived-model.service';
import { NavigationService } from '@app/services/navigation.service';
import { Stations, GraphService } from '@app/services/graph.service';


@Component({
  selector: 'station-table',
  templateUrl: './station-table.component.html',
  styleUrls: ['./station-table.component.scss'],
})
export class StationTableComponent implements OnInit {
  @Input() stations: Stations = {};
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayData: MatTableDataSource<StationDerivedModel>;
  public displayedColumns = ['online', 'name', 'location_name', 'floor_name', 'wifi_rssi', 'distance_to_asset'];
  public dataSource: MatTableDataSource<string>;
  public isLoadingResults = true;
  public isRateLimitReached = false;

  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;

  constructor(
    public navService: NavigationService,
    public dataService: DataService,
    public graphService: GraphService
  ) { 

  }

  public ngOnInit() {
    this.dataService.serverDataCounts.station_total_count.subscribe(value => {
      this.getArray();
    });

    this.getArray();
  }

  ionViewWillLeave() {
    this.displayData.data = [];
    this.stations = {};
  }

  private getArray() {
    if (!this.stations) return;
    this.isLoadingResults = true;

    let temp: StationDerivedModel[] = []
    Object.keys(this.stations).forEach(key => {
      let station = this.stations[key];
      if(station) temp.push(station);
    });

    this.isLoadingResults = false;
    this.displayData = new MatTableDataSource<StationDerivedModel>(temp);
  }
}
