import { Injectable } from '@angular/core';
import { BLE } from '@ionic-native/ble/ngx';

export interface BLEDevicesState {
	[key: string]: {
		device: any,
		listenData: Uint8Array,
	};
}

@Injectable({
	providedIn: 'root'
})
export class BleService {
	constructor(
		private ble: BLE,
	) { }

	public async connectAndAdjustMTU(deviceID, size): Promise<void> {
		return new Promise((resolve, reject) => {
			this.tryConncetion(deviceID).then(() => {
				this.adjustMTU(deviceID, size).then(() => {
					resolve();
				})
			}).catch(err => {
				reject(err);
			});
		});
	}

	public async connectAndSendTlmtryCommandV0(deviceID: string, data): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.bleTryWrite(deviceID, "00FF", "FF01", data.buffer).then(() => {
				resolve();
			}).catch((err) => {
				reject(err);
			});
		});
	}

	public async connectAndWriteTlmtryCommandV1(deviceID): Promise<void> {
		return new Promise((resolve, reject) => {
			let data = new Uint8Array(1);
			data[0] = 1;

			this.bleTryWrite(deviceID, "ff00", "c000", data).then(() => {
				resolve();
			}).catch((err) => {
				reject(err);
			});
		});
	}

	public async connectAndWriteSettings(deviceID, data): Promise<void> {
		return new Promise((resolve, reject) => {
			const copyBuffer = new Uint8Array(data);
			const arrayBuffer = this.arrayBuffer(copyBuffer);
			this.bleTryWrite(deviceID, "FF00", "A000", arrayBuffer).then(() => {
				resolve();
			}).catch((err) => {
				reject(err);
			});
		});
	}


	public async tryConncetion(deviceID): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.isDeviceConnected(deviceID).then(isConnected => {
				if (isConnected) {
					resolve();
				} else {
					this.ble.connect(deviceID).subscribe(() => {
						resolve();
					});
				}
			}).catch(err => {
				reject(err);
			});
		});
	}


	private bleTryWrite(deviceID, serviceUUID, characteristicUUID, data): Promise<void> {
		return new Promise((resolve, reject) => {
			this.ble.connect(deviceID).subscribe(
				() => { //connected
					this.bleWrite(deviceID, serviceUUID, characteristicUUID, data).then(() => {
						resolve();
					}).catch(error => {
						reject(error);
					});
				}, (err) => { //disconnected
					console.log('disconnected', err);
					reject(err);
				});
		});
	}


	private bleWrite(deviceID, serviceUUID, characteristicUUID, data): Promise<void>{
		return new Promise((resolve, reject) => {
			this.ble.write(deviceID, serviceUUID, characteristicUUID, data.buffer)
				.then(() => {
					resolve();
				}).catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	private arrayBuffer(array: Uint8Array): ArrayBuffer {
		return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
	}

	private async isDeviceConnected(deviceID): Promise<Boolean> {
		return new Promise((resolve, reject) => {
			this.ble.isConnected(deviceID).then(
				() => {
					resolve(true);
				}, () => {
					resolve(false);
				}).catch(err => {
					reject(err);
				});
		});
	}

	private adjustMTU(deviceID, size) {
		return new Promise((resolve, reject) => {
			this.ble.requestMtu(deviceID, size).then(() => {
				resolve(true)
			}).catch((err) => {
				console.log('BtleFwUpdate:adjustMTU - MTU Size failed.');
				reject(err)
			});
		});
	}

}

