import { Input, ViewChild, ElementRef } from '@angular/core';
import { LocationDerivedModel, FloorDerivedModel, StationDerivedModel, AssetDerivedModel, Locations } from '@app/services';
import { Point } from '@protos';
import 'leaflet-draw'
import * as L from 'leaflet';
import { Observable } from 'rxjs';
import { GraphLayerGroups } from '..';
import { MapMenu } from './map-menu';
import { defaultLatLng, ZoomLevel } from './map-constants';
import { MapFilter } from './map-filter';
import { MapControls } from './map-control';

export class MapBuilder {
    @ViewChild('map', { static: false }) mapContainer: ElementRef;

    @Input() locationModel: LocationDerivedModel = undefined;
    @Input() floorModel: FloorDerivedModel = undefined;
    @Input() stationModel: StationDerivedModel = undefined;
    @Input() assetModel: AssetDerivedModel = undefined;
    @Input() initial_zoom: Point = undefined;

    public map: L.Map;
    public mapObs: Observable<L.Map>;
    public controls: MapControls = new MapControls();
    public menu: MapMenu = new MapMenu();
    public filter: MapFilter = new MapFilter();

    public layerGroups: GraphLayerGroups = new GraphLayerGroups();

    //These variables needed for use in HTML
    locationLevel: ZoomLevel = ZoomLevel.locationZoomLevel;
    stationLevel: ZoomLevel = ZoomLevel.stationsZoomLevel;

    public generateMap(mapId: string) {

        let map = L.map(mapId,
            {
                attributionControl: false,
            }).setView(defaultLatLng, ZoomLevel.defaultZoom);

        window.dispatchEvent(new Event('resize'));
        setTimeout(() => {
            map.invalidateSize();
        }, 1000);

        this.map = map;
        this.controls.addToMap(this.map, this.layerGroups);
        this.filter.addToMap(this.map);
        this.layerGroups.addToMap(this.map);
    }

    public getMapCenterAsPoint(): Point {
        let latLng: L.LatLng = this.map.getCenter();

        let point = new Point();
        point.setX(latLng.lat);
        point.setY(latLng.lng);

        return point;
    }

    public setViewFromPoint(point: Point, zoom: number = ZoomLevel.stationsZoomLevel) {
        if (!this.map) return;
        if (!point) return;

        let latlng: L.LatLng = <L.LatLng>({});
        latlng.lat = point.getX();
        latlng.lng = point.getY();

        this.map.setView(latlng, zoom);
    }

    public centerMap(locations: Locations) {
        let locationsKeys = Object.keys(locations)

        if (locationsKeys.length == 0) return;

        let latLngArr = [];
        locationsKeys.forEach(locationKey => {
            if (((locations[locationKey]).getLatLong().getX() != 0) && (locations[locationKey]).getLatLong().getY() != 0) {
                if (((locations[locationKey]).getLatLong().getX() != null) && (locations[locationKey]).getLatLong().getY() != null) {
                    latLngArr.push([(locations[locationKey]).getLatLong().getX(), (locations[locationKey]).getLatLong().getY()]);
                }
            }
        });

        var bounds = new L.LatLngBounds(latLngArr);
        if (bounds) this.map.fitBounds(bounds);
    }

    averageGeolocation(latLngs: Point[]): Point {

        if (latLngs.length == 0) {
            return new Point
        }

        if (latLngs.length == 1) {
            return latLngs[0];
        }

        let x = 0.0;
        let y = 0.0;
        let z = 0.0;

        latLngs.forEach(latLng => {
            var latitude = latLng.getX() * Math.PI / 180;
            var longitude = latLng.getY() * Math.PI / 180;

            x += Math.cos(latitude) * Math.cos(longitude);
            y += Math.cos(latitude) * Math.sin(longitude);
            z += Math.sin(latitude);
        });

        var total = latLngs.length;

        x = x / total;
        y = y / total;
        z = z / total;

        var centralLongitude = Math.atan2(y, x);
        var centralSquareRoot = Math.sqrt(x * x + y * y);
        var centralLatitude = Math.atan2(z, centralSquareRoot);

        let point = new Point();
        point.setX(centralLatitude * 180 / Math.PI);
        point.setY(centralLongitude * 180 / Math.PI);
        return point;
    }

    public getDistanceBetweenPoints(pointOne: L.LatLng, pointTwo: L.LatLng) {
        let R = 6373.0;

        let dlon = pointTwo.lng - pointOne.lng;
        let dlat = pointTwo.lat - pointOne.lat;

        let a = Math.sin(dlat / 2) ** 2 + Math.cos(pointOne.lat) * Math.cos(pointTwo.lat) * Math.sin(dlon / 2) ** 2;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        let distance = R * c;
        return distance;
    }

    ///////////
    //Active floor/location Functions

    public findActiveLocation(locations: Locations): LocationDerivedModel {
        let locationKeys = Object.keys(locations);

        let activeLocation: LocationDerivedModel = undefined;

        locationKeys.forEach(locationKey => {

            let nonActiveLatLng = this.getLatLngFromPoint(locations[locationKey].getLatLong());
            var nonActiveDistance = this.getDistanceBetweenPoints(nonActiveLatLng, this.map.getCenter());
            if (nonActiveDistance < 300) {
                if (activeLocation) {
                    let activePoint = this.getLatLngFromPoint(activeLocation.getLatLong());
                    var activeDistance = this.getDistanceBetweenPoints(activePoint, this.map.getCenter());
                    if (nonActiveDistance < activeDistance) {
                        activeLocation = locations[locationKey];
                    }
                } else {
                    activeLocation = locations[locationKey];
                }
            }
        });

        return activeLocation;
    }

    public findActiveFloorId(activeLocation: LocationDerivedModel, userSelectedFloor: FloorDerivedModel): string {
        if (!activeLocation) return;

        if (userSelectedFloor) {
            if (activeLocation.getProtoChildrenList().includes(userSelectedFloor.getId())) {
                return userSelectedFloor.getId();
            }
        }

        let floorKeys = activeLocation.getProtoChildrenList();
        if (floorKeys.length > 0) {
            return floorKeys[0];
        }

        return undefined;
    }
    ///////////

    public getPointFromLatLng(latLng: L.LatLng): Point {
        if (!latLng) return new Point();

        let point = new Point();
        point.setX(latLng.lat);
        point.setY(latLng.lng);
        return point;
    }

    public getLatLngFromPoint(point: Point): L.LatLng {
        if (!point) return new L.LatLng(0, 0);

        let latLng: L.LatLng = new L.LatLng(point.getX(), point.getY());
        return latLng;
    }

}