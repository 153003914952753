import { Injectable } from '@angular/core';
import * as leaflet from 'leaflet';
import { AlertController } from '@ionic/angular';
import { GraphLayerGroups } from '@app/_models';
import { DataService } from '../data.service';
import { RowDefinitions, Locations, GraphService } from '../graph.service';
import { MarkerService } from './marker.service';
import { FloorDerivedModel, AssetDerivedModel, StationDerivedModel } from '../models';
import { FeatureGroup } from '@app/_models/map/feature-group';
import { MarkerFilter } from '@app/_models/map/map-constants';

@Injectable({
	providedIn: 'root'
})
export class DrawService {

	constructor(
		private alertCtrl: AlertController,
		public dataService: DataService,
		private markerService: MarkerService,
		private graph: GraphService
	) { }

	drawLocationMarkers(layerGroups: GraphLayerGroups, map?:any) {
		if (!layerGroups) return;

		let locations = this.graph.getRow(RowDefinitions.LOCATIONS) as Locations;
		let locationMarkers = this.markerService.createLocationMarkers(locations, map);

		locationMarkers.forEach(layer =>{
			layerGroups.locationLayerGroup.addLayer(layer);
		});
	}

	drawFloor(layerGroups: GraphLayerGroups, floor: FloorDerivedModel) {
		if (!layerGroups) return;
		if (!floor) return;

		let floorLayer = this.createImageLayer(floor);
		layerGroups.floorLayerGroup = new leaflet.LayerGroup([floorLayer]);
		let geoJsonLayers = this.createLayersFromGeoJSON(floor.getGeojson().getJson());
		layerGroups.floorJsonLayerGroup = new leaflet.LayerGroup(geoJsonLayers);

		geoJsonLayers.forEach(layer =>{
			layerGroups.floorJsonLayerGroup.addLayer(layer);
		});
	}

	drawFloorStations(layerGroups: GraphLayerGroups, floor: FloorDerivedModel, filter: MarkerFilter) {
		if (!layerGroups) return;
		if (!floor) return;

		let stations = this.dataService.getAllFloorStations(floor);
		let stationMarkers = this.markerService.createStationsMarkers(stations, filter);

		stationMarkers.forEach(marker =>{
			layerGroups.stationLayerGroup.addLayer(marker);
		});
	}

	drawFloorAssets(layerGroups: GraphLayerGroups, floor: FloorDerivedModel, filter: MarkerFilter) {
		if (!layerGroups) return;
		if (!floor) return;

		let assets = this.dataService.getAllFloorAssets(floor);
		let assetMarkers = this.markerService.createAssetsMarkers(assets, filter);

		assetMarkers.forEach(marker =>{
			layerGroups.assetLayerGroup.addLayer(marker);
		});
	}

	drawStationAssets(layerGroups: GraphLayerGroups, station: StationDerivedModel, filter: MarkerFilter) {
		if (!layerGroups) return;
		if (!station) return;

		let assets = this.dataService.getAllStationAssets(station);
		let assetMarkers = this.markerService.createAssetsMarkers(assets, filter);

		assetMarkers.forEach(marker =>{
			layerGroups.assetLayerGroup.addLayer(marker);
		});
	}

	drawStation(layerGroups: GraphLayerGroups, station: StationDerivedModel): leaflet.Circle {
		if (!layerGroups) return;
		if (!station) return;

		let marker = this.markerService.generateStationMarker(station);
		layerGroups.locationLayerGroup.addLayer(marker);

		return marker;
	}

	drawAsset(layerGroups: GraphLayerGroups, asset: AssetDerivedModel): leaflet.Circle {
		if (!layerGroups) return;
		if (!asset) return;

		let marker = this.markerService.generateAssetMarker(asset)
		layerGroups.assetLayerGroup.addLayer(marker);

		return marker;
	}

	drawPlacement(map: leaflet.Map, layerGroups: GraphLayerGroups) {
		if (!layerGroups) return;

		let marker = this.markerService.generatePlacementMarker(map);
		layerGroups.assetLayerGroup.addLayer(marker);
	}

    public createImageLayer(floor: FloorDerivedModel): leaflet.Layer {
        if (!floor) return;

        const imageUrl = floor.getFloorplan().getImage();

        let cornerOne: leaflet.LatLng = leaflet.latLng(floor.getFloorplan().getCornerOne().getX(), floor.getFloorplan().getCornerOne().getY());
        let cornerTwo: leaflet.LatLng = leaflet.latLng(floor.getFloorplan().getCornerTwo().getX(), floor.getFloorplan().getCornerTwo().getY());
        const imageBounds: leaflet.LatLngBoundsExpression = new leaflet.LatLngBounds(cornerOne, cornerTwo);//[floor.getFloorplan().getCornerOne(), floor.getFloorplan().getCornerTwo()];

        return new leaflet.ImageOverlay(imageUrl, imageBounds)
    }

	public createLayersFromGeoJSON(geoJson: string, options?: leaflet.GeoJSONOptions): leaflet.LayerGroup[] {
		let layerGroups: leaflet.LayerGroup[] = [];
		Object.keys(geoJson).forEach(key => {
			let layer = leaflet.geoJSON(geoJson[key].layers, options);
			layerGroups.push(layer);
		});

		return layerGroups;
	}

	public createGeoJSONFromLayers(featureGroups: FeatureGroup[]): any {

		var geoJsonArray = [];

		featureGroups.forEach(featureGroup => {
			var layerCollection = [];
			featureGroup.getLayers().forEach((layer: leaflet.GeoJSON) => {
				layerCollection.push(layer.toGeoJSON());
			});

			geoJsonArray.push({
				"name": featureGroup.name,
				"layers": layerCollection,
			});
		});

		return geoJsonArray;
	}


	createDrawControl(featureGroup: leaflet.FeatureGroup): leaflet.Control.Draw {
		let draw_control = new leaflet.Control.Draw({
			position: 'topright',

			draw: {
				circle: false,
				circlemarker: false,
				marker: false,
				rectangle: false,

			},
			edit: {
				featureGroup: featureGroup,
				remove: false,
			}
		});

		return draw_control;
	}

	async presentLayerNamePrompt(featureGroup: any) {
		let alert = await this.alertCtrl.create({
			header: 'Set Layer Name',
			inputs: [
				{
					name: 'username',
					placeholder: featureGroup.name,
				}
			],
			buttons: [
				{
					text: 'Change',
					handler: data => {
						featureGroup.name = data.username;
					}
				},
				{
					text: 'Cancel',
				}
			]
		});
		await alert.present();
	}

	async presentGeoJsonPrompt() {
		let alert = await this.alertCtrl.create({
			header: 'Load Geo JSON',
			inputs: [
				{
					name: 'geoJson',
					placeholder: 'geoJson',
				}
			],
			buttons: [
				{
					text: 'Add',
					handler: data => {
						return true;
					}
				},
				{
					text: 'Cancel',
				}
			]
		});
		await alert.present();
	}

}
