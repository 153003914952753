import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PureLocateDevice, DeviceFactory } from 'src/shared/devices/pureLocate.device';
import { Platform } from '@ionic/angular';
import { BLEScanOptions, BLE } from '@ionic-native/ble/ngx';
import { BLEDevicesState } from './ble.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { GroupType } from 'src/shared/groups/groups.model';
export interface Scanned_Stations {
	[key: string]: PureLocateDevice,
}

export interface Scanned_Assets {
	[key: string]: PureLocateDevice,
}

@Injectable({
	providedIn: 'root'
})
export class ScannerService {

	private assets = {};
	private stations = {};

	public assetsSubject: BehaviorSubject<Scanned_Assets> = new BehaviorSubject(this.assets);
	public stationsSubject: BehaviorSubject<Scanned_Stations> = new BehaviorSubject(this.stations);

	private deviceFactory = new DeviceFactory();
	private devices: BLEDevicesState = {};

	constructor(
		private platform: Platform,
		private ble: BLE,
		private androidPermissions: AndroidPermissions,
		private barcodeScanner: BarcodeScanner,
	) {
		this.init();
	}

	public async init(){
		await this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.BLUETOOTH]);
		await this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT]);
		await this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.BLUETOOTH_SCAN]);
		this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.BLUETOOTH).then(
			result => { },
			err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.BLUETOOTH)
		);
	
		this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA, this.androidPermissions.PERMISSION.GET_ACCOUNTS, this.androidPermissions.PERMISSION.BLUETOOTH, this.androidPermissions.PERMISSION.BLUETOOTH_SCAN]);
	}

	public scanUUIDs(uuid: string) {
		if (!this.platform.is('cordova')) return;
		
		this.clear();
		this.checkBluetooth();

		this.ble.stopScan().then(() => {
			this.ble.startScanWithOptions([uuid], { reportDuplicates: true }).subscribe(
				data => {

					let device = this.deviceFactory.createAssetDevice(data.id, data.name, data.rssi);
					this.setAssetUpdate(device);
				});
		}).catch((err) => {
			console.log(err);
		});
	}

	public async checkBluetooth() {
		if(this.platform.is('android')){
			console.log("Checking bluetooth permissions")
			await this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.BLUETOOTH]);
			await this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT]);
			await this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.BLUETOOTH_SCAN]);


			this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.BLUETOOTH_SCAN).then(
				result => { console.log("has bluetooth scan") },
				err => { console.log("no bluetooth scan", err) }
			);
		}

		this.ble.isEnabled().then(success => {
			console.log("Bluetooth is enabled");
		},
			error => {
				console.log('Bluetooth cannot be enabled: ', error);
			});
	}

	public scanBleTags() {
		if (!this.platform.is('cordova')) return;
		this.clear();
		this.checkBluetooth();

		this.ble.stopScan().then(() => {
			this.ble.startScanWithOptions([], { reportDuplicates: true }).subscribe(
				data => {
					// let device = this.deviceFactory.createAssetDevice(data.id, data.name, data.rssi);
					this.addFoundAsset(data);
				},
				error => { console.log('Scan error:', error); }
			);
		});
	}

	addFoundAsset(data) {
		// window["LOG"] && console.log(data);
		// window["LOG"] && console.log(data.advertising);

		var update: PureLocateDevice = {
			type: GroupType.PureLocateDevice,
			id: data.id.replace(/:/g, '').toLowerCase(),
			uuid: data.id,
			name: data.name,
			macAddress: data.id.replace(/:/g, '').toLowerCase(),
			service: "TAG",
			fromBLE: true,
			ble_scannedAt: new Date(),
			ble_name: data.name || "Unknown",
			ble_rssi: data.rssi,
			location:"0",
			floor:"0",
		};
		// window["LOG"] && console.log(" :: Scanned Device : ", update);
		this.setAssetUpdate(update);
	}



	private rssiSampleLoop:any;
	private uuid:string = "";
	private characteristic: any = null;

	public disconnectBleTag(){
		clearInterval(this.rssiSampleLoop);
		if(this.characteristic){
			this.ble.writeWithoutResponse(this.uuid, this.characteristic.service, this.characteristic.characteristic, new Uint8Array([0x00]).buffer)
			.then((response) => {
				console.log("Wrote 0x02: ", response);
				this.ble.disconnect(this.uuid).then( (disconnected) => {
					console.log("Disconnected : ", disconnected);
				}).catch( (err) => {
					console.log("Error disconnecting : ", err);
				});
			})
			.catch(error => {
				console.error("Error writing to characteristic: ", error);
			})
		}
		else {
			this.ble.disconnect(this.uuid).then( (disconnected) => {
				console.log("Disconnected : ", disconnected);
			}).catch( (err) => {
				console.log("Error disconnecting : ", err);
			});
		}
	}

	public connectBleTag(macAddress: string) {
		if (!this.platform.is('cordova')) return;
		this.clear();
		this.checkBluetooth();

		this.ble.stopScan().then(() => {
			this.ble.startScanWithOptions([], { reportDuplicates: true }).subscribe(
				data => {
					// let device = this.deviceFactory.createAssetDevice(data.id, data.name, data.rssi);
					var update: PureLocateDevice = {
						type: GroupType.PureLocateDevice,
						id: data.id.replace(/:/g, '').toLowerCase(),
						uuid: data.id,
						macAddress: data.id.replace(/:/g, '').toLowerCase(),
						service: "TAG",
						fromBLE: true,
						ble_scannedAt: new Date(),
						ble_name: data.name || "Unknown",
						ble_rssi: data.rssi,
					};
					// window["LOG"] && console.log(" :: Scanned Device : ", update);
					this.setAssetUpdate(update);

					
					if(update.macAddress == macAddress){
						this.ble.stopScan().then(() => {
							this.uuid = update.uuid;
							this.ble.autoConnect(update.uuid,
								async (data) => {
									// window["LOG"] && console.log("Connected : ", data);
									console.log(`Connected ${macAddress}: `, data);
									var lastSentRingCommand:number = 0;
									if(data.characteristics){
											for (let index = 0; index < data.characteristics.length; index++) {
												const characteristic = data.characteristics[index];
												if(characteristic.service == "1802" && characteristic.characteristic == "2a06"){
													this.characteristic = characteristic;
													// send command if its been longer than 30 seconds
													if(Date.now() - lastSentRingCommand > 30000){
														console.log("Found PL : ", characteristic);
														await new Promise(resolve => setTimeout(resolve, 500));
														this.ble.writeWithoutResponse(update.uuid, characteristic.service, characteristic.characteristic, new Uint8Array([0x02]).buffer)
														.then((response) => {
															console.log("Wrote 0x02: ", response);
															lastSentRingCommand = new Date().getTime();
														})
														.catch(error => {
															console.error("Error writing to characteristic: ", error);
														})
													}
												}
											}
										}
									this.rssiSampleLoop = setInterval( async () => {
										this.ble.readRSSI(update.uuid).then( (rssi) => {
											update.ble_rssi = rssi;
											this.setAssetUpdate(update);
										}).catch((err) =>{
											console.error('unable to read RSSI',err);
										})
										
									}, 500);
								},
								(error) => {
									console.log('Connect error:', error);
								}
							);
						});
					}
				},
				error => { console.log('Scan error:', error); }
			);
		});
	}

	public async scanPureStations() {
		this.disconnectAll();
		this.clear();
		if (!this.platform.is('cordova')) return;
		this.checkBluetooth();

		let options: BLEScanOptions = {};
		options.reportDuplicates = true;

		this.ble.stopScan().then(() => {
			this.ble.startScanWithOptions([], options).subscribe(
				scannedDevice => {

					if (!scannedDevice.name) return;

					let wifi_macAddress: string = "";
					let ble_macAddress: string = "";
					let firmwareVersion: number = 0;

					if (this.platform.is('ios')) {
						if (scannedDevice.advertising && scannedDevice.advertising.kCBAdvDataManufacturerData) {
							ble_macAddress = "";
							var mfgData = new Uint8Array(scannedDevice.advertising.kCBAdvDataManufacturerData);
							window["LOG"] && console.log(" :: All manufacture data ::: ", mfgData)
							for (let i = 0; i < 6; i++) {
								wifi_macAddress += mfgData[i].toString(16);
							}

							// BLE macAddress is +2 from WiFi macAddress (wifi mac is whats in mfgData)
							let lastByte = (parseInt(wifi_macAddress.slice(-2), 16) + 2).toString(16);
							if (lastByte.length == 1) {
								lastByte = "0" + lastByte;
							}
							ble_macAddress = wifi_macAddress.substring(0, 10) + lastByte;

							// move [0,0,0] into a single number for easier comparisons in the future
							firmwareVersion = (mfgData[6] << 16) + (mfgData[7] << 8) + (mfgData[8])
						}
					}
					else if (this.platform.is('android')) {
						window["LOG"] && console.log(" :: Get manufacture Version for Android on the final spec ::");

						// WiFi macAddress is -2 from the BLE macAddress (BLE macAddress is given by the BLE device.id)
						ble_macAddress = scannedDevice.id.replace(/:/g, '').toLowerCase(); // Remove colons if they exist
						let lastByte = (parseInt(ble_macAddress.slice(-2), 16) - 2).toString(16);
						if (lastByte.length == 1) {
							lastByte = "0" + lastByte;
						}
						wifi_macAddress = ble_macAddress.substring(0, 10) + lastByte;

						firmwareVersion = 1;
					}

					// support orginal device onboading (before proto gen_settings)
					if (scannedDevice.name.substring(0, 3).localeCompare("PL-") == 0) {

						if (this.platform.is('android')) {
							let advert = new Uint8Array(scannedDevice.advertising);
							let onboarded: boolean = false;
							firmwareVersion = 0;

							// TODO :: Find a lib/make lib to parse adverts into js objects thats more managable
							// this will be important for the dxifferent platforms istead of just android
							if (
								advert[0] == 2 &&
								advert[1] == 1 &&
								advert[2] == 6 &&
								advert[3] != 2
							) {
								onboarded = true;
							}

							var deviceUpdate: PureLocateDevice = {
								type: GroupType.PureLocateDevice,
								id: wifi_macAddress,
								macAddress: wifi_macAddress,
								service: "BASE_STATION",
								fromBLE: true,
								proto_version: 0,
								ble_onboarded: onboarded,
								ble_macAddress: ble_macAddress,
								ble_uuid: scannedDevice.id,
								ble_scannedAt: new Date(),
								ble_name: scannedDevice.name || "Unknown",
								ble_rssi: scannedDevice.rssi,
							};

							this.setStationUpdate(deviceUpdate);
						}

					}
					else if (scannedDevice.name.substring(0, 3).localeCompare("PS-") == 0) {


						if (firmwareVersion == 0) {
							// This is due to SOME firmwareVersions with PS-, that expect large MTU
							// Advertising with a version of zero .. IE:: somewhere between
							// versions 0.2.0 -> 0.2.16 ish of firmware. Newer firmware will actually
							// broadcast the versions x.x.x in the advertisment.
							firmwareVersion = 1;
						}

						var deviceUpdate: PureLocateDevice = {
							type: GroupType.PureLocateDevice,
							id: wifi_macAddress,
							macAddress: wifi_macAddress,
							service: "BASE_STATION",
							fromBLE: true,
							proto_version: firmwareVersion,
							ble_macAddress: ble_macAddress,
							ble_uuid: scannedDevice.id,
							ble_scannedAt: new Date(),
							ble_name: scannedDevice.name || "Unknown",
							ble_rssi: scannedDevice.rssi,
						};

						this.setStationUpdate(deviceUpdate);
					}

				},
				err => console.error(err)
			);

		});

	}

	public barcodeScan(): Promise<PureLocateDevice> {

		return new Promise((resolve, reject) => {
			this.barcodeScanner.scan()
				.then((result) => {

					let macAddress: string = result.text.toLowerCase();
					macAddress.replace(/(..?)/g, '$1:').slice(0, -1);
					macAddress = macAddress.replace(/\:/g, '');

					var scanned = this.deviceFactory.createAssetDevice(macAddress, macAddress, 0);
					scanned.ble_name = ("Unknown");
					// scanned.set = -50;
					scanned.created_timestamp = new Date().getDate();

					scanned.ble_macAddress = (macAddress);

					scanned.id = (macAddress);
					scanned.name = (scanned.ble_name + "-" + scanned.macAddress);
					scanned.service = ("TAG");
					// scanned.type = 1;

					var regexpSemi = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
					var regexpDash = /^(([A-Fa-f0-9]{2}[-]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
					var regexpNone = /^(([A-Fa-f0-9]{2}){5}[A-Fa-f0-9]{2}[,]?)+$/i;

					console.log("Mac Address: " + macAddress);
					console.log("Scanned Mac Address: " + scanned.macAddress);
					if (scanned.macAddress.length == 12) {
						console.log("Valid: " + scanned.macAddress);
						resolve(scanned);
					} else {
						console.log("Invalid: " + scanned.macAddress);
						reject();
					}
				}).catch((error) => {
					alert(error);
				});
		});
	}

	public stopScanningSync() : Promise<any> {
		console.log("Stopping Scanning sync");
		return this.ble.stopScan();
	}

	public stopScanning() {
		this.ble.stopScan();
	}

	private clear() {
		this.assets = {};
		this.stations = {};
		this.assetsSubject.next(this.assets)
		this.stationsSubject.next(this.stations);
	}

	private disconnectAll() {
		Object.keys(this.devices).map((id) => {
			var device = this.devices[id];
			this.ble.disconnect(id);
		});
		this.devices = {};
	}

	private isOnboarded(advertising): boolean {
		if (!advertising) return;

		let advert = new Uint8Array(advertising);
		let onboarded: boolean = false;

		// TODO :: Find a lib/make lib to parse adverts into js objects thats more managable
		// this will be important for the dxifferent platforms istead of just android
		if (
			advert[0] == 2 &&
			advert[1] == 1 &&
			advert[2] == 6 &&
			advert[3] != 2
		) {
			onboarded = true;
		}

		return onboarded
	}

	private setAssetUpdate(deviceUpdate: PureLocateDevice) {
		this.assets[deviceUpdate.id] = deviceUpdate;
		this.assetsSubject.next(this.assets)
	}

	private setStationUpdate(deviceUpdate: PureLocateDevice) {
		this.stations[deviceUpdate.id] = deviceUpdate;
		this.stationsSubject.next(this.stations)
	}

}